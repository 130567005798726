import { Box, Typography } from "@material-ui/core";
import React from "react";

// Styles
import { FaqStyle } from "Globals/Styles/Public/Faqs/FaqsStyles";

// ViewModels
import { FaqViewModel } from "./FaqViewModel";

interface IProps {
    viewModel: FaqViewModel;
}

export const FaqView: React.FC<IProps> = (props) => {
    const faqStyle = FaqStyle();

    return (
        <Box className={faqStyle.root}>
            <Box>
                <Box>
                    <Box className={faqStyle.innerRow}>
                        <Typography className={faqStyle.qa} component="div">
                            Q.
                        </Typography>
                        <Typography component="div" variant="h4">
                            {props.viewModel.question}
                        </Typography>
                    </Box>
                    <Box className={faqStyle.innerRow}>
                        <Typography className={faqStyle.qa} component="div">
                            A.
                        </Typography>
                        <Typography component="div" variant="body2">
                            {props.viewModel.answer}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
