import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { MyAccountModel, MyAccountModelDTO } from "./MyAccountModel";

// Urls
import { AppUrls } from "AppUrls";

export class MyAccountViewModel extends ViewModelBase<MyAccountModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new MyAccountModel());

        this.setDecorators(MyAccountModel);

        this.getAccount();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region First Name

    @action
    public setFirstName = (value: string): void => {
        this.model.firstName = value;
    };

    @computed
    public get firstName(): string {
        return this.model.firstName;
    }

    @computed
    public get confirmFirstNameValidationMessage(): string {
        const result = this.validateDecorators("firstName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion First Name

    // #region Last Name

    @action
    public setLastName = (value: string): void => {
        this.model.lastName = value;
    };

    @computed
    public get lastName(): string {
        return this.model.lastName;
    }

    @computed
    public get confirmLastNameValidationMessage(): string {
        const result = this.validateDecorators("lastName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Last Name

    // #region Company Name

    @action
    public setCompanyName = (value: string): void => {
        this.model.companyName = value;
    };

    @computed
    public get companyName(): string {
        return this.model.companyName;
    }

    // #endregion Company Name

    // #region Intended Use

    @action
    public setIntendedUse = (value: string): void => {
        this.model.intendedUse = value;
    };

    @computed
    public get intendedUse(): string {
        return this.model.intendedUse;
    }

    @computed
    public get confirmIntendedUseValidationMessage(): string {
        const result = this.validateDecorators("intendedUse");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Intended Use

    // #region Agree Terms and Conditions

    @action
    public setAgreeTermsAndConditions = (): void => {
        this.model.agreeTermsAndConditions = !this.model.agreeTermsAndConditions;
    };

    @computed
    public get agreeTermsAndConditions(): boolean {
        return this.model.agreeTermsAndConditions;
    }

    @computed
    public get confirmAgreeTermsAndConditionsValidationMessage(): string {
        const result = this.validateDecorators("agreeTermsAndConditions");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #region Agree Terms and Conditions

    // #region Actions

    public getAccount = (): Promise<void> => {
        return this.server.query<MyAccountModelDTO>(
            () => this.Get(AppUrls.Server.Account.Get),
            (result) => this.model.fromDto(result),
            "There was an error trying to get your account.",
        );
    };

    public updateAccount = (): Promise<void> => {
        return this.server.command<MyAccountModelDTO>(
            () => this.Post(AppUrls.Server.Account.Update, this.model.toDto()),
            (result) => {
                this.model.fromDto(result);

                // This updates the displayname independently of the information
                // in the token.  Not ideal.
                StoresInstance.Domain.AccountStore.setDisplayName(`${this.model.firstName} ${this.model.lastName}`);
            },
            this.isModelValid,
            "There was an error trying to update your account.",
        );
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<MyAccountModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
