import { FieldType, getUrlSearchParams, isEmptyOrWhitespace, ValidationResponse, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { ResetPasswordModel, ResetPasswordModelDTO } from "./ResetPasswordModel";

// Urls
import { AppUrls } from "AppUrls";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new ResetPasswordModel());

        this.setDecorators(ResetPasswordModel);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Password

    @action
    public setPassword = (value: string): void => {
        this.model.newPassword = value;
    };

    @computed
    public get password(): string {
        return this.model.newPassword;
    }

    @computed
    public get passwordValidationMessage(): string {
        const result = this.validateDecorators("newPassword");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Password

    // #region Confirm Password

    @action
    public setConfirmPassword = (value: string): void => {
        this.model.confirmPassword = value;
    };

    @computed
    public get confirmPassword(): string {
        return this.model.confirmPassword;
    }

    @computed
    public get confirmPasswordValidationMessage(): string {
        const result = this.validateConfirmPassword;

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Confirm Password

    // #region Tokens

    @observable
    public tokenValid?: boolean;

    @action
    private setToken = (value: string): void => {
        this.model.token = value;
    };

    @action
    private setTokenValid = (value: boolean): void => {
        this.tokenValid = value;
    };

    // #endregion Tokens

    // #region Actions

    public getTokenFromUrl = () => {
        const urlSearchParams = getUrlSearchParams();
        const forgotPasswordToken = urlSearchParams.get("forgottoken");

        if (forgotPasswordToken) {
            this.setToken(forgotPasswordToken);
            this.verifyForgotPasswordTokenAsync();
        } else {
            this.setTokenValid(false);
        }
    };

    public verifyForgotPasswordTokenAsync = async (): Promise<void> => {
        const apiResult = await this.Post<ResetPasswordModelDTO>(AppUrls.Server.Account.ResetPassword.VerifyToken, this.model.toDto());

        this.setTokenValid(apiResult.wasSuccessful);

        if (this.tokenValid) {
            this.model.fromDto(apiResult.payload);
        }
    };

    public resetPassword = (): Promise<void> => {
        return this.server.command(
            () => this.Post(AppUrls.Server.Account.ResetPassword.Reset, this.model.toDto()),
            () => {},
            this.isModelValid,
        );
    };

    @action
    public navigateToMyProfile = (): void => {
        this.history.push(AppUrls.Client.MyProfile);
    };

    // #endregion Actions

    // #region Validation

    @computed
    private get validateConfirmPassword(): ValidationResponse {
        const errorMessage = this.model.validateConfirmPassword;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Validation

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<ResetPasswordModel>): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        // Process the properties of the model that cannot be supported via
        // the use of decorators.
        switch (fieldName) {
            case "confirmPassword": {
                const result = this.validateConfirmPassword;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
