import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminFeedModel, AdminGetFeedResponseModelDTO } from "./AdminFeedModel";

// ViewModels
import { AdminFeedViewModel } from "./AdminFeedViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminFeedsViewModel extends ViewModelBase<any> {
    // #region Private Members

    @observable
    private models = observable<AdminFeedModel>([]);

    @action
    private fromDtos = (dtos: AdminGetFeedResponseModelDTO[]): void => {
        for (const dto of dtos) {
            const model = new AdminFeedModel();

            model.fromDto(dto);

            this.models.push(model);
        }
    };

    @action
    private deleteFromCollection = (id: string): void => {
        const feed = this.models.find((m) => m.id === id);

        this.models.remove(feed!);
    };

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super({});

        this.getFeeds();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get feeds(): AdminFeedViewModel[] {
        return this.models.slice().map((model) => new AdminFeedViewModel(model, this.deleteFeed));
    }

    // #endregion Properties

    // #region Actions

    public getFeeds = (): Promise<void> => {
        return this.server.query<AdminGetFeedResponseModelDTO[]>(
            () => this.Get(AppUrls.Server.Admin.Feed.GetAll),
            (result) => this.fromDtos(result),
        );
    };

    public deleteFeed = (id: string): Promise<void> => {
        return this.server.command(
            () =>
                this.Post(AppUrls.Server.Admin.Feed.Delete, {
                    id: id,
                    isDeleted: true,
                }),
            (result) => this.deleteFromCollection(id),
            () => true,
        );
    };

    @action
    public navigateAddNewFeed = () => {
        this.history.push(AppUrls.Client.Admin.Feed.Feed);
    };

    @action
    public navigateToFeed = (id: string) => {
        this.history.push(`${AppUrls.Client.Admin.Feed.Feed}/${id}`);
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
