import { Box, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { EditTextStyles } from "../../../Globals/Styles/Primitives/EditTextStyles";
import { PublicWorkspaceStyles } from "../../../Globals/Styles/Public/PublicWorkspaceStyles";
import { FaqsStyles } from "../../../Globals/Styles/Public/Faqs/FaqsStyles";

// ViewModels
import { FaqsViewModel } from "./FaqsViewModel";

// Views
import { EditText } from "Views/Primitives/EditText";
import { FaqView } from "./FaqView";

export const FaqsView: React.FC = () => {
    const [viewModel] = useState(() => new FaqsViewModel());

    const workspaceClasses = PublicWorkspaceStyles();
    const editTextStyles = EditTextStyles();
    const classes = FaqsStyles();

    useEffect(() => {
        document.title = "FAQs";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const canDisplayNoFaqsMessage = (): boolean => {
        return viewModel.search.haveActiveKeyword && viewModel.faqs.length === 0;
    };

    const onHandleSearchEnterKey = () => {
        if (viewModel.search.haveKeyword) {
            viewModel.search.apply();
        }
    };

    const getSearchAdornment = (): JSX.Element | undefined => {
        return viewModel.search.haveKeyword ? (
            <Box className={editTextStyles.endAdornment}>
                <IconButton className={editTextStyles.endAdornmentButton} onClick={viewModel.search.reset}>
                    <ClearIcon />
                </IconButton>
                <IconButton className={editTextStyles.endAdornmentButton} onClick={viewModel.search.apply}>
                    <SearchIcon />
                </IconButton>
            </Box>
        ) : undefined;
    };

    const renderSearch = (): JSX.Element => {
        return (
            <Box className={classes.introductionSearch}>
                <Box className={classes.introduction}>
                    <Typography component="h1" variant="h2">
                        Frequently Asked Questions (FAQ)
                    </Typography>
                </Box>
                <Box className={classes.search}>
                    <Box className={classes.formRow}>
                        <EditText
                            className={editTextStyles.publicLight}
                            displayName=""
                            execute={viewModel.search.setKeyword}
                            executeOnEnter={onHandleSearchEnterKey}
                            inputProps={{
                                endAdornment: getSearchAdornment(),
                            }}
                            placeholder="Search for a keyword here"
                            validationMessage={canDisplayNoFaqsMessage() ? "No faqs match your search" : ""}
                            value={viewModel.search.keyword}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={classes.searchContainer}>{renderSearch()}</Box>
            <Box className={classes.listContainer}>
                {viewModel.faqs.map((faq) => (
                    <FaqView key={faq.id} viewModel={faq} />
                ))}
            </Box>
        </Box>
    ));
};
