import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Url
import { AppUrls } from "AppUrls";

export class LogoutViewModel extends ViewModelBase<any> {
    // #region Constructors and Disposers

    constructor() {
        super({});
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get userName() {
        return StoresInstance.Domain.AccountStore.DisplayName;
    }

    // #endregion Properties

    // #region Actions

    @action
    public logOut = async (): Promise<void> => {
        const DEFAULT_SERVERVALIDATIONMESSAGE = "There was an error trying to log out.";

        try {
            this.server.resetRequestSuccessful();
            this.server.resetValidationMessage();
            this.server.setIsSubmitted(true);

            if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
                const apiResult = await this.Post(AppUrls.Server.Account.Logout);
            }
        } catch (exception) {
            this.server.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
        } finally {
            StoresInstance.Domain.AccountStore.Logout();
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
