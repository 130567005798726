import { Box, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { MyFeedsStyles } from "Globals/Styles/Public/Home/FeedStyles";

// ViewModels
import { MyFeedsViewModel } from "./MyFeedsViewModel";

// Views
import { MyFeedSubCategoryView } from "./MyFeedSubcategoryView";

export const MyFeedsView: React.FC = () => {
    const [viewModel] = useState(() => new MyFeedsViewModel());

    const feedsStyles = MyFeedsStyles();

    const haveFeeds = (): boolean => {
        return viewModel.filteredFeedSubCategories.length > 0;
    };

    const renderFeeds = () => {
        return viewModel.filteredFeedSubCategories.map((feedSubCategory) => <MyFeedSubCategoryView key={feedSubCategory.id} viewModel={feedSubCategory} />);
    };

    const renderEmptyFeedsWarning = () => {
        return (
            <Box className={feedsStyles.emptyWarning}>
                <Box>
                    <Typography component="div" variant="h4">
                        You are not subscribed to any feeds
                    </Typography>
                </Box>
            </Box>
        );
    };

    return useObserver(() => <Box className={feedsStyles.root}>{haveFeeds() ? renderFeeds() : renderEmptyFeedsWarning()}</Box>);
};
