import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { Equals, IsEmail, IsNotEmpty, MinLength } from "class-validator";
import { action, computed, observable } from "mobx";

export class RegisterModel extends ModelBase<RegisterModel, RegisterModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_EMAILADDRESS = "";
    public static readonly DEFAULT_PASSWORD = "";
    public static readonly DEFAULT_CONFIRMPASSWORD = "";
    public static readonly DEFAULT_FIRSTNAME = "";
    public static readonly DEFAULT_LASTNAME = "";
    public static readonly DEFAULT_COMPANYNAME = "";
    public static readonly DEFAULT_INTENDEDUSE = "";
    public static readonly DEFAULT_AGREETERMSANDCONDITIONS = false;

    // #endregion Defaults

    // #region Observables

    @observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = RegisterModel.DEFAULT_EMAILADDRESS;

    @observable
    @IsNotEmpty({ message: "You must enter a password" })
    @MinLength(8, { message: "Password must be at least 8 characters" })
    public password: string = RegisterModel.DEFAULT_PASSWORD;

    @observable
    // Custom validation below
    public confirmPassword: string = RegisterModel.DEFAULT_CONFIRMPASSWORD;

    @observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = RegisterModel.DEFAULT_FIRSTNAME;

    @observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = RegisterModel.DEFAULT_LASTNAME;

    @observable
    public companyName: string = RegisterModel.DEFAULT_COMPANYNAME;

    @observable
    @IsNotEmpty({ message: "Intended use is required" })
    public intendedUse: string = RegisterModel.DEFAULT_INTENDEDUSE;

    @observable
    @Equals(true, { message: "Please agree to terms and conditions" })
    public agreeTermsAndConditions: boolean = RegisterModel.DEFAULT_AGREETERMSANDCONDITIONS;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.emailAddress = RegisterModel.DEFAULT_EMAILADDRESS;
        this.password = RegisterModel.DEFAULT_PASSWORD;
        this.confirmPassword = RegisterModel.DEFAULT_CONFIRMPASSWORD;
        this.companyName = RegisterModel.DEFAULT_COMPANYNAME;
        this.firstName = RegisterModel.DEFAULT_FIRSTNAME;
        this.lastName = RegisterModel.DEFAULT_LASTNAME;
        this.intendedUse = RegisterModel.DEFAULT_INTENDEDUSE;
        this.agreeTermsAndConditions = RegisterModel.DEFAULT_AGREETERMSANDCONDITIONS;
    }

    public fromDto(model: RegisterModelDTO): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): RegisterModelDTO {
        return {
            emailAddress: this.emailAddress,
            password: this.password,
            confirmPassword: this.confirmPassword,
            firstName: this.firstName,
            lastName: this.lastName,
            companyName: this.companyName,
            intendedUse: this.intendedUse,
            agreeTermsAndConditions: this.agreeTermsAndConditions,
        };
    }

    // #endregion Actions

    // #region Validation

    @computed
    public get validateConfirmPassword(): string {
        return this.password !== this.confirmPassword && !isEmptyOrWhitespace(this.password) ? "This must match the password" : "";
    }

    // #endregion Validation
}

export interface RegisterModelDTO {
    emailAddress: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    companyName: string;
    intendedUse: string;
    agreeTermsAndConditions: boolean;
}
