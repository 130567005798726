import { Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Styles
import { FeedSubCategoryStyles } from "Globals/Styles/Public/Home/FeedStyles";

// ViewModels
import { FeedSubCategoryViewModel } from "../Feeds/FeedSubCategoryViewModel";

// Views
import { FeedView } from "../Feeds/FeedView";

interface IProps {
    viewModel: FeedSubCategoryViewModel;
}

export const MyFeedSubCategoryView: React.FC<IProps> = (props) => {
    const feedSubCategoryStyles = FeedSubCategoryStyles(props);

    const renderHeader = (): JSX.Element => {
        return (
            <Box className={feedSubCategoryStyles.innerRowv2}>
                <Box onClick={props.viewModel.setDisplayFeeds}>
                    <Typography className={feedSubCategoryStyles.iconText} component="div" variant="h2">
                        {props.viewModel.displayName}
                    </Typography>
                    <Box className={feedSubCategoryStyles.countArrow}>
                        <Typography component="div" variant="body1">
                            {props.viewModel.numberOfFilteredFeeds} feeds
                        </Typography>
                        {props.viewModel.canDisplayFeeds ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Box>
                </Box>
            </Box>
        );
    };

    return useObserver(() => (
        <Box className={feedSubCategoryStyles.root}>
            {renderHeader()}
            {props.viewModel.canDisplayFeeds && (
                <Box className={feedSubCategoryStyles.items}>
                    {props.viewModel.filteredFeeds.map((feed) => (
                        <FeedView key={feed.id} viewModel={feed} />
                    ))}
                </Box>
            )}
        </Box>
    ));
};
