import { fade, makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

const labelFontSize = 14;
const editFontSize = 12;
const helperFontSize = 14;
const opacity = 0.5;

export const SelectStyles = makeStyles((theme) => ({
    publicDark: {
        "& > .MuiInputLabel-formControl": {
            fontSize: pxToRem(labelFontSize),
            fontWeight: "bold",
            marginTop: "0",
            transform: "translate(0, 0) scale(1)",
            "&:not(.Mui-disabled)": {
                color: theme.palette.default.main,
            },
        },
        "& > div > .MuiTextField-root": {
            marginTop: pxToRem(22),
            "& > .MuiInput-formControl": {
                backgroundColor: theme.palette.default.contrastText,
                border: `1px solid #CED4DA`,
                fontSize: pxToRem(editFontSize),
                minHeight: pxToRem(30),
                "& > .MuiInput-input": {
                    padding: `${pxToRem(7)} ${pxToRem(7.5)}`,
                },
            },
            "& > .MuiInput-formControl.Mui-disabled": {
                border: `1px solid ${fade("#CED4DA", opacity)}`,
            },
            "& > .MuiInput-formControl.Mui-focused": {
                boxShadow: `0 0 0 0.2rem ${fade("#CED4DA", opacity)}`,
            },
            "& > .MuiFormHelperText-root.Mui-error": {
                fontSize: pxToRem(helperFontSize),
            },
        },
    },
}));
