import React, { useEffect } from "react";
import "date-fns";
import {
    Box,
    Button,
    colors,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
    createMuiTheme,
    MuiThemeProvider,
    Switch,
} from "@material-ui/core";
import { Grid, Else, Show } from "@shoothill/core";
import styled from "styled-components/macro";
import { theme } from "Globals/Styles/AppTheme";

const CustomButton = styled(Button)`
    background-color: ${theme.palette.purpleAccent.main};
    color: ${theme.palette.purpleAccent.contrastText};
    &&:hover {
        background-color: ${theme.palette.purpleAccent.dark};
    }
`;
const CustomRadio = styled(Radio)`
    span {
        color: ${theme.palette.purpleAccent.main};
    }
`;
const CustomCheckbox = styled(Checkbox)`
    label {
        color: ${theme.palette.purpleAccent.contrastText};
    }
    span {
        color: ${theme.palette.purpleAccent.main};
    }
`;

export const Colours: React.FC = () => {
    const [value, setValue] = React.useState("female");
    const [currentTheme, setTheme] = React.useState(theme);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const newTheme = createMuiTheme({
        palette: {
            primary: {
                light: "#303f9f",
                dark: "#7986cb",
                main: "#3f51b5",
                contrastText: "#fff",
            },
            secondary: {
                light: "#ff4081",
                dark: "#c51162",
                main: "#f50057",
                contrastText: "#fff",
            },
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        if (state.checkedA) {
            setTheme(theme);
        } else {
            setTheme(newTheme);
        }
    }, [state]);

    return (
        <>
            <Box padding={"20px"}>
                <MuiThemeProvider theme={currentTheme}>
                    <h1>Home Page</h1>
                    <Show if={true}>
                        <Typography variant={"h4"}>
                            <a target={"_blank"} href={"https://bareynol.github.io/mui-theme-creator/#Appbar"}>
                                Theme Generator 1
                            </a>
                        </Typography>
                        <Else if={false}>
                            <Typography variant={"h4"}>
                                <a target={"_blank"} href={"https://bareynol.github.io/mui-theme-creator/#Appbar"}>
                                    Theme Generator 10
                                </a>
                            </Typography>
                        </Else>
                    </Show>
                    <Typography variant={"h4"}>
                        <a target={"_blank"} href={"https://cimdalli.github.io/mui-theme-generator/"}>
                            Theme Generator 2
                        </a>
                    </Typography>
                    <Typography variant={"h4"}>
                        <a target={"_blank"} href={"https://material.io/resources/color/#!/?view.left=0&view.right=0"}>
                            Theme Generator 3
                        </a>
                    </Typography>
                    <br />
                    <FormControlLabel control={<Switch checked={state.checkedA} onChange={handleThemeChange} name="checkedA" />} label="Change Theme" />
                    <br />
                    <Typography variant="h4">Text Colours</Typography>
                    <Typography component="div" variant="body1">
                        <Box color="primary.main">primary.main</Box>
                        <Box color="secondary.main">secondary.main</Box>
                        <Box color="info.main">info.main</Box>
                        <Box color="success.main">success.main</Box>
                        <Box color="adminGrey.main">adminGrey.main</Box>

                        <Box color="error.main">error.main</Box>
                        <Box color="warning.main">warning.main</Box>
                        <Box color="text.primary">text.primary</Box>
                        <Box color="text.secondary">text.secondary</Box>
                        <Box color="text.disabled">text.disabled</Box>
                        <Box color={theme.palette.purpleAccent}>text.custom</Box>
                    </Typography>
                    <br />
                    <Typography variant="h4">BG Colours</Typography>
                    <Grid rowGap={"10px"} columnGap={"10px"} desktopColumns={"1fr 1fr 1fr"}>
                        {/* Primary */}
                        <Box bgcolor="primary.light" color="primary.contrastText" p={2}>
                            primary.light
                        </Box>
                        <Box bgcolor="primary.main" color="primary.contrastText" p={2}>
                            primary.main
                        </Box>
                        <Box bgcolor="primary.dark" color="primary.contrastText" p={2}>
                            primary.dark
                        </Box>
                        {/* Secondary */}
                        <Box bgcolor="secondary.light" color="secondary.contrastText" p={2}>
                            secondary.light
                        </Box>
                        <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
                            secondary.main
                        </Box>
                        <Box bgcolor="secondary.dark" color="secondary.contrastText" p={2}>
                            secondary.dark
                        </Box>
                        {/* Info */}
                        <Box bgcolor="info.light" color="info.contrastText" p={2}>
                            info.light
                        </Box>
                        <Box bgcolor="info.main" color="info.contrastText" p={2}>
                            info.main
                        </Box>
                        <Box bgcolor="info.dark" color="info.contrastText" p={2}>
                            info.dark
                        </Box>
                        {/* Success */}
                        <Box bgcolor="success.light" color="success.contrastText" p={2}>
                            success.light
                        </Box>
                        <Box bgcolor="success.main" color="success.contrastText" p={2}>
                            success.main
                        </Box>
                        <Box bgcolor="success.dark" color="success.contrastText" p={2}>
                            success.dark
                        </Box>
                        {/* Default */}
                        <Box bgcolor="default.light" color="default.contrastText" p={2}>
                            default.light
                        </Box>
                        <Box bgcolor="default.main" color="default.contrastText" p={2}>
                            default.main
                        </Box>
                        <Box bgcolor="default.dark" color="default.contrastText" p={2}>
                            default.dark
                        </Box>
                        {/* Offwhite */}
                        <Box bgcolor="offWhite.light" color="offWhite.contrastText" p={2}>
                            offWhite.light
                        </Box>
                        <Box bgcolor="offWhite.main" color="offWhite.contrastText" p={2}>
                            offWhite.main
                        </Box>
                        <Box bgcolor="offWhite.dark" color="offWhite.contrastText" p={2}>
                            offWhite.dark
                        </Box>
                        {/* AdmimGrey */}
                        <Box bgcolor="adminGrey.light" color="adminGrey.contrastText" p={2}>
                            adminGrey.light
                        </Box>
                        <Box bgcolor="adminGrey.main" color="adminGrey.contrastText" p={2}>
                            adminGrey.main
                        </Box>
                        <Box bgcolor="adminGrey.dark" color="adminGrey.contrastText" p={2}>
                            adminGrey.dark
                        </Box>
                        {/* MapRed */}
                        <Box bgcolor="mapRed.light" color="mapRed.contrastText" p={2}>
                            mapRed.light
                        </Box>
                        <Box bgcolor="mapRed.main" color="mapRed.contrastText" p={2}>
                            mapRed.main
                        </Box>
                        <Box bgcolor="mapRed.dark" color="mapRed.contrastText" p={2}>
                            mapRed.dark
                        </Box>

                        <Box bgcolor="error.main" color="error.contrastText" p={2}>
                            error.main
                        </Box>
                        <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
                            warning.main
                        </Box>
                        <Box bgcolor="text.primary" color="background.paper" p={2}>
                            text.primary
                        </Box>
                        <Box bgcolor="text.secondary" color="background.paper" p={2}>
                            text.secondary
                        </Box>
                        <Box bgcolor="text.disabled" color="background.paper" p={2}>
                            text.disabled2
                        </Box>
                        <Box bgcolor={"accent.main"} color={"accent.contrastText"} p={2}>
                            text.custom
                        </Box>
                        <Box bgcolor={theme.palette.purpleAccent.main} color={theme.palette.customText.primary} p={2}>
                            text.custom
                        </Box>
                    </Grid>
                    <br />
                    <Typography variant="h4">Control Colours</Typography>

                    <Grid rowGap={"10px"} columnGap={"10px"} desktopColumns={"1fr 1fr 1fr"}>
                        <Button variant="contained" color={"primary"}>
                            primary
                        </Button>
                        <Button variant="contained" color={"secondary"}>
                            secondary
                        </Button>
                        <CustomButton variant="contained" color={"primary"}>
                            custom
                        </CustomButton>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Primary</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="female" control={<Radio color={"primary"} />} label="Female" />
                                <FormControlLabel value="male" control={<Radio color={"primary"} />} label="Male" />
                                <FormControlLabel value="other" control={<Radio color={"primary"} />} label="Other" />
                                <FormControlLabel value="disabled" disabled control={<Radio color={"primary"} />} label="(Disabled option)" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Secondary</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="female" control={<Radio color={"secondary"} />} label="Female" />
                                <FormControlLabel value="male" control={<Radio color={"secondary"} />} label="Male" />
                                <FormControlLabel value="other" control={<Radio color={"secondary"} />} label="Other" />
                                <FormControlLabel value="disabled" disabled control={<Radio color={"secondary"} />} label="(Disabled option)" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Custom</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="female" control={<CustomRadio />} label="Female" />
                                <FormControlLabel value="male" control={<CustomRadio />} label="Male" />
                                <FormControlLabel value="other" control={<CustomRadio />} label="Other" />
                                <FormControlLabel value="disabled" disabled control={<CustomRadio />} label="(Disabled option)" />
                            </RadioGroup>
                        </FormControl>
                        <FormControlLabel control={<Checkbox color={"primary"} name="checkedC" checked />} label="Primary" />
                        <FormControlLabel control={<Checkbox color={"secondary"} name="checkedC" checked />} label="Secondary" />
                        <FormControlLabel control={<CustomCheckbox name="checkedC" />} label="Custom" checked />
                    </Grid>
                </MuiThemeProvider>
            </Box>
        </>
    );
};
