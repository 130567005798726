import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class AdminUserModel extends ModelBase<AdminUserModel> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_FIRSTNAME = "";
    public static readonly DEFAULT_LASTNAME = "";
    public static readonly DEFAULT_EMAILADDRESS = "";
    public static readonly DEFAULT_COMPANYNAME = "";

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = AdminUserModel.DEFAULT_ID;

    @observable
    public firstName: string = AdminUserModel.DEFAULT_FIRSTNAME;

    @observable
    public lastName: string = AdminUserModel.DEFAULT_LASTNAME;

    @observable
    public emailAddress: string = AdminUserModel.DEFAULT_EMAILADDRESS;

    @observable
    public companyName: string = AdminUserModel.DEFAULT_COMPANYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AdminUserModel.DEFAULT_ID;
        this.firstName = AdminUserModel.DEFAULT_FIRSTNAME;
        this.lastName = AdminUserModel.DEFAULT_LASTNAME;
        this.emailAddress = AdminUserModel.DEFAULT_EMAILADDRESS;
        this.companyName = AdminUserModel.DEFAULT_COMPANYNAME;
    }

    @action
    public fromDto(dto: AdminGetUserResponseModelDTO): void {
        this.id = dto.id;
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.emailAddress = dto.emailAddress;
        this.companyName = dto.companyName;
    }

    public toDto() {
        throw new Error("Not implemented");
    }

    // #region Actions
}

export interface AdminGetUserResponseModelDTO {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    companyName: string;
}
