import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../AppTheme";

export const LoginStyles = makeStyles((theme) => ({
    root: {},
    login: {
        borderRadius: pxToRem(5),
        marginBottom: pxToRem(15),
        padding: pxToRem(30),
    },
    logout: {
        marginBottom: pxToRem(15),
        "& > div": {
            alignItems: "center",
            "& > .MuiTypography-root": {
                flex: 1,
            },
        },
    },
    register: {
        display: "flex",
        "& > button": {
            flex: 1,
        },
    },
    formRow: {
        display: "flex",
        margin: `0 ${pxToRem(-15)}`,
        "& > button": {
            margin: `0 ${pxToRem(15)}`,
        },
        "& > .MuiTypography-root": {
            margin: `0 ${pxToRem(15)}`,
        },
        "& > label": {
            margin: `0 ${pxToRem(15)} 0 ${pxToRem(15 - 12)}`,
        },
        "& > .MuiFormControl-root": {
            flex: 1,
            margin: `0 ${pxToRem(15)}`,
        },
        "& > .MuiFormControl-root.editTextGutterBottom": {
            marginBottom: pxToRem(12.5),
        },
    },
    alert: {
        marginTop: pxToRem(30),
    },
}));
