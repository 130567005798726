import { darken, makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

/**
 * Styles for use with the all workspace components.
 */
export const AdminWorkspaceStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.offWhite.main,
        flex: 1,
        flexDirection: "column",
    },
}));

/**
 * Styles for use with form-based workspace components.
 */
export const AdminFormWorkspaceStyles = makeStyles((theme) => ({
    root: {},
    titleContainer: {
        alignItems: "center",
        backgroundColor: "white",
        color: "#333",
        borderBottom: "2px solid #6C6C6A",
        display: "flex",
        justifyContent: "space-between",
        padding: `${pxToRem(22.5)} ${pxToRem(30)}`,
    },
    titleContainerRow: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "center",
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        flex: 1,
    },
    formRow: {
        alignItems: "baseline",
        padding: `0 0 ${pxToRem(15)} 0`,
    },
    formButtonRow: {
        display: "flex",
        justifyContent: "center",
        padding: `0 0 ${pxToRem(15)} 0`,
    },
    formButtonRowWithTop: {
        display: "flex",
        justifyContent: "center",
        padding: `${pxToRem(15)} 0`,
    },
    alert: {
        flex: 1,
        fontSize: pxToRem(14),
        marginTop: pxToRem(0),
    },
}));

/**
 * Styles for use with table-based workspace components.
 */
export const AdminTableWorkspaceStyles = makeStyles((theme) => ({
    root: {},
    titleContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: `${pxToRem(22.5)} ${pxToRem(30)}`,
    },
    tableContainer: {
        "& > div": {
            backgroundColor: "transparent",
            boxShadow: `0px ${pxToRem(3)} ${pxToRem(6)} ${theme.palette.divider}`,
        },
        "& thead": {
            "& > tr": {
                "& th": {
                    backgroundColor: theme.palette.adminGrey.main,
                    color: theme.palette.adminGrey.contrastText,
                    fontWeight: "bold",
                    padding: `${pxToRem(8)} ${pxToRem(16)}`,
                    "& > span": {
                        color: `${theme.palette.adminGrey.contrastText} !important`,
                        "&:hover": {
                            color: `${theme.palette.adminGrey.contrastText} !important`,
                        },
                        "& > span": {
                            // Not sorting
                            color: theme.palette.adminGrey.contrastText,
                        },
                    },
                    "& > span.MuiTableSortLabel-active": {
                        "& > span": {
                            // Sorting
                            color: `${theme.palette.adminGrey.contrastText} !important`,
                        },
                    },
                },
                "& th:first-child": {
                    padding: `${pxToRem(8)} ${pxToRem(15)} ${pxToRem(8)} ${pxToRem(30)}`,
                },
                "& th:last-child": {
                    padding: `${pxToRem(8)} ${pxToRem(30)} ${pxToRem(8)} ${pxToRem(15)}`,
                },
            },
        },
        "& tbody": {
            "& > tr": {
                fontSize: pxToRem(12),
                "& td": {
                    padding: `${pxToRem(8)} ${pxToRem(16)}`,
                },
                "& td:first-child": {
                    padding: `${pxToRem(8)} ${pxToRem(15)} ${pxToRem(8)} ${pxToRem(30)}`,
                },
                "& td:last-child": {
                    padding: `${pxToRem(8)} ${pxToRem(30)} ${pxToRem(8)} ${pxToRem(15)}`,
                },
            },
        },
        "& tfoot": {
            "& > tr": {
                "& td": {
                    backgroundColor: theme.palette.adminGrey.main,
                    border: "none",
                    borderRadius: "none",
                    color: theme.palette.adminGrey.contrastText,
                    "& > div": {
                        minHeight: pxToRem(40),
                        "& > div": {
                            color: `${theme.palette.adminGrey.contrastText} !important`,
                            "& svg": {
                                fill: `${theme.palette.adminGrey.contrastText} !important`,
                            },
                            "& button": {
                                color: `${theme.palette.adminGrey.contrastText} !important`,
                                padding: `${pxToRem(8)}`,
                                "&:hover": {
                                    color: `${theme.palette.adminGrey.contrastText} !important`,
                                },
                            },
                            "& button.Mui-disabled": {
                                color: `${darken(theme.palette.adminGrey.contrastText, 0.25)} !important`,
                            },
                        },
                    },
                },
                "& td:first-child": {
                    padding: `${pxToRem(0)} ${pxToRem(15)} ${pxToRem(0)} ${pxToRem(30)}`,
                },
                "& td:last-child": {
                    padding: `${pxToRem(0)} ${pxToRem(30)} ${pxToRem(0)} ${pxToRem(15)}`,
                },
            },
        },
    },
}));
