import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";
import { FeedSubCategory } from "./FeedSubCategoryModel";

export class FeedCategory extends ModelBase<FeedCategory, FeedCategoryResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";
    public static readonly DEFAULT_ORDINAL = 0;
    public static readonly DEFAULT_TYPE = "";
    public static readonly DEFAULT_FEEDSUBCATEGORIES = [];

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = FeedCategory.DEFAULT_ID;

    @observable
    public displayName: string = FeedCategory.DEFAULT_DISPLAYNAME;

    @observable
    public ordinal: number = FeedCategory.DEFAULT_ORDINAL;

    @observable
    public type: string = FeedCategory.DEFAULT_TYPE;

    @observable
    public feedSubCategories = observable<FeedSubCategory>(FeedCategory.DEFAULT_FEEDSUBCATEGORIES);

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = FeedCategory.DEFAULT_ID;
        this.displayName = FeedCategory.DEFAULT_DISPLAYNAME;
        this.ordinal = FeedCategory.DEFAULT_ORDINAL;
        this.type = FeedCategory.DEFAULT_TYPE;
        this.feedSubCategories.replace(FeedCategory.DEFAULT_FEEDSUBCATEGORIES);
    }

    @action
    public fromDto(dto: FeedCategoryResponseModelDTO): void {
        this.id = dto.id;
        this.displayName = dto.displayName;
        this.ordinal = dto.ordinal;
        this.type = dto.type;
        this.feedSubCategories.replace(FeedCategory.DEFAULT_FEEDSUBCATEGORIES);
    }

    @action
    public setSubCategories(value: FeedSubCategory[]): void {
        this.feedSubCategories.replace(value);
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface FeedCategoryResponseModelDTO {
    id: string;
    displayName: string;
    ordinal: number;
    type: string;
}
