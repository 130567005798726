import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

import { AdminHeaderStyles } from "../../../../Globals/Styles/Admin/AdminHeaderStyles";
import { AdminHeaderViewModel } from "./AdminHeaderViewModel";

export const AdminHeader: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new AdminHeaderViewModel());
    const classes = AdminHeaderStyles();

    // #region Code behind

    const getNavigationButtonClasses = (isOptionActive: boolean) => {
        return clsx({
            [classes.navigationOption]: true,
            [classes.navigationOptionActive]: isOptionActive,
        });
    };

    // #endregion Code behind

    return useObserver(() => (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.logo} onClick={viewModel.navigateToHome} />
                <Box className={classes.navigationOptions}>
                    <Box className={getNavigationButtonClasses(viewModel.IsUsersActive)} onClick={viewModel.navigateToUsers}>
                        <Box>Users</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(viewModel.IsFaqsActive)} onClick={viewModel.navigateToFaqs}>
                        <Box>FAQ management</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(viewModel.IsFeedsActive)} onClick={viewModel.navigateToFeeds}>
                        <Box>Feed management</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(false)} onClick={viewModel.navigateToLogout}>
                        <Box>Logout</Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    ));
};
