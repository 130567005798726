import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

export const ButtonStyles = makeStyles((theme) => ({
    containedRegister: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        fontSize: pxToRem(32),
        fontWeight: "bold",
        justifyContent: "space-between",
        padding: `${pxToRem(14)} ${pxToRem(29)}`,
        textTransform: "none",
        "& > span.MuiButton-label > span.MuiButton-endIcon": {
            marginLeft: pxToRem(15),
            marginRight: pxToRem(1),
            transform: "scale(1.8)",
        },
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
    },
    containedSuccess: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        fontSize: pxToRem(14),
        fontWeight: "bold",
        maxHeight: pxToRem(30),
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
    },
    containedCancel: {
        color: theme.palette.adminGrey.contrastText,
        backgroundColor: theme.palette.adminGrey.main,
        fontSize: pxToRem(14),
        fontWeight: "bold",
        maxHeight: pxToRem(30),
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.adminGrey.dark,
        },
    },
    forgotPassword: {
        color: theme.palette.default.contrastText,
        fontSize: pxToRem(14),
        maxHeight: pxToRem(30),
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.default.light,
        },
    },
}));
