import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminUserModel, AdminUpsertUserResponseModelDTO } from "./AdminUserModel";

// Urls
import { AppUrls } from "./../../../../AppUrls";

export class AdminUserViewModel extends ViewModelBase<AdminUserModel> {
    // #region Constructors and Disposers

    constructor(id?: string) {
        super(new AdminUserModel());

        this.setDecorators(AdminUserModel);

        if (!isEmptyOrWhitespace(id)) {
            this.getUser(id!);
        }
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region First Name

    @action
    public setFirstName = (value: string): void => {
        this.model.firstName = value;
    };

    @computed
    public get firstName(): string {
        return this.model.firstName;
    }

    @computed
    public get confirmFirstNameValidationMessage(): string {
        const result = this.validateDecorators("firstName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion First Name

    // #region Last Name

    @action
    public setLastName = (value: string): void => {
        this.model.lastName = value;
    };

    @computed
    public get lastName(): string {
        return this.model.lastName;
    }

    @computed
    public get confirmLastNameValidationMessage(): string {
        const result = this.validateDecorators("lastName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Last Name

    // #region Company Name

    @action
    public setCompanyName = (value: string): void => {
        this.model.companyName = value;
    };

    @computed
    public get companyName(): string {
        return this.model.companyName;
    }

    // #endregion Company Name

    // #region Intended Use

    @action
    public setIntendedUse = (value: string): void => {
        this.model.intendedUse = value;
    };

    @computed
    public get intendedUse(): string {
        return this.model.intendedUse;
    }

    @computed
    public get confirmIntendedUseValidationMessage(): string {
        const result = this.validateDecorators("intendedUse");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Intended Use

    // #region Reset Password

    @computed
    public get canResetPassword(): boolean {
        return !this.server.IsBusy && !isEmptyOrWhitespace(this.model.emailAddress);
    }

    // #endregion Reset Password

    // #region Actions

    public getUser = (id: string): void => {
        this.server.query<AdminUpsertUserResponseModelDTO>(
            () =>
                this.Post(AppUrls.Server.Admin.User.Get, {
                    id: id,
                }),
            (result) => this.model.fromDto(result),
        );
    };

    public updateUser = (): void => {
        this.server.command<AdminUpsertUserResponseModelDTO>(
            () => this.Post(AppUrls.Server.Admin.User.Update, this.model.toDto()),
            (result) => this.model.fromDto(result),
            this.isModelValid,
            "There was an error trying to update the user.",
        );
    };

    public resetPassword = (): void => {
        this.server.command(
            () => this.Post(AppUrls.Server.Account.ForgotPassword, this.model.toForgotPasswordDto()),
            () => {},
            () => true,
        );
    };

    @action
    public navigateBackToUsers = () => {
        this.history.push(AppUrls.Client.Admin.User.Users);
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<AdminUserModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
