import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { PublicAccountFormWorkspaceStyles, PublicWorkspaceStyles } from "../../../../Globals/Styles/Public/PublicWorkspaceStyles";
import { ButtonStyles } from "../../../../Globals/Styles/Primitives/ButtonStyles";
import { CheckBoxStyles } from "../../../../Globals/Styles/Primitives/CheckBoxStyles";
import { EditTextStyles } from "../../../../Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { RegisterViewModel } from "./RegisterViewModel";

// Views
import { EditText } from "Views/Primitives/EditText";
import { CheckBox } from "Views/Primitives/CheckBoxes";

export const RegisterView: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new RegisterViewModel());

    const buttonStyles = ButtonStyles();
    const checkBoxStyles = CheckBoxStyles();
    const editTextStyles = EditTextStyles();
    const workspaceClasses = PublicWorkspaceStyles();
    const formWorkspaceClasses = PublicAccountFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Registration";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.register();
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={formWorkspaceClasses.titleContainer}>
                <Box className={formWorkspaceClasses.titleContainerRow}>
                    <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateToMyProfile} variant="contained">
                        Back
                    </Button>
                    <Typography component="h1" variant="h2">
                        Registration
                    </Typography>
                </Box>
            </Box>
            <Box className={formWorkspaceClasses.formContainer}>
                <form className={formWorkspaceClasses.form} onSubmit={onSubmit}>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Email address*"
                            execute={viewModel.setEmailAddress}
                            placeholder="Your email address"
                            validationMessage={viewModel.emailAddressValidationMessage}
                            value={viewModel.emailAddress}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Password*"
                            execute={viewModel.setPassword}
                            isPassword={true}
                            placeholder="Your password"
                            validationMessage={viewModel.passwordValidationMessage}
                            value={viewModel.password}
                        />
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Confirm password*"
                            execute={viewModel.setConfirmPassword}
                            isPassword={true}
                            placeholder="Your password"
                            validationMessage={viewModel.confirmPasswordValidationMessage}
                            value={viewModel.confirmPassword}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Company name"
                            execute={viewModel.setCompanyName}
                            placeholder="The company you work for"
                            value={viewModel.companyName}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="First name*"
                            execute={viewModel.setFirstName}
                            placeholder="Your first name"
                            validationMessage={viewModel.confirmFirstNameValidationMessage}
                            value={viewModel.firstName}
                        />
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Last name*"
                            execute={viewModel.setLastName}
                            placeholder="Your last name"
                            validationMessage={viewModel.confirmLastNameValidationMessage}
                            value={viewModel.lastName}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <EditText
                            canExecute={!viewModel.server.IsBusy}
                            className={editTextStyles.publicDark}
                            displayName="Intended use*"
                            execute={viewModel.setIntendedUse}
                            placeholder="The use for the feeds"
                            validationMessage={viewModel.confirmIntendedUseValidationMessage}
                            value={viewModel.intendedUse}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <CheckBox
                            canExecute={!viewModel.server.IsBusy}
                            className={checkBoxStyles.publicDark}
                            displayName="I agree to the Terms & Conditions*"
                            execute={viewModel.setAgreeTermsAndConditions}
                            validationMessage={viewModel.confirmAgreeTermsAndConditionsValidationMessage}
                            value={viewModel.agreeTermsAndConditions}
                        />
                    </Grid>
                    <Grid className={formWorkspaceClasses.formButtonRow} columnGap={30} rowGap={15}>
                        <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                            Register
                        </Button>
                    </Grid>
                    {viewModel.server.HaveValidationMessage && (
                        <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                            <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                                {viewModel.server.ValidationMessage}
                            </Alert>
                        </Grid>
                    )}
                </form>
            </Box>
        </Box>
    ));
};
