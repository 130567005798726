import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { PublicWorkspaceStyles } from "../../../Globals/Styles/Public/PublicWorkspaceStyles";
import { HomeStyles } from "../../../Globals/Styles/Public/Home/HomeStyles";

// ViewModels
import { HomeViewModel } from "./HomeViewModel";

// Views
import { FeedsView } from "../SubViews/Feeds/FeedsView";
import { Login } from "../SubViews/Login/Login";
import { Logout } from "../SubViews/Logout/Logout";

export const HomeView: React.FC = () => {
    const [viewModel] = useState(() => new HomeViewModel());

    const workspaceClasses = PublicWorkspaceStyles();
    const classes = HomeStyles();

    useEffect(() => {
        document.title = "Smart City Cluster Home";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const renderLoginContainer = (): JSX.Element => {
        return (
            <Box className={classes.introductionLogin}>
                <Box className={classes.introduction}>
                    <Typography component="h1" gutterBottom variant="h2">
                        Welcome to Berkshire's <span className="nowrap">Real-Time</span> Open Data Information System
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        The Berkshire Open Data Hub has been designed to provide developers with access to data from across the Berkshire transport network. The system enables you
                        to register for an account, select data feeds and to provide details of the application you are intending on creating.
                    </Typography>
                    <Typography variant="body1">
                        Berkshire Local Authorities hope that by making more data available from across the region’s transport network that this will inspire the development of
                        high-quality applications that create better informed travellers and stimulate smarter choices.
                    </Typography>
                </Box>
                <Login className={classes.login} />
            </Box>
        );
    };

    const renderLogoutContainer = (): JSX.Element => {
        return (
            <Box className={classes.introductionLogout}>
                <Box className={classes.introduction}>
                    <Logout />
                    <Typography gutterBottom variant="body1">
                        The Berkshire Open Data Hub has been designed to provide developers with access to data from across the Berkshire transport network. The system enables you
                        to register for an account, select data feeds and to provide details of the application you are intending on creating.
                    </Typography>
                    <Typography variant="body1">
                        Berkshire Local Authorities hope that by making more data available from across the region’s transport network that this will inspire the development of
                        high-quality applications that create better informed travellers and stimulate smarter choices.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const renderPublicFeedsContainer = (): JSX.Element => {
        return (
            <Box>
                <FeedsView />
            </Box>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={classes.introductionContainer}>{viewModel.canDisplayLogout ? renderLogoutContainer() : renderLoginContainer()}</Box>
            <Box>{renderPublicFeedsContainer()}</Box>
        </Box>
    ));
};
