import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../AppTheme";

export const AdminHomeStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: `${theme.palette.adminGrey.main} !important` as any,
        color: theme.palette.adminGrey.contrastText,
        display: "flex",
        justifyContent: "center",
    },
    formContainer: {
        flex: 1,
        padding: pxToRem(30),
    },
    form: {
        maxWidth: pxToRem(400),
    },
}));
