import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { PublicAccountFormWorkspaceStyles, PublicWorkspaceStyles } from "../../../../Globals/Styles/Public/PublicWorkspaceStyles";
import { ButtonStyles } from "../../../../Globals/Styles/Primitives/ButtonStyles";
import { EditTextStyles } from "../../../../Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";

// Views
import { EditText } from "Views/Primitives/EditText";

export const ForgotPasswordView: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());

    const buttonStyles = ButtonStyles();
    const editTextStyles = EditTextStyles();
    const workspaceClasses = PublicWorkspaceStyles();
    const formWorkspaceClasses = PublicAccountFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Forgot password";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.forgotPassword();
    };

    const renderForgotPasswordSuccess = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} key="success-1" rowGap={15}>
                    <Alert className={formWorkspaceClasses.info} severity="info" variant="filled">
                        The email to reset your password has been sent. Please check you inbox.
                    </Alert>
                </Grid>
            </React.Fragment>
        );
    };

    const renderForgotPassword = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} key="form-1" rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Email address*"
                        execute={viewModel.setEmailAddress}
                        placeholder="Your email address"
                        validationMessage={viewModel.emailAddressValidationMessage}
                        value={viewModel.emailAddress}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRowWithTop} columnGap={30} key="form-2" rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Send email
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} key="form-3" rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={formWorkspaceClasses.titleContainer}>
                <Box className={formWorkspaceClasses.titleContainerRow}>
                    <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateToMyProfile} variant="contained">
                        Back
                    </Button>
                    <Typography component="h1" variant="h2">
                        Forgot password
                    </Typography>
                </Box>
            </Box>
            <Box className={formWorkspaceClasses.formContainer}>
                <form className={formWorkspaceClasses.form} onSubmit={onSubmit}>
                    {viewModel.server.IsRequestSuccessful ? renderForgotPasswordSuccess() : renderForgotPassword()}
                </form>
            </Box>
        </Box>
    ));
};
