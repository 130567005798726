import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";

export class MyProfileViewModel extends ViewModelBase<any> {
    // #region Private Members

    private reactionDispose?: IReactionDisposer;

    @observable
    private options = observable<any>([]);

    // #endregion Private Mmembers

    // #region Constructors and Disposers

    constructor() {
        super({});

        // Monitor changes to the login state. If the user is already on the
        // page and log in or navigating to the page and already logged in,
        // provide the options.
        this.reactionDispose = reaction(
            () => StoresInstance.Domain.AccountStore.IsLoggedIn,
            (isLoggedIn) => {
                if (isLoggedIn) {
                    this.createOptions();
                }
            },
        );

        if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
            this.createOptions();
        }
    }

    public dispose = (): void => {
        if (!isNullOrUndefined(this.reactionDispose)) {
            this.reactionDispose?.();
        }
    };

    private createOptions = (): void => {
        this.options.replace([]);

        if (!StoresInstance.Domain.AccountStore.IsAdministrator) {
            this.options.push({
                id: "id_myaccount",
                displayName: "My account",
            });
        }

        this.options.push({
            id: "id_myfeeds",
            displayName: "My feeds",
        });
        this.options.push({
            id: "id_myapplications",
            displayName: "My applications",
        });

        this.myProfileOption = this.options[0];
    };

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get canDisplayLogout() {
        return StoresInstance.Domain.AccountStore.IsLoggedIn;
    }

    @computed
    public get canDisplayProfile() {
        return StoresInstance.Domain.AccountStore.IsLoggedIn;
    }

    @computed
    public get myProfileOptions() {
        return this.options;
    }

    @observable
    public myProfileOption: any = undefined;

    public isOptionActive = (id: string): boolean => {
        return isNullOrUndefined(this.myProfileOption) ? false : this.myProfileOption.id === id;
    };

    // #endregion Properties

    // #region Actions

    @action
    public setMyProfileOption = (value: any) => {
        this.myProfileOption = value;
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
