import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import moment from "moment";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { ApplicationModel, ApplicationResponseModelDTO } from "./ApplicationModel";
import { MyApplicationsResponseModelDTO } from "./MyApplicationsModel";

// ViewModels
import { ApplicationViewModel } from "./ApplicationViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class ApplicationsViewModel extends ViewModelBase<any> {
    // #region Private Members

    @observable
    private displayApplications = true;

    @observable
    private models = observable<ApplicationModel>([]);

    @computed
    private get haveItems(): boolean {
        return this.applications.length > 0;
    }

    @action
    private fromDtos = (dtos: ApplicationResponseModelDTO[]): void => {
        for (const dto of dtos) {
            const model = new ApplicationModel();

            model.fromDto(dto);

            this.models.push(model);
        }
    };

    @action
    private deleteFromCollection = (id: string): void => {
        const application = this.models.find((m) => m.id === id);

        this.models.remove(application!);
    };

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super({});

        this.getApplications();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get applications(): ApplicationViewModel[] {
        return this.models
            .map((model) => new ApplicationViewModel(model, this.deleteApplication))
            .slice()
            .sort((a1, a2) => moment.utc(a1.lastUpdated).valueOf() - moment.utc(a2.lastUpdated).valueOf());
    }

    @computed
    public get numberOfApplications() {
        return this.applications.length;
    }

    @computed
    public get canDisplayFeeds(): boolean {
        return this.haveItems && this.displayApplications;
    }

    @computed
    public get canSelectItem(): boolean {
        return this.haveItems;
    }

    // #endregion Properties

    // #region Actions

    public getApplications = (): Promise<void> => {
        return this.server.query<ApplicationResponseModelDTO[]>(
            () => this.Get(AppUrls.Server.Public.Application.GetAllByUser),
            (result) => this.fromDtos(result),
        );
    };

    public deleteApplication = (id: string): Promise<void> => {
        return this.server.command(
            () =>
                this.Post(AppUrls.Server.Public.Application.Delete, {
                    id: id,
                }),
            () => this.deleteFromCollection(id),
            () => true,
        );
    };

    @action
    public addOrUpateCollection = (dto: MyApplicationsResponseModelDTO): void => {
        let model = this.models.find((m) => m.id === dto.id);

        if (isNullOrUndefined(model)) {
            model = new ApplicationModel();
            model.fromDto(dto);

            this.models.push(model);
        } else {
            model?.fromDto(dto);
        }
    };

    @action
    public setDisplayApplications = () => {
        if (this.haveItems) {
            this.displayApplications = !this.displayApplications;
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
