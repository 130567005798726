import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class FeedModel extends ModelBase<FeedModel, FeedResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_FEEDSUBCATEGORYID = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_ISSUBSCRIBED = false;

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = FeedModel.DEFAULT_ID;

    @observable
    public feedSubCategoryId: string = FeedModel.DEFAULT_FEEDSUBCATEGORYID;

    @observable
    public description: string = FeedModel.DEFAULT_DESCRIPTION;

    @observable
    public name: string = FeedModel.DEFAULT_NAME;

    @observable
    public isSubscribed: boolean = FeedModel.DEFAULT_ISSUBSCRIBED;

    @observable
    public displayOnMap: boolean = false;

    @observable
    public apiUrl: string = "";
    @observable
    public isMappable: boolean = false;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = FeedModel.DEFAULT_ID;
        this.feedSubCategoryId = FeedModel.DEFAULT_FEEDSUBCATEGORYID;
        this.description = FeedModel.DEFAULT_DESCRIPTION;
        this.name = FeedModel.DEFAULT_NAME;
        this.isSubscribed = FeedModel.DEFAULT_ISSUBSCRIBED;
    }

    @action
    public fromDto(dto: FeedResponseModelDTO): void {
        this.id = dto.id;
        this.feedSubCategoryId = dto.feedSubCategoryId;
        this.description = dto.description;
        this.name = dto.name;
        this.isSubscribed = FeedModel.DEFAULT_ISSUBSCRIBED;
        this.apiUrl = dto.apiUrl;
        this.isMappable = dto.isMappable;
    }

    @action
    public fromSubscribeDto(dto: SubscribeFeedModelResponseDto): void {
        this.isSubscribed = dto.isSubscribed;
    }

    @action
    public setSubscribed(value: boolean): void {
        this.isSubscribed = value;
    }

    public toSubscribeDto(): SubscribeFeedModelRequestDto {
        return {
            id: this.id,
            isSubscribed: this.isSubscribed,
        };
    }

    public toDto(): FeedResponseModelDTO {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface FeedResponseModelDTO {
    id: string;
    feedSubCategoryId: string;
    description: string;
    name: string;
    apiUrl: string;
    isMappable: boolean;
}

export interface SubscribeFeedModelRequestDto {
    id: string;
    isSubscribed: boolean;
}

export interface SubscribeFeedModelResponseDto {
    id: string;
    isSubscribed: boolean;
}
