import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminFeedModel, AdminGetFeedResponseModelDTO, AdminUpsertFeedResponseModelDTO } from "./AdminFeedModel";
import { AdminFeedCategory } from "./AdminFeedCategoryModel";
import { AdminFeedSubCategory } from "./AdminFeedSubCategoryModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminFeedViewModel extends ViewModelBase<AdminFeedModel> {
    // #region Constructors and Disposers

    constructor(id?: string) {
        super(new AdminFeedModel());

        this.setDecorators(AdminFeedModel);

        isEmptyOrWhitespace(id) ? this.getFeedSupportingOnly() : this.getFeedWithSupporting(id!);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get isExistingFeed(): boolean {
        return !isEmptyOrWhitespace(this.model.id);
    }

    // #endregion Properties

    // #region Categories

    @action
    public setFeedCategory = (value: AdminFeedCategory | null): void => {
        const result = value ? value.id : AdminFeedCategory.DEFAULT_ID;

        // Side-effect on subcategory.
        if (result !== this.model.feedCategoryId) {
            this.setFeedSubCategory(null);
        }

        this.model.feedCategoryId = result;
    };

    @computed
    public get feedCategory(): AdminFeedCategory | null {
        const result = this.model.feedCategories.find((fc) => fc.id === this.model.feedCategoryId);

        return result ? result! : null;
    }

    @computed
    public get feedCategories() {
        return this.model.feedCategories;
    }

    @computed
    public get confirmFeedCategoryValidationMessage(): string {
        const result = this.validateDecorators("feedCategoryId");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Categories

    // #region Categories

    @action
    public setFeedSubCategory = (value: AdminFeedSubCategory | null): void => {
        this.model.feedSubCategoryId = value ? value.id : AdminFeedSubCategory.DEFAULT_ID;
    };

    @computed
    public get feedSubCategory(): AdminFeedSubCategory | null {
        const result = this.model.feedSubCategories.find((fc) => fc.id === this.model.feedSubCategoryId);

        return result ? result! : null;
    }

    @computed
    public get feedSubCategories() {
        return this.model.feedSubCategories.filter((fsc) => fsc.feedCategoryId === this.model.feedCategoryId);
    }

    @computed
    public get confirmFeedSubCategoryValidationMessage(): string {
        const result = this.validateDecorators("feedSubCategoryId");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Categories

    // #region Description

    @action
    public setDescription = (value: string): void => {
        this.model.description = value;
    };

    @computed
    public get description(): string {
        return this.model.description;
    }

    @computed
    public get confirmDescriptionValidationMessage(): string {
        const result = this.validateDecorators("description");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Description

    // #region Name

    @action
    public setName = (value: string): void => {
        this.model.name = value;
    };

    @computed
    public get name(): string {
        return this.model.name;
    }

    @computed
    public get confirmNameValidationMessage(): string {
        const result = this.validateDecorators("name");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Name

    // #region ApiUrl

    @action
    public setApiUrl = (value: string): void => {
        this.model.apiUrl = value;
    };

    @computed
    public get apiUrl(): string {
        return this.model.apiUrl;
    }

    @computed
    public get confirmApiUrlValidationMessage(): string {
        const result = this.validateDecorators("apiUrl");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion ApiUrl

    // #region SchemaUrl

    @action
    public setSchemaUrl = (value: string): void => {
        this.model.schemaUrl = value;
    };

    @computed
    public get schemaUrl(): string {
        return this.model.schemaUrl;
    }

    @computed
    public get confirmSchemaUrlValidationMessage(): string {
        const result = this.validateDecorators("schemaUrl");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion SchemaUrl

    // #region IsActive

    @action
    public setIsActive = (): void => {
        this.model.isActive = !this.model.isActive;
    };

    @computed
    public get isActive(): boolean {
        return this.model.isActive;
    }

    // #endregion IsActive

    // #region Actions

    public getFeedSupportingOnly = (): Promise<void> => {
        return this.server.query<AdminGetFeedResponseModelDTO>(
            () => this.Get(AppUrls.Server.Admin.Feed.Get),
            (result) => this.model.fromGetDto(result, true),
        );
    };

    public getFeedWithSupporting = (id: string): Promise<void> => {
        return this.server.query<AdminGetFeedResponseModelDTO>(
            () =>
                this.Post(AppUrls.Server.Admin.Feed.Get, {
                    id: id,
                }),
            (result) => this.model.fromGetDto(result, false),
        );
    };

    public updateFeed = (): Promise<void> => {
        return this.server.command<AdminUpsertFeedResponseModelDTO>(
            () => this.Post(AppUrls.Server.Admin.Feed.Update, this.model.toDto()),
            (result) => this.model.fromDto(result),
            this.isModelValid,
            "There was an error trying to update the feed.",
        );
    };

    @action
    public navigateBackToFeeds = () => {
        this.history.push(AppUrls.Client.Admin.Feed.Feeds);
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<AdminFeedModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
