import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

import { AdminFeedCategory, AdminFeedCategoryResponseModelDTO } from "./AdminFeedCategoryModel";
import { AdminFeedSubCategory, AdminFeedSubCategoryResponseModelDTO } from "./AdminFeedSubCategoryModel";

export class AdminFeedModel extends ModelBase<AdminFeedModel, AdminUpsertFeedResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = undefined;
    public static readonly DEFAULT_FEEDCATEGORYID = "";
    public static readonly DEFAULT_FEEDSUBCATEGORYID = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_APIURL = "";
    public static readonly DEFAULT_SCHEMAURL = "";
    public static readonly DEFAULT_ISACTIVE = false;
    public static readonly DEFAULT_CREATEDDATETIMEUTC = "";
    public static readonly DEFAULT_CREATEDBYFIRSTNAME = "";
    public static readonly DEFAULT_CREATEDBYLASTNAME = "";
    public static readonly DEFAULT_MODIFIEDDATETIMEUTC = undefined;
    public static readonly DEFAULT_MODIFIEDBYFIRSTNAME = undefined;
    public static readonly DEFAULT_MODIFIEDBYLASTNAME = undefined;

    public static readonly DEFAULT_FEEDCATEGORIES = [];
    public static readonly DEFAULT_FEEDSUBCATEGORIES = [];

    // #endregion Defaults

    // #region Observables

    @observable
    public id?: string = AdminFeedModel.DEFAULT_ID;

    @observable
    @IsNotEmpty({ message: "You must provide a feed category" })
    public feedCategoryId: string = AdminFeedModel.DEFAULT_FEEDCATEGORYID;

    @observable
    @IsNotEmpty({ message: "You must provide a feed subcategory" })
    public feedSubCategoryId: string = AdminFeedModel.DEFAULT_FEEDSUBCATEGORYID;

    @observable
    @IsNotEmpty({ message: "You must provide a description" })
    public description: string = AdminFeedModel.DEFAULT_DESCRIPTION;

    @observable
    @IsNotEmpty({ message: "You must provide a title" })
    public name: string = AdminFeedModel.DEFAULT_NAME;

    @observable
    @IsNotEmpty({ message: "You must provide a url" })
    public apiUrl: string = AdminFeedModel.DEFAULT_APIURL;

    @observable
    @IsNotEmpty({ message: "You must provide a url" })
    public schemaUrl: string = AdminFeedModel.DEFAULT_SCHEMAURL;

    @observable
    public isActive: boolean = AdminFeedModel.DEFAULT_ISACTIVE;

    @observable
    public createdDateTimeUTC: string = AdminFeedModel.DEFAULT_CREATEDDATETIMEUTC;

    @observable
    public createdByFirstName: string = AdminFeedModel.DEFAULT_CREATEDBYFIRSTNAME;

    @observable
    public createdByLastName: string = AdminFeedModel.DEFAULT_CREATEDBYLASTNAME;

    @observable
    public modifiedDateTimeUTC?: string = AdminFeedModel.DEFAULT_MODIFIEDDATETIMEUTC;

    @observable
    public modifiedByFirstName?: string = AdminFeedModel.DEFAULT_MODIFIEDBYFIRSTNAME;

    @observable
    public modifiedByLastName?: string = AdminFeedModel.DEFAULT_MODIFIEDBYLASTNAME;

    @observable
    public feedCategories = observable<AdminFeedCategory>(AdminFeedModel.DEFAULT_FEEDCATEGORIES);

    @observable
    public feedSubCategories = observable<AdminFeedSubCategory>(AdminFeedModel.DEFAULT_FEEDSUBCATEGORIES);

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AdminFeedModel.DEFAULT_ID;
        this.feedCategoryId = AdminFeedModel.DEFAULT_FEEDCATEGORYID;
        this.feedSubCategoryId = AdminFeedModel.DEFAULT_FEEDSUBCATEGORYID;
        this.description = AdminFeedModel.DEFAULT_DESCRIPTION;
        this.name = AdminFeedModel.DEFAULT_NAME;
        this.apiUrl = AdminFeedModel.DEFAULT_APIURL;
        this.schemaUrl = AdminFeedModel.DEFAULT_SCHEMAURL;
        this.isActive = AdminFeedModel.DEFAULT_ISACTIVE;
        this.createdDateTimeUTC = AdminFeedModel.DEFAULT_CREATEDDATETIMEUTC;
        this.createdByFirstName = AdminFeedModel.DEFAULT_CREATEDBYFIRSTNAME;
        this.createdByLastName = AdminFeedModel.DEFAULT_CREATEDBYLASTNAME;
        this.modifiedDateTimeUTC = AdminFeedModel.DEFAULT_MODIFIEDDATETIMEUTC;
        this.modifiedByFirstName = AdminFeedModel.DEFAULT_MODIFIEDBYFIRSTNAME;
        this.modifiedByLastName = AdminFeedModel.DEFAULT_MODIFIEDBYLASTNAME;
        this.feedCategories.replace(AdminFeedModel.DEFAULT_FEEDCATEGORIES);
        this.feedSubCategories.replace(AdminFeedModel.DEFAULT_FEEDSUBCATEGORIES);
    }

    @action
    public fromDto(dto: AdminUpsertFeedResponseModelDTO): void {
        this.id = dto.id;
        this.feedSubCategoryId = dto.feedSubCategoryId;
        this.description = dto.description;
        this.name = dto.name;
        this.apiUrl = dto.apiUrl;
        this.schemaUrl = dto.schemaUrl;
        this.isActive = dto.isActive;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.createdByFirstName = dto.createdByFirstName;
        this.createdByLastName = dto.createdByLastName;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
        this.modifiedByFirstName = dto.modifiedByFirstName;
        this.modifiedByLastName = dto.modifiedByLastName;

        this.feedCategoryId = this.feedSubCategories.find((fsc) => fsc.id === this.feedSubCategoryId)!.feedCategoryId;
    }

    @action
    public fromGetDto(dto: AdminGetFeedResponseModelDTO, supportingOnly: boolean): void {
        for (const fcDto of dto.feedCategories) {
            const model = new AdminFeedCategory();

            model.fromDto(fcDto);

            this.feedCategories.push(model);
        }

        for (const fscDto of dto.feedSubCategories) {
            const model = new AdminFeedSubCategory();

            model.fromDto(fscDto);

            this.feedSubCategories.push(model);
        }

        if (!supportingOnly) {
            this.fromDto(dto);
        }
    }

    public toDto(): AdminUpsertFeedRequestModelDTO {
        return {
            id: this.id,
            feedSubCategoryId: this.feedSubCategoryId,
            description: this.description,
            name: this.name,
            apiUrl: this.apiUrl,
            schemaUrl: this.schemaUrl,
            isActive: this.isActive,
        };
    }

    // #endregion Actions
}

export interface AdminUpsertFeedRequestModelDTO {
    id?: string;
    feedSubCategoryId: string;
    description: string;
    name: string;
    apiUrl: string;
    schemaUrl: string;
    isActive: boolean;
}

export interface AdminUpsertFeedResponseModelDTO {
    id?: string;
    feedSubCategoryId: string;
    description: string;
    name: string;
    apiUrl: string;
    schemaUrl: string;
    isActive: boolean;
    createdDateTimeUTC: string;
    createdByFirstName: string;
    createdByLastName: string;
    modifiedDateTimeUTC?: string;
    modifiedByFirstName?: string;
    modifiedByLastName?: string;
}

export interface AdminGetFeedResponseModelDTO extends AdminUpsertFeedResponseModelDTO {
    feedCategories: AdminFeedCategoryResponseModelDTO[];
    feedSubCategories: AdminFeedSubCategoryResponseModelDTO[];
}
