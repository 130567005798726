import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";
import { ApplicationViewModel } from "./ApplicationViewModel";

// Styles
import { ApplicationStyles } from "Globals/Styles/Public/MyProfile/ApplicationStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";

interface IProps {
    viewModel: ApplicationViewModel;
}

interface IDeleteConfirmationDialogState {
    isOpen: boolean;
}

export const ApplicationView: React.FC<IProps> = (props) => {
    const applicationStyle = ApplicationStyles();
    const [openDeleteConfirmationState, setOpenDeleteConfirmation] = useState<IDeleteConfirmationDialogState>({ isOpen: false });

    const buttonStyles = ButtonStyles();

    const onOpenDeleteConfirmation = () => {
        setOpenDeleteConfirmation({ isOpen: true });
    };

    const onCloseConfirmDelete = () => {
        props.viewModel.delete();
        setOpenDeleteConfirmation({ isOpen: false });
    };

    const onCloseCancelDelete = () => {
        setOpenDeleteConfirmation({ isOpen: false });
    };

    const renderDeleteConfirmationDialog = () => {
        return (
            <Dialog onClose={onCloseCancelDelete} open={openDeleteConfirmationState.isOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography color="textPrimary">Are you sure you want to delete this?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={buttonStyles.containedSuccess} color="primary" onClick={onCloseConfirmDelete}>
                        Yes
                    </Button>
                    <Button autoFocus className={buttonStyles.containedCancel} color="primary" onClick={onCloseCancelDelete}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return useObserver(() => (
        <Box className={applicationStyle.root}>
            {openDeleteConfirmationState.isOpen && renderDeleteConfirmationDialog()}
            <Box>
                <Box>
                    <Box className={applicationStyle.topRow}>
                        <Box>
                            <Typography className={applicationStyle.innerRow} component="div" variant="h4">
                                {props.viewModel.name}
                            </Typography>
                            <Typography className={applicationStyle.innerRow} component="div" variant="h4">
                                <b>Last updated: </b>
                                {props.viewModel.lastUpdatedFormatted}
                            </Typography>
                        </Box>
                        <IconButton onClick={onOpenDeleteConfirmation}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Typography className={applicationStyle.innerRow} component="div" variant="body2">
                        {props.viewModel.description}
                    </Typography>
                    <Typography className={applicationStyle.innerRow} component="div" variant="body2">
                        <b>API key: </b>
                        {props.viewModel.apiKey}
                    </Typography>
                </Box>
            </Box>
        </Box>
    ));
};
