import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class AdminFeedSubCategory extends ModelBase<AdminFeedSubCategory, AdminFeedSubCategoryResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_FEEDCATEGORYID = "";
    public static readonly DEFAULT_DISPLAYNAME = "";

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = AdminFeedSubCategory.DEFAULT_ID;

    @observable
    public feedCategoryId: string = AdminFeedSubCategory.DEFAULT_FEEDCATEGORYID;

    @observable
    public displayName: string = AdminFeedSubCategory.DEFAULT_DISPLAYNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AdminFeedSubCategory.DEFAULT_ID;
        this.feedCategoryId = AdminFeedSubCategory.DEFAULT_FEEDCATEGORYID;
        this.displayName = AdminFeedSubCategory.DEFAULT_DISPLAYNAME;
    }

    @action
    public fromDto(dto: AdminFeedSubCategoryResponseModelDTO): void {
        this.id = dto.id;
        this.feedCategoryId = dto.feedCategoryId;
        this.displayName = dto.displayName;
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface AdminFeedSubCategoryResponseModelDTO {
    id: string;
    feedCategoryId: string;
    displayName: string;
}
