import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class AdminFeedModel extends ModelBase<AdminFeedModel> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_ISACTIVE = false;
    public static readonly DEFAULT_CREATEDDATETIMEUTC = "";
    public static readonly DEFAULT_CREATEDBYFIRSTNAME = "";
    public static readonly DEFAULT_CREATEDBYLASTNAME = "";
    public static readonly DEFAULT_MODIFIEDDATETIMEUTC = undefined;
    public static readonly DEFAULT_MODIFIEDBYFIRSTNAME = undefined;
    public static readonly DEFAULT_MODIFIEDBYLASTNAME = undefined;

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = AdminFeedModel.DEFAULT_ID;

    @observable
    public description: string = AdminFeedModel.DEFAULT_DESCRIPTION;

    @observable
    public name: string = AdminFeedModel.DEFAULT_NAME;

    @observable
    public isActive: boolean = AdminFeedModel.DEFAULT_ISACTIVE;

    @observable
    public createdDateTimeUTC: string = AdminFeedModel.DEFAULT_CREATEDDATETIMEUTC;

    @observable
    public createdByFirstName: string = AdminFeedModel.DEFAULT_CREATEDBYFIRSTNAME;

    @observable
    public createdByLastName: string = AdminFeedModel.DEFAULT_CREATEDBYLASTNAME;

    @observable
    public modifiedDateTimeUTC?: string = AdminFeedModel.DEFAULT_MODIFIEDDATETIMEUTC;

    @observable
    public modifiedByFirstName?: string = AdminFeedModel.DEFAULT_MODIFIEDBYFIRSTNAME;

    @observable
    public modifiedByLastName?: string = AdminFeedModel.DEFAULT_MODIFIEDBYLASTNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AdminFeedModel.DEFAULT_ID;
        this.description = AdminFeedModel.DEFAULT_DESCRIPTION;
        this.name = AdminFeedModel.DEFAULT_NAME;
        this.isActive = AdminFeedModel.DEFAULT_ISACTIVE;
        this.createdDateTimeUTC = AdminFeedModel.DEFAULT_CREATEDDATETIMEUTC;
        this.createdByFirstName = AdminFeedModel.DEFAULT_CREATEDBYFIRSTNAME;
        this.createdByLastName = AdminFeedModel.DEFAULT_CREATEDBYLASTNAME;
        this.modifiedDateTimeUTC = AdminFeedModel.DEFAULT_MODIFIEDDATETIMEUTC;
        this.modifiedByFirstName = AdminFeedModel.DEFAULT_MODIFIEDBYFIRSTNAME;
        this.modifiedByLastName = AdminFeedModel.DEFAULT_MODIFIEDBYLASTNAME;
    }

    @action
    public fromPublishDto(dto: AdminPublishFeedResponseModelDto): void {
        this.isActive = dto.isActive;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
        this.modifiedByFirstName = dto.modifiedByFirstName;
        this.modifiedByLastName = dto.modifiedByLastName;
    }

    @action
    public fromDto(dto: AdminGetFeedResponseModelDTO): void {
        this.id = dto.id;
        this.description = dto.description;
        this.name = dto.name;
        this.isActive = dto.isActive;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.createdByFirstName = dto.createdByFirstName;
        this.createdByLastName = dto.createdByLastName;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
        this.modifiedByFirstName = dto.modifiedByFirstName;
        this.modifiedByLastName = dto.modifiedByLastName;
    }

    public toPublishDto(): AdminPublishFeedModelRequestDto {
        return {
            id: this.id,
            isActive: this.isActive,
        };
    }

    public toDto() {
        throw new Error("Not implemented");
    }

    // #endregion Actions
}

export interface AdminGetFeedResponseModelDTO {
    id: string;
    description: string;
    name: string;
    isActive: boolean;
    createdDateTimeUTC: string;
    createdByFirstName: string;
    createdByLastName: string;
    modifiedDateTimeUTC?: string;
    modifiedByFirstName?: string;
    modifiedByLastName?: string;
}

export interface AdminPublishFeedModelRequestDto {
    id: string;
    isActive: boolean;
}

export interface AdminPublishFeedResponseModelDto {
    id: string;
    isActive: boolean;
    modifiedDateTimeUTC?: string;
    modifiedByFirstName?: string;
    modifiedByLastName?: string;
}
