import { ModelBase } from "@shoothill/core";
import { IsEmail, IsNotEmpty } from "class-validator";
import { observable } from "mobx";

export class AdminHomeModel extends ModelBase<AdminHomeModel, AdminHomeModelDTO> {
    // #region Observables

    @observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = "";

    @observable
    @IsNotEmpty({ message: "You must enter a password" })
    public password: string = "";

    @observable
    public rememberMe: boolean = false;

    // #endregion Observables

    // #region Actions

    public fromDto(model: AdminHomeModelDTO): void {
        throw "Not implemented";
    }

    public toDto(): AdminHomeModelDTO {
        return {
            emailAddress: this.emailAddress,
            password: this.password,
            rememberMe: this.rememberMe,
        };
    }

    // #endregion Actions
}

export interface AdminHomeModelDTO {
    emailAddress: string;
    password: string;
    rememberMe: boolean;
}
