import { AccountStatus, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { LoginModel } from "./LoginModel";

// Urls
import { AppUrls } from "AppUrls";

export class LoginViewModel extends ViewModelBase<LoginModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new LoginModel());

        this.setDecorators(LoginModel);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region Password

    @action
    public setPassword = (value: string): void => {
        this.model.password = value;
    };

    @computed
    public get password(): string {
        return this.model.password;
    }

    @computed
    public get passwordValidationMessage(): string {
        const result = this.validateDecorators("password");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Password

    // #region Remember Me

    @action
    public setRememberMe = (): void => {
        this.model.rememberMe = !this.model.rememberMe;
    };

    @computed
    public get rememberMe(): boolean {
        return this.model.rememberMe;
    }

    // #endregion Remember Me

    // #region Actions

    @action
    public logIn = async (): Promise<void> => {
        return this.server.command<AccountStatus>(
            () => this.Post(AppUrls.Server.Account.Login, this.model.toDto()),
            (result) => StoresInstance.Domain.AccountStore.getLoginState(result),
            this.isModelValid,
            "There was an error trying to log in.",
        );
    };

    @action
    public navigateToForgotPassword = (): void => {
        this.history.push(AppUrls.Client.Account.ForgotPassword);
    };

    @action
    public navigateToRegister = (): void => {
        this.history.push(AppUrls.Client.Account.Register);
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<LoginModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
