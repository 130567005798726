import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import App from "./App";
import { CoreStoreInstance, getBaseUrl, Loader } from "@shoothill/core";
import { InitViewModel } from "Globals/ViewModels/InitViewModel";
import { AppErrorBoundary } from "AppErrorBoundary";
import * as MobX from "mobx";
import * as History from "history";
import { Error } from "Globals/Views";
import { useObserver } from "mobx-react-lite";

MobX.configure({ enforceActions: "observed" });

const baseUrl: string = getBaseUrl();
export const GlobalHistory: History.History = History.createBrowserHistory({
    basename: baseUrl,
});
//(window as any).GlobalHistory = GlobalHistory;

GlobalHistory.listen((location: any) => {
    if (CoreStoreInstance) {
        CoreStoreInstance.PageView(location.pathname);
    }
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === "POP") {
            return;
        }
        // In all other cases, check fragment/scroll to top
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
});

export const IsDevMode: boolean = process.env.NODE_ENV === "development";
window.IsDev = IsDevMode;

const Init: React.FC = () => {
    const [viewModel] = useState(() => new InitViewModel());
    useEffect(() => {
        const _ = viewModel.getInitialState();
    }, []);
    const getRender = () => {
        if (viewModel.IsLoading) {
            // EN: Delayed in code behind so we can see the loading text
            return <Loader delayBeforeShow={0} />;
        } else if (viewModel.IsErrored) {
            return <Error supportEmail={"support@shoothill.com"} />;
        }

        if (viewModel.DataReady) {
            return (
                <AppErrorBoundary>
                    <App />
                </AppErrorBoundary>
            );
        }
        return <></>;
    };
    return useObserver(() => {
        return <>{getRender()}</>;
    });
};

ReactDOM.render(<Init />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
