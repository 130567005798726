import React from "react";
import { Box } from "@material-ui/core";
import { UnsupportedBrowserView } from "../../../Views/UnsupportedBrowser/UnsupportedBrowserView";

//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Box>
                        {/* <Header /> */}
                        {props && <div>{props.children}</div>}
                        {/* <Footer /> */}
                    </Box>
                </>
            )}
        </>
    );
};
