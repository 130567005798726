import { FieldType, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";

import { AppUrls } from "../../../../AppUrls";

export class AdminFooterViewModel extends ViewModelBase<any> {
    constructor() {
        super({});
    }

    // #region Actions

    @action
    public navigatePrivacyPolicy = () => {
        this.history.push(AppUrls.Client.PrivacyPolicy);
    };

    @action
    public navigateTermsAndConditions = () => {
        this.history.push(AppUrls.Client.TermsAndConditions);
    };

    // #endregion Actions

    // Properties

    public get Copyright() {
        return `© ${new Date().getFullYear()} - Reading ODS Data HUB`;
    }

    // Properties

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
