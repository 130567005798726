import { makeStyles, Theme } from "@material-ui/core";
import { pxToRem } from "Globals/Styles/AppTheme";
import L from "leaflet";

const gutterSpacing = 30;

const getCanSelectFeedCategory = (props: any) => {
    return props.viewModel.canSelectItem;
};

export const getMapIcon = () => {
    let data = "url(resources/energy.svg)";
    const myIcon = new L.Icon({
        iconUrl: getIcon("PARKING").replace("url(", "").replace(")", ""),
        iconRetinaUrl: "resources/energy.svg",
        popupAnchor: [-0, -0],
        iconSize: [32, 45],
    });
    return myIcon;
};

export const getIcon = (type: string) => {
    switch (type) {
        case "ENERGY":
            return "url(resources/energy.svg)";

        case "ENVIRONMENT":
            return "url(resources/environment.svg)";

        case "HEALTHWELLBEING":
            return "url(resources/health.svg)";

        case "VARIABLEMESSAGESIGN":
            return "url(resources/variableSign.svg)";

        case "ROAD":
            return "url(resources/road.svg)";

        case "ROADWORKS":
            return "url(resources/roadworks.svg)";

        case "ANPR":
            return "url(resources/anpr.svg)";

        case "PARKING":
            return "url(resources/parking.svg)";

        case "BUS":
        case "TRANSPORT":
        default:
            return "url(resources/transport.svg)";
    }
};

const getColor = (theme: Theme, props: any) => {
    switch (props.viewModel.model.type) {
        case "ENERGY":
            return theme.palette.secondary;

        case "ENVIRONMENT":
            return theme.palette.info;

        case "HEALTHWELLBEING":
            return theme.palette.adminGrey;

        case "VARIABLEMESSAGESIGN":
        case "ROAD":
        case "ROADWORKS":
        case "ANPR":
        case "PARKING":
        case "BUS":
        case "TRANSPORT":
        default:
            return theme.palette.primary;
    }
};

export const FeedsStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
        },
    },
    titleContainer: {
        alignItems: "center",
        display: "flex",
        padding: pxToRem(gutterSpacing),
        justifyContent: "space-between",
    },
    searchContainer: {
        padding: `0 ${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)}`,
        "& > div": {
            borderTop: "1px solid #CED4DA",
            display: "flex",
            paddingTop: pxToRem(gutterSpacing),
            "& > div": {
                flex: 1,
                "& > div": {
                    marginTop: "0 !important",
                },
            },
        },
    },
}));

export const FeedCategoryStyles = makeStyles((theme) => ({
    root: {},
    innerRow: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: (props) => getColor(theme, props).main,
        color: (props) => getColor(theme, props).contrastText,
        "& > div": {
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            minHeight: pxToRem(100),
            padding: pxToRem(gutterSpacing),
        },
        "&:hover": {
            backgroundColor: (props) => (getCanSelectFeedCategory(props) ? getColor(theme, props).dark : getColor(theme, props).main),
            cursor: (props) => (getCanSelectFeedCategory(props) ? "pointer" : "auto"),
        },
    },
    innerRowWithSubCategories: {
        display: "flex",
        justifyContent: "flex-start",
        "& > div": {
            alignItems: "center",
            display: "flex",
            flex: 1,
            fontSize: "10px",
            justifyContent: "flex-start",
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            minHeight: pxToRem(100),
            padding: pxToRem(gutterSpacing),
        },
    },
    iconText: {
        backgroundImage: (props: any) => getIcon(props!.viewModel!.model.type),
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        minHeight: pxToRem(40),
        minWidth: pxToRem(40),
        paddingLeft: pxToRem(75),
    },
    buttonText: {
        color: "white",
        alignItems: "center",
        display: "flex",
        "& > button": {
            marginRight: pxToRem(gutterSpacing),
        },
    },
    countArrow: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        maxWidth: pxToRem(160),
    },
}));

export const FeedSubCategoryStyles = makeStyles((theme) => ({
    root: {},
    innerRow: {
        borderBottom: (props) => `1px solid ${getColor(theme, props).light}`,
        display: "flex",
        justifyContent: "center",
        backgroundColor: (props) => getColor(theme, props).main,
        color: (props) => getColor(theme, props).contrastText,
        "& > div": {
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            minHeight: pxToRem(99),
            padding: pxToRem(gutterSpacing),
        },
        "&:hover": {
            backgroundColor: (props) => (getCanSelectFeedCategory(props) ? getColor(theme, props).dark : getColor(theme, props).main),
            cursor: (props) => (getCanSelectFeedCategory(props) ? "pointer" : "auto"),
        },
    },
    innerRowv2: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            borderBottom: (props) => `1px solid ${getColor(theme, props).light}`,
            backgroundColor: (props) => getColor(theme, props).main,
            color: (props) => getColor(theme, props).contrastText,
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            margin: `0 ${pxToRem(gutterSpacing)}`,
            maxWidth: pxToRem(960),
            minHeight: pxToRem(99),
            padding: pxToRem(gutterSpacing),
            "&:hover": {
                backgroundColor: (props) => (getCanSelectFeedCategory(props) ? getColor(theme, props).dark : getColor(theme, props).main),
                cursor: (props) => (getCanSelectFeedCategory(props) ? "pointer" : "auto"),
            },
        },
    },
    iconText: {
        backgroundImage: (props: any) => getIcon(props!.viewModel!.model.type),
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        minHeight: pxToRem(40),
        minWidth: pxToRem(40),
        paddingLeft: pxToRem(75),
    },
    countArrow: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        maxWidth: pxToRem(160),
        "& > svg": {
            fontSize: pxToRem(39),
        },
    },
    items: {
        display: "flex",
        flexDirection: "column",
        margin: `${pxToRem(gutterSpacing / 2)} 0`,
    },
}));

export const FeedMapStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            flex: 1,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            "& > div": {
                backgroundColor: "#F3F3F3",
                borderLeft: (props) => `${pxToRem(gutterSpacing / 2)} solid ${theme.palette.primary.light}`,
                margin: `${pxToRem(gutterSpacing / 10)} ${pxToRem(gutterSpacing / 5)}`,
                minHeight: pxToRem(100),
                padding: `${pxToRem(5)} ${pxToRem(gutterSpacing)}`,
            },
        },
    },
    innerRow: {
        padding: `${pxToRem(10)} 0`,
    },
    featureRow: {
        paddingBottom: pxToRem(gutterSpacing / 2),
    },
}));

export const MyFeedsStyles = makeStyles((theme) => ({
    root: {
        margin: `${pxToRem(gutterSpacing)} 0`,
    },
    emptyWarning: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            margin: `0 ${pxToRem(gutterSpacing)}`,
            maxWidth: pxToRem(960),
        },
    },
}));
