import { Box, Button, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Alert } from "@material-ui/lab";
import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { ButtonStyles } from "../../../../Globals/Styles/Primitives/ButtonStyles";
import { CheckBoxStyles } from "../../../../Globals/Styles/Primitives/CheckBoxStyles";
import { EditTextStyles } from "../../../../Globals/Styles/Primitives/EditTextStyles";
import { LoginStyles } from "../../../../Globals/Styles/Public/SubViews/LoginStyles";

// ViewModels
import { LoginViewModel } from "./LoginViewModel";

// Views
import { CheckBox } from "../../../Primitives/CheckBoxes";
import { EditText } from "../../../Primitives/EditText";

interface LoginProps {
    className?: string;
}

export const Login: React.FC<LoginProps> = (props) => {
    const [viewModel] = useState(() => new LoginViewModel());

    const buttonStyles = ButtonStyles();
    const checkBoxStyles = CheckBoxStyles();
    const editTextStyles = EditTextStyles();
    const classes = LoginStyles();

    // #region Code Behind

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            [classes.root]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.logIn();
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getClasses()}>
            <form className={classes.login} onSubmit={onSubmit}>
                <Box>
                    <Typography gutterBottom variant="h3">
                        Log in:
                    </Typography>
                </Box>
                <Box className={classes.formRow}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicLight}
                        displayName="Email address"
                        execute={viewModel.setEmailAddress}
                        gutterBottom={true}
                        placeholder="Your email address"
                        validationMessage={viewModel.emailAddressValidationMessage}
                        value={viewModel.emailAddress}
                    />
                </Box>
                <Box className={classes.formRow}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicLight}
                        displayName="Password"
                        execute={viewModel.setPassword}
                        isPassword={true}
                        placeholder="Your password"
                        validationMessage={viewModel.passwordValidationMessage}
                        value={viewModel.password}
                    />
                </Box>
                <Box className={classes.formRow}>
                    <CheckBox
                        canExecute={!viewModel.server.IsBusy}
                        className={checkBoxStyles.publicLight}
                        displayName="Remember me"
                        execute={viewModel.setRememberMe}
                        value={viewModel.rememberMe}
                    />
                </Box>
                <Box className={classes.formRow}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Log in
                    </Button>
                    <Button className={buttonStyles.forgotPassword} disabled={viewModel.server.IsBusy} onClick={viewModel.navigateToForgotPassword}>
                        Forgot password
                    </Button>
                </Box>
                {viewModel.server.HaveValidationMessage && (
                    <Alert className={classes.alert} severity="error" variant="filled">
                        {viewModel.server.ValidationMessage}
                    </Alert>
                )}
            </form>
            <Box className={classes.register}>
                <Button className={buttonStyles.containedRegister} endIcon={<ArrowForwardIcon />} onClick={viewModel.navigateToRegister} variant="contained">
                    Register now
                </Button>
            </Box>
        </Box>
    ));
};
