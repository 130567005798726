import { Box, Button, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Styles
import { ApplicationsStyles } from "Globals/Styles/Public/MyProfile/ApplicationStyles";

// ViewModels
import { ApplicationsViewModel } from "./ApplicationsViewModel";
import { ApplicationView } from "./ApplicationView";

interface IProps {
    viewModel: ApplicationsViewModel;
}

export const ApplicationsView: React.FC<IProps> = (props) => {
    const applicationsStyles = ApplicationsStyles(props);

    const renderHeader = (): JSX.Element => {
        return (
            <Box className={applicationsStyles.innerRowv2}>
                <Box onClick={props.viewModel.setDisplayApplications}>
                    <Typography className={applicationsStyles.iconText} component="div" variant="h2">
                        My applications
                    </Typography>
                    <Box className={applicationsStyles.countArrow}>
                        <Typography component="div" variant="body1">
                            {props.viewModel.numberOfApplications} applications
                        </Typography>
                        {props.viewModel.canDisplayFeeds ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Box>
                </Box>
            </Box>
        );
    };

    return useObserver(() => (
        <Box className={applicationsStyles.root}>
            {renderHeader()}
            {props.viewModel.canDisplayFeeds && (
                <Box className={applicationsStyles.items}>
                    {props.viewModel.applications.map((application) => (
                        <ApplicationView key={application.id} viewModel={application} />
                    ))}
                </Box>
            )}
        </Box>
    ));
};
