import { Box, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";
import { FeedsStyles } from "Globals/Styles/Public/Home/FeedStyles";

// ViewModels
import { FeedsViewModel } from "./FeedsViewModel";

// Views
import { FeedCategoryView } from "./FeedCategoryView";
import { EditText } from "Views/Primitives/EditText";

export const FeedsView: React.FC = () => {
    const [viewModel] = useState(() => new FeedsViewModel());
    useEffect(() => {
        return () => viewModel.dispose();
    }, []);

    const feedsStyles = FeedsStyles();
    const editTextStyles = EditTextStyles();

    const canDisplayHeader = (): boolean => {
        return viewModel.activeFeedCategory === "";
    };

    const canDisplayNoFeedsMessage = (): boolean => {
        return viewModel.search.haveActiveKeyword && viewModel.numberOfFilteredFeeds === 0;
    };

    const onHandleSearchEnterKey = () => {
        if (viewModel.search.haveKeyword) {
            viewModel.search.apply();
        }
    };

    const getSearchAdornment = (): JSX.Element | undefined => {
        return viewModel.search.haveKeyword ? (
            <Box className={editTextStyles.endAdornment}>
                <IconButton className={editTextStyles.endAdornmentButton} onClick={viewModel.search.reset}>
                    <ClearIcon />
                </IconButton>
                <IconButton className={editTextStyles.endAdornmentButton} onClick={viewModel.search.apply}>
                    <SearchIcon />
                </IconButton>
            </Box>
        ) : undefined;
    };

    return useObserver(() => (
        <Box>
            {canDisplayHeader() && (
                <Box className={feedsStyles.header}>
                    <Box>
                        <Box className={feedsStyles.titleContainer}>
                            <Typography component="div" variant="h1">
                                Feed categories
                            </Typography>
                            <Typography component="div" variant="body2">
                                {viewModel.numberOfFilteredFeeds} feeds
                            </Typography>
                        </Box>
                        <Box className={feedsStyles.searchContainer}>
                            <Box>
                                <EditText
                                    className={editTextStyles.publicDark}
                                    displayName=""
                                    execute={viewModel.search.setKeyword}
                                    executeOnEnter={onHandleSearchEnterKey}
                                    inputProps={{
                                        endAdornment: getSearchAdornment(),
                                    }}
                                    placeholder="Search for a keyword here"
                                    validationMessage={canDisplayNoFeedsMessage() ? "No feeds match your search" : ""}
                                    value={viewModel.search.keyword}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {viewModel.filteredFeedCategories.map((feedCategory) => (
                <FeedCategoryView key={feedCategory.id} viewModel={feedCategory} />
            ))}
        </Box>
    ));
};
