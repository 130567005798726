import { FieldType, ViewModelBase } from "@shoothill/core";
import { StoresInstance } from "Globals/Stores";
import { action, computed, observable } from "mobx";

import { AppUrls } from "../../../../AppUrls";

export class AdminHeaderViewModel extends ViewModelBase<any> {
    constructor() {
        super({});

        this.history.listen((location) => {
            this.setActiveOption(location.pathname);
        });

        this.setActiveOption(location.pathname);
    }

    // #region Properties

    @observable
    public activeOption = "";

    // #endregion Properties

    // #region Actions

    @action
    public navigateToHome = () => {
        if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
            this.history.push(AppUrls.Client.Admin.User.Users);
        } else {
            this.history.push(AppUrls.Client.Admin.Account.Home);
        }
    };

    @action
    public navigateToFaqs = () => {
        this.history.push(AppUrls.Client.Admin.Faq.Faqs);
    };

    @action
    public navigateToFeeds = () => {
        this.history.push(AppUrls.Client.Admin.Feed.Feeds);
    };

    @action
    public navigateToUsers = () => {
        this.history.push(AppUrls.Client.Admin.User.Users);
    };

    @action
    public navigateToLogout = async () => {
        try {
            if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
                const apiResult = await this.Post(AppUrls.Server.Account.Logout);
            }
        } catch (exception) {
            // If there was an error logging out there is nothing we can do.
        } finally {
            StoresInstance.Domain.AccountStore.Logout();
            this.history.push(AppUrls.Client.Admin.Account.Home);
        }
    };

    @action
    private setActiveOption = (path: string) => {
        this.activeOption = path;
    };

    // #endregion Actions

    // #region Computeds

    @computed
    public get IsFaqsActive() {
        return this.activeOption === AppUrls.Client.Admin.Faq.Faq || this.activeOption === AppUrls.Client.Admin.Faq.Faqs;
    }

    @computed
    public get IsFeedsActive() {
        return this.activeOption === AppUrls.Client.Admin.Feed.Feed || this.activeOption === AppUrls.Client.Admin.Feed.Feeds;
    }

    @computed
    public get IsUsersActive() {
        return this.activeOption === AppUrls.Client.Admin.User.User || this.activeOption === AppUrls.Client.Admin.User.Users;
    }

    // #endregion Computeds

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
