import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import MaterialTable from "material-table";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

// Styles
import { AdminTableWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";

// ViewModels
import { AdminFaqsViewModel } from "./AdminFaqsViewModel";
import { AdminFaqViewModel } from "./AdminFaqViewModel";

interface IDeleteConfirmationDialogState {
    isOpen: boolean;
    delete?: () => void;
}

interface IUnpublishConfirmationDialogState {
    isOpen: boolean;
    unpublish?: () => Promise<void>;
}

export const AdminFaqsView: React.FC = () => {
    const [viewModel] = useState(() => new AdminFaqsViewModel());
    const [openDeleteConfirmationState, setOpenDeleteConfirmation] = useState<IDeleteConfirmationDialogState>({ isOpen: false, delete: undefined });
    const [openUnpublishConfirmationState, setOpenUnpublishConfirmation] = useState<IUnpublishConfirmationDialogState>({ isOpen: false, unpublish: undefined });

    const buttonStyles = ButtonStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const tableWorkspaceClasses = AdminTableWorkspaceStyles();

    useEffect(() => {
        document.title = "FAQ management";
    }, []);

    const tableRef = useRef<any>();

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    const renderRowLastUpdated = (rowData: AdminFaqViewModel) => {
        return rowData.lastUpdatedFormatted;
    };

    const onRowClick = (e: any, rowData: any) => {
        viewModel.navigateToFaq(rowData.id);
    };

    // #endregion Code Behind

    // #region Delete Code Behind

    const onOpenDeleteConfirmation = (event: any, rowData: AdminFaqViewModel) => {
        event.stopPropagation();
        setOpenDeleteConfirmation({ isOpen: true, delete: rowData.deleteFaq });
    };

    const onCloseConfirmDelete = () => {
        openDeleteConfirmationState.delete?.();
        setOpenDeleteConfirmation({ isOpen: false, delete: undefined });
    };

    const onCloseCancelDelete = () => {
        setOpenDeleteConfirmation({ isOpen: false, delete: undefined });
    };

    const renderDeleteConfirmationDialog = () => {
        return (
            <Dialog onClose={onCloseCancelDelete} open={openDeleteConfirmationState.isOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography color="textPrimary">Are you sure you want to delete this?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={buttonStyles.containedSuccess} color="primary" onClick={onCloseConfirmDelete}>
                        Yes
                    </Button>
                    <Button autoFocus className={buttonStyles.containedCancel} color="primary" onClick={onCloseCancelDelete}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderRowDeleteButton = (rowData: AdminFaqViewModel) => {
        return (
            <IconButton onClick={(event: any) => onOpenDeleteConfirmation(event, rowData)}>
                <DeleteIcon />
            </IconButton>
        );
    };

    // #endregion Delete Code Behind

    // #region Publish/Unpublish Code Behind

    const onOpenUnpublishConfirmation = (event: any, rowData: AdminFaqViewModel) => {
        event.stopPropagation();
        setOpenUnpublishConfirmation({ isOpen: true, unpublish: rowData.publishFaq });
    };

    const onCloseConfirmUnpublish = async () => {
        await openUnpublishConfirmationState.unpublish?.();
        setOpenUnpublishConfirmation({ isOpen: false, unpublish: undefined });
    };

    const onCloseCancelUnpublish = () => {
        setOpenUnpublishConfirmation({ isOpen: false, unpublish: undefined });
    };

    const renderUnpublishConfirmationDialog = () => {
        return (
            <Dialog onClose={onCloseCancelUnpublish} open={openUnpublishConfirmationState.isOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography color="textPrimary">Are you sure you want to unpublish this?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={buttonStyles.containedSuccess} color="primary" onClick={onCloseConfirmUnpublish}>
                        Yes
                    </Button>
                    <Button autoFocus className={buttonStyles.containedCancel} color="primary" onClick={onCloseCancelUnpublish}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderRowCheckedButton = (rowData: AdminFaqViewModel) => {
        return (
            <IconButton
                onClick={async (event: any) => {
                    const materialTable = tableRef.current;

                    event.stopPropagation();

                    if (rowData.isPublished) {
                        onOpenUnpublishConfirmation(event, rowData);
                    } else {
                        await rowData.publishFaq();

                        if (materialTable) {
                            materialTable.setState({
                                ...materialTable.dataManager.getRenderState(),
                            });
                        }
                    }
                }}
            >
                {rowData.isPublished ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </IconButton>
        );
    };

    // #endregion Publish/Unpublish Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            {openDeleteConfirmationState.isOpen && renderDeleteConfirmationDialog()}
            {openUnpublishConfirmationState.isOpen && renderUnpublishConfirmationDialog()}
            <Box className={tableWorkspaceClasses.titleContainer}>
                <Typography variant="h2">FAQ management</Typography>
                <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateAddNewFaq} variant="contained">
                    Add new FAQ
                </Button>
            </Box>
            <Box className={tableWorkspaceClasses.tableContainer}>
                <MaterialTable
                    columns={[
                        {
                            title: "Question",
                            field: "question",
                        },
                        {
                            title: "Last updated by",
                            field: "lastUpdatedBy",
                            cellStyle: {
                                maxWidth: "20%",
                                width: "20%",
                            },
                            headerStyle: {
                                maxWidth: "20%",
                                width: "20%",
                            },
                        },
                        {
                            title: "Last updated",
                            field: "lastUpdated",
                            render: (rowData: AdminFaqViewModel) => renderRowLastUpdated(rowData),
                            cellStyle: {
                                maxWidth: "15%",
                                width: "15%",
                            },
                            headerStyle: {
                                maxWidth: "15%",
                                width: "15%",
                            },
                        },
                        {
                            title: "Published",
                            field: "",
                            render: (rowData: AdminFaqViewModel) => renderRowCheckedButton(rowData),
                            cellStyle: {
                                maxWidth: "10%",
                                width: "10%",
                            },
                            headerStyle: {
                                maxWidth: "10%",
                                width: "10%",
                            },
                        },
                        {
                            title: "",
                            field: "",
                            render: (rowData: AdminFaqViewModel) => renderRowDeleteButton(rowData),
                            cellStyle: {
                                maxWidth: "5%",
                                width: "5%",
                            },
                            headerStyle: {
                                maxWidth: "5%",
                                width: "5%",
                            },
                        },
                    ]}
                    data={viewModel.faqs}
                    onRowClick={onRowClick}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        toolbar: false,
                    }}
                    tableRef={tableRef}
                />
            </Box>
        </Box>
    ));
};
