import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid, useRouter } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { AdminFaqStyles } from "Globals/Styles/Admin/Faqs/AdminFaqStyles";
import { AdminFormWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { CheckBoxStyles } from "Globals/Styles/Primitives/CheckBoxStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { AdminFaqViewModel } from "./AdminFaqViewModel";

// Views
import { CheckBox } from "Views/Primitives/CheckBoxes";
import { EditText } from "Views/Primitives/EditText";
import { EditTextArea } from "Views/Primitives/EditTextArea";

export const AdminFaqView: React.FC = () => {
    const { match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new AdminFaqViewModel(id));

    const buttonStyles = ButtonStyles();
    const checkBoxStyles = CheckBoxStyles();
    const editTextStyles = EditTextStyles();
    const classes = AdminFaqStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const formWorkspaceClasses = AdminFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Add/edit FAQ";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const getFormContainerClasses = () => {
        return clsx({
            [classes.formContainer]: true,
            [formWorkspaceClasses.formContainer]: true,
        });
    };

    const getFormClasses = () => {
        return clsx({
            [formWorkspaceClasses.form]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.updateFaq();
    };

    const renderFaq = () => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Question*"
                        execute={viewModel.setQuestion}
                        placeholder="Your question"
                        validationMessage={viewModel.confirmQuestionValidationMessage}
                        value={viewModel.question}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <EditTextArea
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Answer*"
                        execute={viewModel.setAnswer}
                        numberOfRows={6}
                        placeholder="Your answer"
                        validationMessage={viewModel.confirmAnswerValidationMessage}
                        value={viewModel.answer}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <CheckBox
                        canExecute={!viewModel.server.IsBusy}
                        className={checkBoxStyles.publicDark}
                        displayName="Publish now"
                        execute={viewModel.setIsActive}
                        value={viewModel.isActive}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRow} columnGap={30} rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Update
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
                {viewModel.server.IsRequestSuccessful && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="info" variant="filled">
                            Your faq has been updated.
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={formWorkspaceClasses.titleContainer}>
                <Typography variant="h2">{viewModel.isExistingFaq ? "Edit FAQ" : "Add new FAQ"}</Typography>
                <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateBackToFaqs} variant="contained">
                    Back
                </Button>
            </Box>
            <Box className={getFormContainerClasses()}>
                <form className={getFormClasses()} onSubmit={onSubmit}>
                    {renderFaq()}
                </form>
            </Box>
        </Box>
    ));
};
