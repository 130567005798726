import { Box } from "@material-ui/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { PublicHeaderStyles } from "../../../../Globals/Styles/Public/PublicHeaderStyles";
import { PublicHeaderViewModel } from "./PublicHeaderViewModel";

export const PublicHeader: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new PublicHeaderViewModel());
    const classes = PublicHeaderStyles();

    // #region Code behind

    const getNavigationButtonClasses = (className: string, isOptionActive: boolean) => {
        return clsx({
            [classes.navigationOption]: true,
            [classes.navigationOptionActive]: isOptionActive,
            [className]: true,
        });
    };

    // #endregion Code behind

    return useObserver(() => (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.logo} onClick={viewModel.navigateToHome} />
                <Box className={classes.navigationOptions}>
                    <Box className={getNavigationButtonClasses(classes.navigationOptionHome, viewModel.IsHomeActive)} onClick={viewModel.navigateToHome}>
                        <Box>Home</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(classes.navigationOptionMyProfile, viewModel.IsMyProfileActive)} onClick={viewModel.navigateToMyProfile}>
                        <Box>My profile</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(classes.naviagationOptionFaqs, viewModel.IsFaqsActive)} onClick={viewModel.navigateToFaqs}>
                        <Box>FAQ</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(classes.navigationOptionMap, viewModel.IsDocActive)} onClick={viewModel.navigateToDocs}>
                        <Box>Docs</Box>
                    </Box>
                    <Box className={getNavigationButtonClasses(classes.navigationOptionMap, viewModel.IsMapActive)} onClick={viewModel.navigateToMap}>
                        <Box>Map</Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    ));
};
