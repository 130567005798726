import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid, useRouter } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { AdminUserStyles } from "Globals/Styles/Admin/Users/AdminUserStyles";
import { AdminFormWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { AdminUserViewModel } from "./AdminUserViewModel";

// Views
import { EditText } from "Views/Primitives/EditText";

export const AdminUserView: React.FC = () => {
    const { match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new AdminUserViewModel(id));

    const buttonStyles = ButtonStyles();
    const editTextStyles = EditTextStyles();
    const classes = AdminUserStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const formWorkspaceClasses = AdminFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Add/edit FAQ";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const getFormContainerClasses = () => {
        return clsx({
            [classes.formContainer]: true,
            [formWorkspaceClasses.formContainer]: true,
        });
    };

    const getFormClasses = () => {
        return clsx({
            [formWorkspaceClasses.form]: true,
        });
    };

    const getResetButtonClasses = () => {
        return clsx({
            [buttonStyles.containedSuccess]: true,
            [classes.resetButton]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.updateUser();
    };

    const renderUser = () => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Email address*"
                        execute={viewModel.setEmailAddress}
                        placeholder="Your email address"
                        validationMessage={viewModel.emailAddressValidationMessage}
                        value={viewModel.emailAddress}
                    />
                    <Button className={getResetButtonClasses()} disabled={!viewModel.canResetPassword} onClick={viewModel.resetPassword} variant="contained">
                        Reset password
                    </Button>
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Company name"
                        execute={viewModel.setCompanyName}
                        placeholder="The company you work for"
                        value={viewModel.companyName}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="First name*"
                        execute={viewModel.setFirstName}
                        placeholder="Your first name"
                        validationMessage={viewModel.confirmFirstNameValidationMessage}
                        value={viewModel.firstName}
                    />
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Last name*"
                        execute={viewModel.setLastName}
                        placeholder="Your last name"
                        validationMessage={viewModel.confirmLastNameValidationMessage}
                        value={viewModel.lastName}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Intended use*"
                        execute={viewModel.setIntendedUse}
                        placeholder="The use for the feeds"
                        validationMessage={viewModel.confirmIntendedUseValidationMessage}
                        value={viewModel.intendedUse}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRow} columnGap={30} rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Update
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
                {viewModel.server.IsRequestSuccessful && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="info" variant="filled">
                            Your user has been updated.
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={formWorkspaceClasses.titleContainer}>
                <Typography variant="h2">Edit user</Typography>
                <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateBackToUsers} variant="contained">
                    Back
                </Button>
            </Box>
            <Box className={getFormContainerClasses()}>
                <form className={getFormClasses()} onSubmit={onSubmit}>
                    {renderUser()}
                </form>
            </Box>
        </Box>
    ));
};
