import { Box } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useObserver } from "mobx-react-lite";
import * as styles from "./Map.styles";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

// Styles
import { PublicWorkspaceStyles } from "Globals/Styles/Public/PublicWorkspaceStyles";
import { MapViewModel } from "./MapViewModel";
import { FeedCategoryMapView } from "./FeedCategoryMapView";
import { DomainStores, DomainStoresInstance } from "../../../Globals/Stores";
import { getIcon, getMapIcon } from "./FeedMapStyles";
import { PinData } from "../../../Globals/Models/PinLocation";

export const MapView: React.FC = () => {
    const workspaceClasses = PublicWorkspaceStyles();
    const [viewModel] = useState(() => new MapViewModel());
    const mapRef = useRef({});

    useEffect(() => {
        document.title = "Map";
        /*const map = mapRef!.current.leafletElement;
        setTimeout(() => {
            map.invalidateSize();
        }, 250);*/
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    const getMarkers = () => {
        return DomainStoresInstance.listOfPins.map((pin: PinData, index: number) => {
            return (
                <Marker key={`marker-${index}`} position={[pin.Latitude, pin.Longitude]} icon={getMapIcon()}>
                    <Popup>{getPopup(pin.Data)}</Popup>
                </Marker>
            );
        });
    };

    const getPopup = (pinData: any) => {
        let retval: JSX.Element[] = [];
        for (let name in pinData) {
            if (name !== "Data" && name !== "coordinates") {
                //console.log(pinData[item]);
                retval.push(
                    <Box>
                        {name}: {pinData[name]}
                    </Box>,
                );
            }
        }
        return retval;
    };

    return useObserver(() => (
        <styles.Map>
            <MapContainer center={[53.505, -0.09]} zoom={6} scrollWheelZoom={true}>
                {/*<MapCluster />*/}
                <Circle
                    ref={viewModel.circleRef}
                    center={[viewModel.getValue("lat"), viewModel.getValue("lon")]}
                    radius={viewModel.getWithin}
                    fillColor={"red"}
                    fillOpacity={0.2}
                />
                <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MarkerClusterGroup
                    ref={viewModel.clusterRef}
                    zoomToBoundsOnClick
                    chunkedLoading
                    showCoverageOnHover={true}
                    spiderfyDistanceMultiplier={2}
                    iconCreateFunction={styles.createClusterCustomIcon}
                >
                    {getMarkers()}
                </MarkerClusterGroup>
            </MapContainer>
            <Box className={"feedsContainer"}>
                {viewModel.feedsViewModel.filteredFeedCategories.map((feedCategory) => (
                    <FeedCategoryMapView key={feedCategory.id} viewModel={feedCategory} />
                ))}
            </Box>
        </styles.Map>
    ));
};
