import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ApplicationsView } from "./ApplicationsView";

// Styles
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";
import { MyApplicationsStyles } from "Globals/Styles/Public/MyProfile/ApplicationStyles";

// ViewModels
import { MyApplicationsViewModel } from "./MyApplicationsViewModel";

// Views
import { EditText } from "../../../Primitives/EditText";
import { EditTextArea } from "../../../Primitives/EditTextArea";

export const MyApplicationsView: React.FC = () => {
    const [viewModel] = useState(() => new MyApplicationsViewModel());

    const buttonStyles = ButtonStyles();
    const editTextStyles = EditTextStyles();
    const myApplicationsStyles = MyApplicationsStyles();

    const haveApplications = (): boolean => {
        return viewModel.applicationsViewModel.applications.length > 0;
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.updateApplication();
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Box className={myApplicationsStyles.header}>
                <Box>
                    {viewModel.canDisplayAddApplication ? (
                        <Button className={buttonStyles.containedSuccess} onClick={viewModel.setCanDisplayAddApplication} variant="contained">
                            Back
                        </Button>
                    ) : (
                        <Button className={buttonStyles.containedSuccess} onClick={viewModel.setCanDisplayAddApplication} variant="contained">
                            Add application
                        </Button>
                    )}
                </Box>
            </Box>
        );
    };

    const renderApplication = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid className={myApplicationsStyles.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Application name*"
                        execute={viewModel.setName}
                        placeholder="Your application name"
                        validationMessage={viewModel.nameValidationMessage}
                        value={viewModel.name}
                    />
                </Grid>
                <Grid className={myApplicationsStyles.formRow} columnGap={30} rowGap={15}>
                    <EditTextArea
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Description*"
                        execute={viewModel.setDescription}
                        numberOfRows={6}
                        placeholder="Your description"
                        validationMessage={viewModel.descriptionValidationMessage}
                        value={viewModel.description}
                    />
                </Grid>
                <Grid className={myApplicationsStyles.formButtonRowWithTop} columnGap={30} rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Update
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={myApplicationsStyles.formRow} columnGap={30} rowGap={15}>
                        <Alert className={myApplicationsStyles.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
                {viewModel.server.IsRequestSuccessful && (
                    <Grid className={myApplicationsStyles.formRow} columnGap={30} rowGap={15}>
                        <Alert className={myApplicationsStyles.infoWithTop} severity="info" variant="filled">
                            Your application has been updated.
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    const renderForm = (): JSX.Element => {
        return viewModel.canDisplayAddApplication ? (
            <Box className={myApplicationsStyles.formContainer}>
                <form className={myApplicationsStyles.formv2} onSubmit={onSubmit}>
                    {renderApplication()}
                </form>
            </Box>
        ) : (
            <React.Fragment />
        );
    };

    const renderApplications = () => {
        return <ApplicationsView viewModel={viewModel.applicationsViewModel} />;
    };

    const renderEmptyApplicationsWarning = () => {
        return (
            <Box className={myApplicationsStyles.emptyWarning}>
                <Box>
                    <Typography component="div" variant="h4">
                        You have no applications
                    </Typography>
                </Box>
            </Box>
        );
    };

    return useObserver(() => (
        <Box>
            {renderHeader()}
            {renderForm()}
            {haveApplications() ? renderApplications() : renderEmptyApplicationsWarning()}
        </Box>
    ));
};
