import { makeStyles } from "@material-ui/core";

/**
 * Styles for use with the Public layout components.
 */
export const PublicLayoutStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
    scrollableContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
    },
}));
