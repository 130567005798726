import * as MobX from "mobx";
import { validate, validateOrReject, Contains, IsInt, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsNotEmpty, ValidateIf, Equals } from "class-validator";
import { ModelBase } from "@shoothill/core";

export class MapModel extends ModelBase<MapModel, MapModel> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public imageUrl: string = "";

    @MobX.observable
    public postCode: string = "";

    @MobX.observable
    public within: number = 500;

    @MobX.observable
    public lat: number = 0;

    @MobX.observable
    public lon: number = 0;

    @MobX.observable
    public minSpace: number = 0;

    @MobX.observable
    public maxSpace: number = 100000000;

    @MobX.observable
    public price: number = 0;

    @MobX.observable
    public hidden: boolean = false;

    @MobX.observable
    public deleted: boolean = false;

    public ref: any = null;

    //fromDto is required but you can leave it blank
    fromDto(model: MapModel): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: MapModel): void {}
}
