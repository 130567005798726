import { AccountStatus, FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Stores
import { StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminHomeModel } from "./AdminHomeModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminHomeViewModel extends ViewModelBase<AdminHomeModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new AdminHomeModel());

        this.setDecorators(AdminHomeModel);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region Password

    @action
    public setPassword = (value: string): void => {
        this.model.password = value;
    };

    @computed
    public get password(): string {
        return this.model.password;
    }

    @computed
    public get passwordValidationMessage(): string {
        const result = this.validateDecorators("password");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Password

    // #region Actions

    public logIn = (): Promise<void> => {
        return this.server.command<AccountStatus>(
            () => this.Post(AppUrls.Server.Admin.Account.Login, this.model.toDto()),
            (result) => {
                StoresInstance.Domain.AccountStore.getLoginState(result);
                this.navigateToUsers();
            },
            this.isModelValid,
            "There was an error trying to log in.",
        );
    };

    @action
    public navigateToForgotPassword = (): void => {
        this.history.push(AppUrls.Client.Admin.Account.ForgotPassword);
    };

    @action
    public navigateToUsers = (): void => {
        this.history.replace(AppUrls.Client.Admin.User.Users);
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<AdminHomeModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #endregion Boilerplate
}
