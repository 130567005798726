import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid, useRouter } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { AdminFeedStyles } from "Globals/Styles/Admin/Feeds/AdminFeedStyles";
import { AdminFormWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { CheckBoxStyles } from "Globals/Styles/Primitives/CheckBoxStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";
import { SelectStyles } from "Globals/Styles/Primitives/SelectStyles";

// ViewModels
import { AdminFeedViewModel } from "./AdminFeedViewModel";

// Views
import { CheckBox } from "Views/Primitives/CheckBoxes";
import { EditText } from "Views/Primitives/EditText";
import { EditTextArea } from "Views/Primitives/EditTextArea";
import { Select } from "Views/Primitives/Select";

export const AdminFeedView: React.FC = () => {
    const { match } = useRouter();
    const { id } = match.params as any;
    const [viewModel] = useState(() => new AdminFeedViewModel(id));

    const buttonStyles = ButtonStyles();
    const checkBoxStyles = CheckBoxStyles();
    const editTextStyles = EditTextStyles();
    const selectStyles = SelectStyles();
    const classes = AdminFeedStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const formWorkspaceClasses = AdminFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Add/edit feed";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const getFormContainerClasses = () => {
        return clsx({
            [classes.formContainer]: true,
            [formWorkspaceClasses.formContainer]: true,
        });
    };

    const getFormClasses = () => {
        return clsx({
            [formWorkspaceClasses.form]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.updateFeed();
    };

    const renderFeed = () => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Title*"
                        execute={viewModel.setName}
                        placeholder="Your title"
                        validationMessage={viewModel.confirmNameValidationMessage}
                        value={viewModel.name}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <EditTextArea
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Description*"
                        execute={viewModel.setDescription}
                        numberOfRows={6}
                        placeholder="Your description"
                        validationMessage={viewModel.confirmDescriptionValidationMessage}
                        value={viewModel.description}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <Select
                        canExecute={!viewModel.server.IsBusy}
                        className={selectStyles.publicDark}
                        displayName="Category*"
                        execute={viewModel.setFeedCategory}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.feedCategories}
                        placeholder="Select a category"
                        validationMessage={viewModel.confirmFeedCategoryValidationMessage}
                        value={viewModel.feedCategory}
                    />
                    <Select
                        canExecute={!viewModel.server.IsBusy}
                        className={selectStyles.publicDark}
                        displayName="Subcategory*"
                        execute={viewModel.setFeedSubCategory}
                        getOptionLabel={(option: any) => option.displayName}
                        options={viewModel.feedSubCategories}
                        placeholder="Select a subcategory"
                        validationMessage={viewModel.confirmFeedSubCategoryValidationMessage}
                        value={viewModel.feedSubCategory}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Api Url*"
                        execute={viewModel.setApiUrl}
                        placeholder="Your api url"
                        validationMessage={viewModel.confirmApiUrlValidationMessage}
                        value={viewModel.apiUrl}
                    />
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Schema Url*"
                        execute={viewModel.setSchemaUrl}
                        placeholder="Your schema url"
                        validationMessage={viewModel.confirmSchemaUrlValidationMessage}
                        value={viewModel.schemaUrl}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                    <CheckBox
                        canExecute={!viewModel.server.IsBusy}
                        className={checkBoxStyles.publicDark}
                        displayName="Publish now"
                        execute={viewModel.setIsActive}
                        value={viewModel.isActive}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRow} columnGap={30} rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Update
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
                {viewModel.server.IsRequestSuccessful && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="info" variant="filled">
                            Your feed has been updated.
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={formWorkspaceClasses.titleContainer}>
                <Typography variant="h2">{viewModel.isExistingFeed ? "Edit feed" : "Add new feed"}</Typography>
                <Button className={buttonStyles.containedSuccess} onClick={viewModel.navigateBackToFeeds} size="small" variant="contained">
                    Back
                </Button>
            </Box>
            <Box className={getFormContainerClasses()}>
                <form className={getFormClasses()} onSubmit={onSubmit}>
                    {renderFeed()}
                </form>
            </Box>
        </Box>
    ));
};
