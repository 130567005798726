import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, IComputedValue, observable } from "mobx";

// Models
import { FeedSubCategory } from "./FeedSubCategoryModel";

// ViewModels
import { FeedViewModel } from "./FeedViewModel";

export class FeedSubCategoryViewModel extends ViewModelBase<FeedSubCategory> {
    // #region Private Members

    private activeKeyword: IComputedValue<string>;

    private removeFeedOnUnsubscribe: boolean;

    @observable
    private displayFeeds = false;

    @computed
    private get feeds(): FeedViewModel[] {
        const viewModel: FeedViewModel[] = [];

        for (const feed of this.model.feeds) {
            viewModel.push(new FeedViewModel(feed, this.activeKeyword));
        }

        return viewModel;
    }

    @computed
    private get haveFilteredItems(): boolean {
        return this.filteredFeeds.length > 0;
    }

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(feedSubCategory: FeedSubCategory, activeKeyword: IComputedValue<string>, removeFeedOnUnsubscribe = false) {
        super(feedSubCategory);

        this.activeKeyword = activeKeyword;
        this.removeFeedOnUnsubscribe = removeFeedOnUnsubscribe;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get displayName(): string {
        return this.model.displayName;
    }

    @computed
    public get filteredFeeds(): FeedViewModel[] {
        let filteredFeeds = this.feeds.filter((f) => f.containsSearchKeyword);

        if (this.removeFeedOnUnsubscribe) {
            filteredFeeds = filteredFeeds.filter((f) => f.isSubscribed);
        }

        return filteredFeeds;
    }

    @computed
    public get id(): string {
        return this.model.id;
    }

    @computed
    public get numberOfFilteredFeeds() {
        return this.filteredFeeds.length;
    }

    // #endregion Properties

    // #region Computeds

    @computed
    public get canDisplayFeeds(): boolean {
        return this.haveFilteredItems && this.displayFeeds;
    }

    @computed
    public get canSelectItem(): boolean {
        return this.haveFilteredItems;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setDisplayFeeds = () => {
        if (this.haveFilteredItems) {
            this.displayFeeds = !this.displayFeeds;
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
