import { Box, Typography } from "@material-ui/core";
import React from "react";

import { AdminHomeHeaderStyles } from "../../../../Globals/Styles/Admin/AdminHeaderStyles";

export const AdminHomeHeader: React.FC<{}> = (props) => {
    const classes = AdminHomeHeaderStyles();

    // #region Code behind
    // #endregion Code behind

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <Box className={classes.logo} />
            </Box>
        </Box>
    );
};
