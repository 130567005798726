import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { FeedStyles } from "Globals/Styles/Public/Home/FeedStyles";

// ViewModels
import { FeedViewModel } from "./FeedViewModel";

interface IProps {
    viewModel: FeedViewModel;
}

interface IUnsubscribeConfirmationDialogState {
    isOpen: boolean;
}

export const FeedView: React.FC<IProps> = (props) => {
    const [openUnsubscribeConfirmationState, setOpenUnsubscribeConfirmation] = useState<IUnsubscribeConfirmationDialogState>({ isOpen: false });

    const buttonStyles = ButtonStyles();
    const feedStyles = FeedStyles();

    const onClickSubscribeUnsubscribeButton = () => {
        props.viewModel.isSubscribed ? onOpenUnsubscribeConfirmation() : props.viewModel.subscribe();
    };

    const onOpenUnsubscribeConfirmation = () => {
        setOpenUnsubscribeConfirmation({ isOpen: true });
    };

    const onCloseConfirmUnsubscribe = () => {
        props.viewModel.subscribe();
        setOpenUnsubscribeConfirmation({ isOpen: false });
    };

    const onCloseCancelUnsubscribe = () => {
        setOpenUnsubscribeConfirmation({ isOpen: false });
    };

    const renderUnsubscribeConfirmationDialog = () => {
        return (
            <Dialog onClose={onCloseCancelUnsubscribe} open={openUnsubscribeConfirmationState.isOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography color="textPrimary">Are you sure you want to unsubscribe from this feed?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={buttonStyles.containedSuccess} color="primary" onClick={onCloseConfirmUnsubscribe}>
                        Yes
                    </Button>
                    <Button autoFocus className={buttonStyles.containedCancel} color="primary" onClick={onCloseCancelUnsubscribe}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return useObserver(() => (
        <Box className={feedStyles.root}>
            {openUnsubscribeConfirmationState.isOpen && renderUnsubscribeConfirmationDialog()}
            <Box>
                <Box>
                    <Typography className={feedStyles.innerRow} component="div" variant="h4">
                        {props.viewModel.name}
                    </Typography>
                    <Typography className={feedStyles.innerRow} component="div" variant="body2">
                        {props.viewModel.description}
                    </Typography>
                    {props.viewModel.canDisplayUserFeatures && (
                        <Box className={feedStyles.featureRow}>
                            <Button
                                className={buttonStyles.containedSuccess}
                                disabled={props.viewModel.server.IsBusy}
                                onClick={onClickSubscribeUnsubscribeButton}
                                variant="contained"
                            >
                                {props.viewModel.isSubscribed ? "Unsubscribe" : "Subscribe"}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    ));
};
