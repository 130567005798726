import { AccountStatus, FieldType, isEmptyOrWhitespace, ValidationResponse, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { RegisterModel } from "./RegisterModel";

// Urls
import { AppUrls } from "AppUrls";

export class RegisterViewModel extends ViewModelBase<RegisterModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new RegisterModel());

        this.setDecorators(RegisterModel);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region Password

    @action
    public setPassword = (value: string): void => {
        this.model.password = value;
    };

    @computed
    public get password(): string {
        return this.model.password;
    }

    @computed
    public get passwordValidationMessage(): string {
        const result = this.validateDecorators("password");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Password

    // #region Confirm Password

    @action
    public setConfirmPassword = (value: string): void => {
        this.model.confirmPassword = value;
    };

    @computed
    public get confirmPassword(): string {
        return this.model.confirmPassword;
    }

    @computed
    public get confirmPasswordValidationMessage(): string {
        const result = this.validateConfirmPassword;

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Confirm Password

    // #region First Name

    @action
    public setFirstName = (value: string): void => {
        this.model.firstName = value;
    };

    @computed
    public get firstName(): string {
        return this.model.firstName;
    }

    @computed
    public get confirmFirstNameValidationMessage(): string {
        const result = this.validateDecorators("firstName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion First Name

    // #region Last Name

    @action
    public setLastName = (value: string): void => {
        this.model.lastName = value;
    };

    @computed
    public get lastName(): string {
        return this.model.lastName;
    }

    @computed
    public get confirmLastNameValidationMessage(): string {
        const result = this.validateDecorators("lastName");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Last Name

    // #region Company Name

    @action
    public setCompanyName = (value: string): void => {
        this.model.companyName = value;
    };

    @computed
    public get companyName(): string {
        return this.model.companyName;
    }

    // #endregion Company Name

    // #region Intended Use

    @action
    public setIntendedUse = (value: string): void => {
        this.model.intendedUse = value;
    };

    @computed
    public get intendedUse(): string {
        return this.model.intendedUse;
    }

    @computed
    public get confirmIntendedUseValidationMessage(): string {
        const result = this.validateDecorators("intendedUse");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Intended Use

    // #region Agree Terms and Conditions

    @action
    public setAgreeTermsAndConditions = (): void => {
        this.model.agreeTermsAndConditions = !this.model.agreeTermsAndConditions;
    };

    @computed
    public get agreeTermsAndConditions(): boolean {
        return this.model.agreeTermsAndConditions;
    }

    @computed
    public get confirmAgreeTermsAndConditionsValidationMessage(): string {
        const result = this.validateDecorators("agreeTermsAndConditions");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Agree Terms and Conditions

    // #region Actions

    public register = (): Promise<void> => {
        return this.server.command<AccountStatus>(
            () => this.Post(AppUrls.Server.Account.Register, this.model.toDto()),
            (result) => {
                StoresInstance.Domain.AccountStore.getLoginState(result);
            },
            this.isModelValid,
        );
    };

    @action
    public navigateToMyProfile = (): void => {
        this.history.push(AppUrls.Client.MyProfile);
    };

    @action
    public navigateToHome = (): void => {
        this.history.push(AppUrls.Client.Home);
    };

    // #endregion Actions

    // #region Validation

    @computed
    private get validateConfirmPassword(): ValidationResponse {
        const errorMessage = this.model.validateConfirmPassword;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion Validation

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<RegisterModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        // Process the properties of the model that cannot be supported via
        // the use of decorators.
        switch (fieldName) {
            case "confirmPassword": {
                const result = this.validateConfirmPassword;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
