import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { FeedMapStyles } from "./FeedMapStyles";
import { FeedViewModel } from "../SubViews/Feeds/FeedViewModel";
import { CheckBox } from "../../Primitives/CheckBoxes";

interface IProps {
    viewModel: FeedViewModel;
}

interface IUnsubscribeConfirmationDialogState {
    isOpen: boolean;
}

export const FeedMapView: React.FC<IProps> = (props) => {
    const [openUnsubscribeConfirmationState, setOpenUnsubscribeConfirmation] = useState<IUnsubscribeConfirmationDialogState>({ isOpen: false });

    const buttonStyles = ButtonStyles();
    const feedStyles = FeedMapStyles();

    return useObserver(() => (
        <Box className={feedStyles.root}>
            <Box>
                <Box display={"flex"}>
                    {/*{props.viewModel.IsLoading ? (
                        <CircularProgress size={20} disableShrink={true} />
                    ) : (
                        <CheckBox displayName={""} execute={props.viewModel.setDisplayOnMap} value={props.viewModel.canDisplayOnMap} />
                    )}*/}
                    <CheckBox displayName={""} execute={props.viewModel.setDisplayOnMap} value={props.viewModel.canDisplayOnMap} canExecute={!props.viewModel.IsLoading} />
                    <Typography className={feedStyles.innerRow} component="div" variant="h5">
                        {props.viewModel.name}
                    </Typography>
                    {/* <Typography className={feedStyles.innerRow} component="div" variant="body2">
                        {props.viewModel.description}
                    </Typography>*/}
                </Box>
            </Box>
        </Box>
    ));
};
