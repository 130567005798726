import { Box, Button, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useObserver } from "mobx-react-lite";
import React from "react";

// Styles
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";

// Views
import { FeedCategoryViewModel } from "../SubViews/Feeds/FeedCategoryViewModel";
import { FeedSubCategoryMapView } from "./FeedSubCategoryMapView";
import { FeedCategoryStyles } from "./FeedMapStyles";

interface IProps {
    viewModel: FeedCategoryViewModel;
}

export const FeedCategoryMapView: React.FC<IProps> = (props) => {
    const buttonStyles = ButtonStyles();
    const feedCategoryStyles = FeedCategoryStyles(props);

    const renderHeader = (): JSX.Element => {
        return (
            <Box className={feedCategoryStyles.innerRow} onClick={props.viewModel.setDisplaySubCategories}>
                <Box>
                    <Typography className={feedCategoryStyles.iconText} component="div" variant="h2">
                        {props.viewModel.displayName}
                    </Typography>
                    <Box className={feedCategoryStyles.countArrow}>
                        <Typography component="div" variant="body1">
                            {props.viewModel.numberOfFilteredFeeds} feeds
                        </Typography>
                        <ArrowForwardIosIcon />
                    </Box>
                </Box>
            </Box>
        );
    };

    const renderHeaderWithSubCategories = (): JSX.Element => {
        return (
            <Box className={feedCategoryStyles.innerRowWithSubCategories}>
                <Box>
                    <Box className={feedCategoryStyles.buttonText}>
                        <Button className={buttonStyles.containedSuccess} onClick={props.viewModel.setDisplaySubCategories}>
                            Back
                        </Button>
                        <Typography component="div" variant="h1">
                            {props.viewModel.displayName}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    };

    return useObserver(() => (
        <Box className={feedCategoryStyles.root}>
            {props.viewModel.canDisplaySubCategories ? renderHeaderWithSubCategories() : renderHeader()}
            {props.viewModel.canDisplaySubCategories && (
                <Box>
                    {props.viewModel.filteredFeedSubCategories.map((feedSubCategory) => (
                        <FeedSubCategoryMapView key={feedSubCategory.id} viewModel={feedSubCategory} />
                    ))}
                </Box>
            )}
        </Box>
    ));
};
