import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { IsNotEmpty, MinLength } from "class-validator";
import { action, computed, observable } from "mobx";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel, ResetPasswordModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_NEWPASSWORD = "";
    public static readonly DEFAULT_CONFIRMPASSWORD = "";
    public static readonly DEFAULT_TOKEN = "";

    // #endregion Defaults

    // #region Observables

    @observable
    @IsNotEmpty({ message: "Password is required" })
    @MinLength(8, { message: "Password must be at least 8 characters" })
    public newPassword: string = ResetPasswordModel.DEFAULT_NEWPASSWORD;

    @observable
    // Custom validation below
    public confirmPassword: string = ResetPasswordModel.DEFAULT_CONFIRMPASSWORD;

    @observable
    public token: string = ResetPasswordModel.DEFAULT_TOKEN;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.newPassword = ResetPasswordModel.DEFAULT_NEWPASSWORD;
        this.confirmPassword = ResetPasswordModel.DEFAULT_CONFIRMPASSWORD;
        this.token = ResetPasswordModel.DEFAULT_TOKEN;
    }

    @action
    public fromDto(model: ResetPasswordModelDTO): void {
        this.newPassword = model.newPassword;
        this.token = model.token;
    }

    public toDto(): ResetPasswordModelDTO {
        return {
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
            token: this.token,
        };
    }

    // #endregion Actions

    // #region Validation

    @computed
    public get validateConfirmPassword(): string {
        return this.newPassword !== this.confirmPassword && !isEmptyOrWhitespace(this.newPassword) ? "This must match the password" : "";
    }

    // #endregion Validation
}

export interface ResetPasswordModelDTO {
    newPassword: string;
    confirmPassword: string;
    token: string;
}
