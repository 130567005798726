import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed, IComputedValue, runInAction } from "mobx";

// Globals
import { DomainStoresInstance, StoresInstance } from "Globals/Stores";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { FeedModel, SubscribeFeedModelResponseDto } from "./FeedModel";

// Urls
import { AppUrls } from "AppUrls";

export class FeedViewModel extends ViewModelBase<FeedModel> {
    // #region Private Members

    private activeKeyword: IComputedValue<string>;

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(feed: FeedModel, activeKeyword: IComputedValue<string>) {
        super(feed);

        this.activeKeyword = activeKeyword;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get description(): string {
        return this.model.description;
    }

    @computed
    public get id(): string {
        return this.model.id;
    }

    @computed
    public get isSubscribed(): boolean {
        return this.model.isSubscribed;
    }

    @computed
    public get name(): string {
        return this.model.name;
    }

    @computed
    public get canDisplayOnMap(): boolean {
        return this.model.displayOnMap;
    }

    @action
    public setDisplayOnMap = async (): Promise<void> => {
        this.model.displayOnMap = !this.model.displayOnMap;
        if (this.model.displayOnMap) {
            //Get feed
            await this.getDataFromFeed();
        } else {
            const filteredList = DomainStoresInstance.listOfPins.filter((a) => a.feedId !== this.model.id);
            DomainStoresInstance.listOfPins.replace(filteredList);
        }
    };

    private updateCurrentPinData = (result: any) => {
        try {
            result.carParks.forEach((item: any) => {
                runInAction(() => {
                    DomainStoresInstance.listOfPins.push({
                        feedId: this.model.id,
                        Latitude: item.coordinates.latitude,
                        Longitude: item.coordinates.longitude,
                        Data: item,
                        Type: "PARKING",
                    });
                    //console.log("pushed", item.coordinates);
                });
            });
        } catch (e) {
            debugger;
        }
    };

    // #region Properties

    // #region Computeds

    @computed
    public get canDisplayUserFeatures(): boolean {
        return StoresInstance.Domain.AccountStore.IsLoggedIn;
    }

    @computed
    public get containsSearchKeyword(): boolean {
        return !isEmptyOrWhitespace(this.activeKeyword.get())
            ? this.name.toLocaleLowerCase().includes(this.activeKeyword.get().toLocaleLowerCase()) ||
                  this.description.toLocaleLowerCase().includes(this.activeKeyword.get().toLocaleLowerCase())
            : true;
    }

    // #endregion Computeds

    // #region Actions

    public subscribe = (): Promise<void> => {
        return this.server.command<SubscribeFeedModelResponseDto>(
            () =>
                this.Post(AppUrls.Server.Public.Feed.Subscribe, {
                    id: this.model.id,
                    isSubscribed: !this.model.isSubscribed,
                }),
            (result) => this.model.fromSubscribeDto(result),
            () => true,
        );
    };

    public getDataFromFeed = (): Promise<void> => {
        return this.server.command<SubscribeFeedModelResponseDto>(
            () => this.Get(AppUrls.Server.Public.Feed.Pins + "?feedUrl=" + this.model.apiUrl),
            (result) => this.updateCurrentPinData(result),
            () => true,
        );
    };
    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
