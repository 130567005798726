import React from "react";
import { Switch } from "react-router-dom";

import { SamplesView } from "./Views/Samples/SamplesView";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
import { Error } from "Globals/Views/Error";
import { HomeSamplesView } from "./Views/HomeSamples/HomeSamplesView";
import { RouteWithLayout as Route } from "./Globals/Views/RouteWithLayout";
import { DefaultLayoutNoFooter } from "Globals/Views/Layouts/DefaultLayoutNoFooter";
import { CommonInjects } from "./Globals/CommonInjects";
import { LogViewer, TestPage } from "@shoothill/core";

import { AppUrls } from "./AppUrls";

import { AdminFormLayout, AdminLayout, AdminTableLayout } from "Views/Admin/AdminLayout";
import { PublicLayout } from "./Views/Public/PublicLayout";

import { AdminHomeView } from "Views/Admin/Account/Home/AdminHomeView";
import { AdminForgotPasswordView } from "Views/Admin/Account/ForgotPassword/ForgotPasswordView";
import { AdminResetPasswordView } from "Views/Admin/Account/ResetPassword/ResetPasswordView";

import { AdminFaqView } from "Views/Admin/Faqs/Form/AdminFaqView";
import { AdminFaqsView } from "Views/Admin/Faqs/Table/AdminFaqsView";
import { AdminFeedView } from "Views/Admin/Feeds/Form/AdminFeedView";
import { AdminFeedsView } from "Views/Admin/Feeds/Table/AdminFeedsView";
import { AdminUserView } from "Views/Admin/Users/Form/AdminUserView";
import { AdminUsersView } from "Views/Admin/Users/Table/AdminUsersView";

import { ForgotPasswordView } from "Views/Public/Account/ForgotPassword/ForgotPasswordView";
import { RegisterView } from "Views/Public/Account/Register/RegisterView";
import { ResetPasswordView } from "Views/Public/Account/ResetPassword/ResetPasswordView";

import { FaqsView } from "Views/Public/Faqs/FaqsView";
import { HomeView } from "Views/Public/Home/HomeView";
import { MapView } from "Views/Public/Map/MapView";
import { MyProfileView } from "Views/Public/MyProfile/MyProfileView";
import { PrivacyPolicyView } from "Views/Public/Legal/PrivacyPolicyView";
import { TermsAndConditionsView } from "Views/Public/Legal/TermsAndConditionsView";
import { PrivateRoute } from "Globals/Views";
import { StoresInstance } from "Globals/Stores";
import { Doc } from "./Views/Public/Swagger/Doc";

export const AppRoutes: React.FC = () => {
    return (
        <Switch>
            {/* Public facing routes */}
            <Route component={ForgotPasswordView} exact layout={PublicLayout} path={AppUrls.Client.Account.ForgotPassword} />
            <Route component={RegisterView} exact layout={PublicLayout} path={AppUrls.Client.Account.Register} />
            <Route component={ResetPasswordView} exact layout={PublicLayout} path={AppUrls.Client.Account.ResetPassword} />

            <Route component={FaqsView} exact layout={PublicLayout} path={AppUrls.Client.Faqs} />
            <Route component={PrivacyPolicyView} exact layout={PublicLayout} path={AppUrls.Client.PrivacyPolicy} />
            <Route component={TermsAndConditionsView} exact layout={PublicLayout} path={AppUrls.Client.TermsAndConditions} />
            <Route component={Doc} exact layout={PublicLayout} path={AppUrls.Client.Swagger} />

            {/* Protected/Unprotected Public facing routes */}
            <Route component={HomeView} exact layout={PublicLayout} path={AppUrls.Client.Home} />
            <Route component={MapView} exact layout={PublicLayout} path={AppUrls.Client.Map} />
            <Route component={MyProfileView} exact layout={PublicLayout} path={AppUrls.Client.MyProfile} />

            {/* Admin facing routes */}
            <Route component={AdminHomeView} exact layout={AdminLayout} path={AppUrls.Client.Admin.Account.Home} />
            <Route component={AdminForgotPasswordView} exact layout={AdminLayout} path={AppUrls.Client.Admin.Account.ForgotPassword} />
            <Route component={AdminResetPasswordView} exact layout={AdminLayout} path={AppUrls.Client.Admin.Account.ResetPassword} />

            {/* Protected Admin facing routes */}
            <PrivateRoute
                component={AdminFaqsView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminTableLayout}
                path={AppUrls.Client.Admin.Faq.Faqs}
            />
            <PrivateRoute
                component={AdminFeedsView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminTableLayout}
                path={AppUrls.Client.Admin.Feed.Feeds}
            />
            <PrivateRoute
                component={AdminUsersView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminTableLayout}
                path={AppUrls.Client.Admin.User.Users}
            />

            <PrivateRoute
                component={AdminFaqView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminFormLayout}
                path={`${AppUrls.Client.Admin.Faq.Faq}/:id?`}
            />
            <PrivateRoute
                component={AdminFeedView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminFormLayout}
                path={`${AppUrls.Client.Admin.Feed.Feed}/:id?`}
            />
            <PrivateRoute
                component={AdminUserView}
                exact
                isAllowed={() => StoresInstance.Domain.AccountStore.IsLoggedIn && StoresInstance.Domain.AccountStore.IsAdministrator}
                layout={AdminFormLayout}
                path={`${AppUrls.Client.Admin.User.User}/:id?`}
            />

            {/* Sample stuff to borrow from - to be deleted towards the end of the project */}
            <Route component={HomeSamplesView} exact path={AppUrls.Client.HomeSamples} />
            <Route component={SamplesView} exact layout={DefaultLayoutNoFooter} path={AppUrls.Client.Samples} />
            <Route component={SessionExpiredView} exact path={AppUrls.Client.Account.SessionExpired} />
            <Route component={LogViewer} exact path={AppUrls.Client.Admin.LogViewer} />
            <Route component={Error} exact path={AppUrls.Client.Error} />
            <Route component={TestPage} exact path={AppUrls.Client.Test} />

            <CommonInjects />
        </Switch>
    );
};
