import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

// Models
import { FeedCategory, FeedCategoryResponseModelDTO } from "./FeedCategoryModel";
import { FeedModel, FeedResponseModelDTO } from "./FeedModel";
import { FeedSubCategory, FeedSubCategoryResponseModelDTO } from "./FeedSubCategoryModel";

export class FeedsModel extends ModelBase<FeedsModel, FeedsResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_FEEDCATEGORIES = [];

    // #endregion Defaults

    // #region Observables

    @observable
    public feedCategories = observable<FeedCategory>(FeedsModel.DEFAULT_FEEDCATEGORIES);

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.feedCategories.replace(FeedsModel.DEFAULT_FEEDCATEGORIES);
    }

    @action
    public fromDto(dto: FeedsResponseModelDTO): void {
        const feedCategoryModels: FeedCategory[] = [];

        for (const fcDto of dto.feedCategories) {
            // Find subcategories belonging to this category.
            const subCategories = dto.feedSubCategories.filter((fsc) => fsc.feedCategoryId === fcDto.id);
            const feedSubCategoryModels: FeedSubCategory[] = [];

            for (const subCategory of subCategories) {
                // Find feeds belonging to this subcategory.
                const feeds = dto.feeds.filter((f) => f.feedSubCategoryId === subCategory.id);
                const feedModels: FeedModel[] = [];

                for (const feed of feeds) {
                    feedModels.push(this.createFeed(feed, dto.feedSubscriptions));
                }

                feedSubCategoryModels.push(this.createSubcategory(subCategory, feedModels));
            }

            feedCategoryModels.push(this.createCategory(fcDto, feedSubCategoryModels));
        }

        this.feedCategories.replace(feedCategoryModels);
    }

    public toDto() {
        throw new Error("Method not implemented.");
    }

    private createCategory = (feedCategoryDto: FeedCategoryResponseModelDTO, feedSubCategories: FeedSubCategory[]): FeedCategory => {
        const feedCategoryModel = new FeedCategory();

        feedCategoryModel.fromDto(feedCategoryDto);
        feedCategoryModel.setSubCategories(feedSubCategories);

        return feedCategoryModel;
    };

    private createFeed = (feedDto: FeedResponseModelDTO, feedSubscriptionDtos: FeedSubscriptionResponseModelDTO[]): FeedModel => {
        const isSubcribed = feedSubscriptionDtos ? feedSubscriptionDtos.some((s) => s.feedId === feedDto.id) : false;

        const feedModel = new FeedModel();

        feedModel.fromDto(feedDto);
        feedModel.setSubscribed(isSubcribed);

        return feedModel;
    };

    private createSubcategory = (subCategoryDto: FeedSubCategoryResponseModelDTO, feedModels: FeedModel[]): FeedSubCategory => {
        const feedSubCategoryModel = new FeedSubCategory();

        feedSubCategoryModel.fromDto(subCategoryDto);
        feedSubCategoryModel.setFeeds(feedModels);

        return feedSubCategoryModel;
    };

    // #endregion Actions
}

export interface FeedsResponseModelDTO {
    feeds: FeedResponseModelDTO[];
    feedCategories: FeedCategoryResponseModelDTO[];
    feedSubCategories: FeedSubCategoryResponseModelDTO[];
    feedSubscriptions: FeedSubscriptionResponseModelDTO[];
}

export interface UserFeedsResponseModelDTO {
    feeds: FeedResponseModelDTO[];
    feedCategories: FeedCategoryResponseModelDTO[];
    feedSubCategories: FeedSubCategoryResponseModelDTO[];
    feedSubscriptions: FeedSubscriptionResponseModelDTO[];
}

export interface FeedSubscriptionResponseModelDTO {
    feedId: string;
}
