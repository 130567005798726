import { makeStyles } from "@material-ui/core";
import { pxToRem } from "Globals/Styles/AppTheme";

const gutterSpacing = 30;

export const HomeStyles = makeStyles((theme) => ({
    root: {},
    introductionContainer: {
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            margin: `${pxToRem(60)} 0`,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
        },
    },
    introductionLogin: {},
    introductionLogout: {},
    introduction: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
    },
    login: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
        "& > form": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        },
    },
}));
