import { hot, setConfig } from "react-hot-loader";

import { AppRoutes } from "./AppRoutes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import React from "react";
import { Router } from "react-router-dom";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { theme } from "Globals/Styles/AppTheme";
import { GlobalHistory } from "index";

setConfig({
    showReactDomPatchNotification: false,
});

const App: React.FC = () => {
    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <CssBaseline>
                        <Router history={GlobalHistory}>
                            <AppRoutes />
                        </Router>
                    </CssBaseline>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

export default hot(module)(App);
