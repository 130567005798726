import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class AdminFaqModel extends ModelBase<AdminFaqModel> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_ANSWER = "";
    public static readonly DEFAULT_QUESTION = "";
    public static readonly DEFAULT_ISACTIVE = false;
    public static readonly DEFAULT_CREATEDDATETIMEUTC = "";
    public static readonly DEFAULT_CREATEDBYFIRSTNAME = "";
    public static readonly DEFAULT_CREATEDBYLASTNAME = "";
    public static readonly DEFAULT_MODIFIEDDATETIMEUTC = undefined;
    public static readonly DEFAULT_MODIFIEDBYFIRSTNAME = undefined;
    public static readonly DEFAULT_MODIFIEDBYLASTNAME = undefined;

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = AdminFaqModel.DEFAULT_ID;

    @observable
    public answer: string = AdminFaqModel.DEFAULT_ANSWER;

    @observable
    public question: string = AdminFaqModel.DEFAULT_QUESTION;

    @observable
    public isActive: boolean = AdminFaqModel.DEFAULT_ISACTIVE;

    @observable
    public createdDateTimeUTC: string = AdminFaqModel.DEFAULT_CREATEDDATETIMEUTC;

    @observable
    public createdByFirstName: string = AdminFaqModel.DEFAULT_CREATEDBYFIRSTNAME;

    @observable
    public createdByLastName: string = AdminFaqModel.DEFAULT_CREATEDBYLASTNAME;

    @observable
    public modifiedDateTimeUTC?: string = AdminFaqModel.DEFAULT_MODIFIEDDATETIMEUTC;

    @observable
    public modifiedByFirstName?: string = AdminFaqModel.DEFAULT_MODIFIEDBYFIRSTNAME;

    @observable
    public modifiedByLastName?: string = AdminFaqModel.DEFAULT_MODIFIEDBYLASTNAME;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = AdminFaqModel.DEFAULT_ID;
        this.answer = AdminFaqModel.DEFAULT_ANSWER;
        this.question = AdminFaqModel.DEFAULT_QUESTION;
        this.isActive = AdminFaqModel.DEFAULT_ISACTIVE;
        this.createdDateTimeUTC = AdminFaqModel.DEFAULT_CREATEDDATETIMEUTC;
        this.createdByFirstName = AdminFaqModel.DEFAULT_CREATEDBYFIRSTNAME;
        this.createdByLastName = AdminFaqModel.DEFAULT_CREATEDBYLASTNAME;
        this.modifiedDateTimeUTC = AdminFaqModel.DEFAULT_MODIFIEDDATETIMEUTC;
        this.modifiedByFirstName = AdminFaqModel.DEFAULT_MODIFIEDBYFIRSTNAME;
        this.modifiedByLastName = AdminFaqModel.DEFAULT_MODIFIEDBYLASTNAME;
    }

    @action
    public fromPublishDto(dto: AdminPublishFaqResponseModelDto): void {
        this.isActive = dto.isActive;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
        this.modifiedByFirstName = dto.modifiedByFirstName;
        this.modifiedByLastName = dto.modifiedByLastName;
    }

    @action
    public fromDto(dto: AdminGetFaqResponseModelDTO): void {
        this.id = dto.id;
        this.answer = dto.answer;
        this.question = dto.question;
        this.isActive = dto.isActive;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.createdByFirstName = dto.createdByFirstName;
        this.createdByLastName = dto.createdByLastName;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
        this.modifiedByFirstName = dto.modifiedByFirstName;
        this.modifiedByLastName = dto.modifiedByLastName;
    }

    public toPublishDto(): AdminPublishFaqModelRequestDto {
        return {
            id: this.id,
            isActive: this.isActive,
        };
    }

    public toDto() {
        throw new Error("Not implemented");
    }

    // #endregion Actions
}

export interface AdminGetFaqResponseModelDTO {
    id: string;
    answer: string;
    question: string;
    isActive: boolean;
    createdDateTimeUTC: string;
    createdByFirstName: string;
    createdByLastName: string;
    modifiedDateTimeUTC?: string;
    modifiedByFirstName?: string;
    modifiedByLastName?: string;
}

export interface AdminPublishFaqModelRequestDto {
    id: string;
    isActive: boolean;
}

export interface AdminPublishFaqResponseModelDto {
    id: string;
    isActive: boolean;
    modifiedDateTimeUTC?: string;
    modifiedByFirstName?: string;
    modifiedByLastName?: string;
}
