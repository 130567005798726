import * as Models from "../Models";
import { action, observable } from "mobx";
import * as MobX from "mobx";
import { AccountStore } from "./Domain";
import { EditableCopy } from "Globals/Models";
import { EditableCopyRoute } from "Globals/Models";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "./Stores";
import { UserStore } from "./Domain/Admin";
import { PinData } from "../Models/PinLocation";

export class DomainStores {
    @observable public openSnackBar = false;
    @observable public snackBarSeverity: "success" | "error" | "warning" | "info" = "success";
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    @observable public editableCopies = MobX.observable<EditableCopy>([]);
    @observable public editableCopyRoutes = MobX.observable<EditableCopyRoute>([]);

    @observable public listOfPins = MobX.observable<PinData>([]);

    public AccountStore = new AccountStore();
    public UserStore = new UserStore();
    public RoleStore = new RoleStore();

    constructor() {
        DomainStoresInstance = this;
    }

    public init(stores: Stores, initialState: Models.InitialState) {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        initialState.editableCopies.forEach((editableCopy) => {
            this.editableCopies.push(editableCopy);
        });
        initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
            this.editableCopyRoutes.push(editableCopyRoute);
        });
        this.AccountStore.init(stores, initialState);
    }

    @action
    public OpenSnackBar(content: string, severity: "success" | "error" | "warning" | "info") {
        console.log("open snack");
        this.snackBarContent = content;
        this.snackBarSeverity = severity;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
export let DomainStoresInstance = {} as DomainStores;
