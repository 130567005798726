import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Urls
import { AppUrls } from "../../../../AppUrls";

export class PublicHeaderViewModel extends ViewModelBase<any> {
    constructor() {
        super({});

        this.history.listen((location) => {
            this.setActiveOption(location.pathname);
        });

        this.setActiveOption(location.pathname);
    }

    // #region Properties

    @observable
    public activeOption = "";

    // #endregion Properties

    // #region Actions

    @action
    public navigateToHome = () => {
        this.history.push(AppUrls.Client.Home);
    };

    public navigateToMyProfile = () => {
        this.history.push(AppUrls.Client.MyProfile);
    };

    public navigateToFaqs = () => {
        this.history.push(AppUrls.Client.Faqs);
    };

    public navigateToMap = () => {
        this.history.push(AppUrls.Client.Map);
    };

    public navigateToDocs = () => {
        this.history.push(AppUrls.Client.Swagger);
    };

    @action
    private setActiveOption = (path: string) => {
        this.activeOption = path;
    };

    // #endregion Actions

    // #region Computeds

    @computed
    public get IsHomeActive() {
        return this.activeOption === AppUrls.Client.Home;
    }

    @computed
    public get IsMyProfileActive() {
        let isActive = false;

        switch (this.activeOption) {
            case AppUrls.Client.MyProfile:
            case AppUrls.Client.Account.Register:
            case AppUrls.Client.Account.ForgotPassword:
            case AppUrls.Client.Account.ResetPassword:
                isActive = true;
                break;
        }

        return isActive;
    }

    @computed
    public get IsFaqsActive() {
        return this.activeOption === AppUrls.Client.Faqs;
    }

    @computed
    public get IsMapActive() {
        return this.activeOption === AppUrls.Client.Map;
    }

    @computed
    public get IsDocActive() {
        return this.activeOption === AppUrls.Client.Swagger;
    }

    // #endregion Computeds

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
