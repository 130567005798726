import React from "react";

type Props = {
    width?: string | number;
    color?: string;
};
export const MapMarker: React.FC<Props> = (props: Props) => {
    // @ts-ignore
    return (
        <svg width="46" height="51.644" viewBox="0 0 46 51.644">
            <defs>
                <filter id="Path_3236" x="0" y="0" width="46" height="51.644" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_4468" transform="translate(-200 -623)">
                <g transform="matrix(1, 0, 0, 1, 200, 623)" filter="url(#Path_3236)">
                    <g id="Path_3236-2" transform="translate(11 8)" fill="#fff">
                        <path
                            d="M 12 30.32180023193359 L 11.34605979919434 29.75654029846191 C 11.2214298248291 29.6488094329834 8.259090423583984 27.07865905761719 5.248089790344238 23.64455032348633 C 1.102159976959229 18.91601943969727 -1 14.99822044372559 -1 12 C -1 8.527569770812988 0.3522399961948395 5.26298999786377 2.807610034942627 2.807610034942627 C 5.26298999786377 0.3522399961948395 8.527569770812988 -1 12 -1 C 15.47243022918701 -1 18.73700904846191 0.3522399961948395 21.19239044189453 2.807610034942627 C 23.64776039123535 5.26298999786377 25 8.527569770812988 25 12 C 25 14.99822044372559 22.89784049987793 18.91601943969727 18.75190925598145 23.64455032348633 C 15.74090957641602 27.07865905761719 12.7785701751709 29.6488094329834 12.65394020080566 29.75654029846191 L 12 30.32180023193359 Z"
                            stroke="none"
                        />
                        <path
                            d="M 12 0 C 5.372579574584961 0 0 5.372579574584961 0 12 C 0 18.62742042541504 12 29 12 29 C 12 29 24 18.62742042541504 24 12 C 24 5.372579574584961 18.62742042541504 0 12 0 M 12 -2 C 15.73954010009766 -2 19.2552490234375 -0.5437507629394531 21.89949989318848 2.100500106811523 C 24.54375076293945 4.744749069213867 26 8.260459899902344 26 12 C 26 13.90079021453857 25.29574012756348 16.01696014404297 23.84699058532715 18.46942901611328 C 22.78861045837402 20.26105117797852 21.32736015319824 22.22402000427246 19.50382041931152 24.30381965637207 C 16.44771957397461 27.78936958312988 13.43465042114258 30.40350914001465 13.30788993835449 30.51309013366699 L 12 31.64360046386719 L 10.69211006164551 30.51309013366699 C 10.56534957885742 30.40350914001465 7.552280426025391 27.78936958312988 4.496179580688477 24.30381965637207 C 2.672639846801758 22.22402000427246 1.211389541625977 20.26105117797852 0.1530094146728516 18.46942901611328 C -1.295740127563477 16.01696014404297 -2 13.90079021453857 -2 12 C -2 8.260459899902344 -0.5437507629394531 4.744749069213867 2.100500106811523 2.100500106811523 C 4.744749069213867 -0.5437507629394531 8.260459899902344 -2 12 -2 Z"
                            stroke="none"
                            fill="#134e76"
                        />
                    </g>
                </g>
                <circle id="Ellipse_137" cx="8" cy="8" r="8" transform="translate(215 634)" fill={props.color} />
            </g>
        </svg>
    );
};
MapMarker.defaultProps = {
    width: "290px",
    color: "#009b00",
};
