import { Box, Button, Typography } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useState } from "react";

// Styles
import { ButtonStyles } from "../../../../Globals/Styles/Primitives/ButtonStyles";
import { LoginStyles } from "../../../../Globals/Styles/Public/SubViews/LoginStyles";

// ViewModels
import { LogoutViewModel } from "./LogoutViewModel";

interface Props {
    className?: string;
}

export const Logout: React.FC<Props> = (props) => {
    const [viewModel] = useState(() => new LogoutViewModel());

    const buttonStyles = ButtonStyles();
    const classes = LoginStyles();

    // #region Code Behind

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            [classes.root]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.logOut();
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getClasses()}>
            <form className={classes.logout} onSubmit={onSubmit}>
                <Box className={classes.formRow}>
                    <Typography component="h1" variant="h2">
                        Hi {viewModel.userName},
                    </Typography>
                    <Button className={buttonStyles.containedSuccess} type="submit" variant="contained">
                        Log out
                    </Button>
                </Box>
            </form>
        </Box>
    ));
};
