import React from "react";
import { Box } from "@material-ui/core";
import { UnsupportedBrowserView } from "../../../Views/UnsupportedBrowser/UnsupportedBrowserView";

//EN: use empty generic type to get default react props
export const DefaultLayoutNoFooter: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Box>{props && <div>{props.children}</div>}</Box>
                </>
            )}
        </>
    );
};
