//ts-ignore
import React, { useEffect } from "react";
import SwaggerUi from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

export const Doc: React.FC = () => {
    useEffect(() => {
        document.title = "Adept API Documentation";
        SwaggerUi({
            dom_id: "#swaggerContainer",
            url: `/api/public/application/getswagger`,
            //presets: [presets.apis],
        });
    }, []);

    return <div id="swaggerContainer" />;
};
