import { ModelBase } from "@shoothill/core";
import { Equals, IsEmail, IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

export class MyAccountModel extends ModelBase<MyAccountModel, MyAccountModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_EMAILADDRESS = "";
    public static readonly DEFAULT_FIRSTNAME = "";
    public static readonly DEFAULT_LASTNAME = "";
    public static readonly DEFAULT_COMPANYNAME = "";
    public static readonly DEFAULT_INTENDEDUSE = "";
    public static readonly DEFAULT_AGREETERMSANDCONDITIONS = false;

    // #endregion Defaults

    // #region Observables

    @observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = MyAccountModel.DEFAULT_EMAILADDRESS;

    @observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = MyAccountModel.DEFAULT_FIRSTNAME;

    @observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = MyAccountModel.DEFAULT_LASTNAME;

    @observable
    public companyName: string = MyAccountModel.DEFAULT_COMPANYNAME;

    @observable
    @IsNotEmpty({ message: "Intended use is required" })
    public intendedUse: string = MyAccountModel.DEFAULT_INTENDEDUSE;

    @observable
    @Equals(true, { message: "Please agree to terms and conditions" })
    public agreeTermsAndConditions: boolean = MyAccountModel.DEFAULT_AGREETERMSANDCONDITIONS;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.emailAddress = MyAccountModel.DEFAULT_EMAILADDRESS;
        this.companyName = MyAccountModel.DEFAULT_COMPANYNAME;
        this.firstName = MyAccountModel.DEFAULT_FIRSTNAME;
        this.lastName = MyAccountModel.DEFAULT_LASTNAME;
        this.intendedUse = MyAccountModel.DEFAULT_INTENDEDUSE;
        this.agreeTermsAndConditions = MyAccountModel.DEFAULT_AGREETERMSANDCONDITIONS;
    }

    @action
    public fromDto(model: MyAccountModelDTO): void {
        this.emailAddress = model.emailAddress;
        this.companyName = model.companyName;
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.intendedUse = model.intendedUse;
        this.agreeTermsAndConditions = model.agreeTermsAndConditions;
    }

    public toDto(): MyAccountModelDTO {
        return {
            emailAddress: this.emailAddress,
            firstName: this.firstName,
            lastName: this.lastName,
            companyName: this.companyName,
            intendedUse: this.intendedUse,
            agreeTermsAndConditions: this.agreeTermsAndConditions,
        };
    }

    // #endregion Actions
}

export interface MyAccountModelDTO {
    emailAddress: string;
    firstName: string;
    lastName: string;
    companyName: string;
    intendedUse: string;
    agreeTermsAndConditions: boolean;
}
