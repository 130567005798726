import { Box } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";

// Styles
import { PublicWorkspaceStyles } from "../../../Globals/Styles/Public/PublicWorkspaceStyles";

export const TermsAndConditionsView: React.FC = () => {
    const workspaceClasses = PublicWorkspaceStyles();

    useEffect(() => {
        document.title = "Terms and conditions";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    // #endregion Code Behind

    return <Box className={getWorkspaceClasses()}>Public terms and conditions page</Box>;
};
