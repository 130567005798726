import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import MaterialTable from "material-table";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

// Styles
import { AdminTableWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";

// ViewModels
import { AdminUsersViewModel } from "./AdminUsersViewModel";
import { AdminUserViewModel } from "./AdminUserViewModel";

interface IDeleteConfirmationDialogState {
    isOpen: boolean;
    delete?: () => Promise<void>;
}

export const AdminUsersView: React.FC = () => {
    const [viewModel] = useState(() => new AdminUsersViewModel());
    const [openDeleteConfirmationState, setOpenDeleteConfirmation] = useState<IDeleteConfirmationDialogState>({ isOpen: false, delete: undefined });

    const buttonStyles = ButtonStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const tableWorkspaceClasses = AdminTableWorkspaceStyles();

    useEffect(() => {
        document.title = "User management";
    }, []);

    const tableRef = useRef<any>();

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
        });
    };

    const onOpenDeleteConfirmation = (event: any, rowData: AdminUserViewModel) => {
        event.stopPropagation();
        setOpenDeleteConfirmation({ isOpen: true, delete: rowData.deleteUser });
    };

    const onCloseConfirmDelete = () => {
        openDeleteConfirmationState.delete?.();
        setOpenDeleteConfirmation({ isOpen: false, delete: undefined });
    };

    const onCloseCancelDelete = () => {
        setOpenDeleteConfirmation({ isOpen: false, delete: undefined });
    };

    const onRowClick = (e: any, rowData: any) => {
        viewModel.navigateToUser(rowData.id);
    };

    const renderDeleteConfirmationDialog = () => {
        return (
            <Dialog onClose={onCloseCancelDelete} open={openDeleteConfirmationState.isOpen}>
                <DialogContent>
                    <DialogContentText>
                        <Typography color="textPrimary">Are you sure you want to delete this?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={buttonStyles.containedSuccess} color="primary" onClick={onCloseConfirmDelete}>
                        Yes
                    </Button>
                    <Button autoFocus className={buttonStyles.containedCancel} color="primary" onClick={onCloseCancelDelete}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderRowDeleteButton = (rowData: AdminUserViewModel) => {
        return (
            <IconButton onClick={(event: any) => onOpenDeleteConfirmation(event, rowData)}>
                <DeleteIcon />
            </IconButton>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            {openDeleteConfirmationState.isOpen && renderDeleteConfirmationDialog()}
            <Box className={tableWorkspaceClasses.titleContainer}>
                <Typography variant="h2">User management</Typography>
            </Box>
            <Box className={tableWorkspaceClasses.tableContainer}>
                <MaterialTable
                    columns={[
                        {
                            title: "Name",
                            field: "name",
                        },
                        {
                            title: "Email address",
                            field: "emailAddress",
                        },
                        {
                            title: "Company name",
                            field: "companyName",
                        },
                        {
                            title: "",
                            field: "",
                            render: (rowData: AdminUserViewModel) => renderRowDeleteButton(rowData),
                            cellStyle: {
                                maxWidth: "5%",
                                width: "5%",
                            },
                            headerStyle: {
                                maxWidth: "5%",
                                width: "5%",
                            },
                        },
                    ]}
                    data={viewModel.users}
                    onRowClick={onRowClick}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        toolbar: false,
                    }}
                    tableRef={tableRef}
                />
            </Box>
        </Box>
    ));
};
