// Libraries
import { FormControl, InputLabel, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React from "react";

interface IProps {
    canExecute?: boolean;
    className?: string;
    displayName?: string;
    execute: (value: any) => void;
    getOptionLabel: (option: any) => string;
    groupBy?: (option: any) => string;
    gutterBottom?: boolean;
    options: any[];
    placeholder?: string;
    validationMessage?: string;
    value: any;
}

export const Select: React.FC<IProps> = (props: IProps) => {
    // #region Code Behind

    //const selectClasses = WMFSSelectStyle();

    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["select"]: true,
            ["selectGutterBottom"]: hasGutterBottom(),
        });
    };

    const getId = (): string => {
        return "selectControlId";
    };

    const getPlaceholder = (): string => {
        return !isEmptyOrWhitespace(props.placeholder) ? props.placeholder! : "";
    };

    const getValidationMessage = (): string => {
        return !isEmptyOrWhitespace(props.validationMessage) ? props.validationMessage! : "";
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return !isNullOrUndefined(props.canExecute) ? !props.canExecute : false;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    const onChange = (e: any, value: any): void => {
        props.execute(value);
    };

    // #endregion Code Behind

    // #region Public Interface

    return useObserver(() => (
        <FormControl className={getClasses()}>
            <InputLabel disabled={isDisabled()} htmlFor={getId()} shrink>
                {props.displayName}
            </InputLabel>
            <Autocomplete
                disableClearable={true}
                disabled={isDisabled()}
                getOptionLabel={props.getOptionLabel}
                groupBy={props.groupBy}
                onChange={onChange}
                options={props.options}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        InputProps={{
                            disableUnderline: true,
                            ...params.InputProps,
                        }}
                        error={hasError()}
                        helperText={getValidationMessage()}
                        placeholder={getPlaceholder()}
                    />
                )}
                value={props.value}
            />
        </FormControl>
    ));

    // #endregion Public Interface
};
