import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, Box } from "@material-ui/core";
import { LinkBox, DotWrapper, Dot, Grid } from "@shoothill/core";
import { EditableCopyViewModel } from "Globals/ViewModels/EditableCopyViewModel";
import { GridLayouts } from "Examples/GridLayouts";
import { Buttons } from "Examples/Buttons";
import { TextInputs } from "Examples/TextInputs";
import { DatePicker } from "Examples/DatePicker";
import { Text } from "Examples/Text";
import { SelectInput } from "Examples/SelectInput";
import { Animation } from "Examples/Animation";

export const SamplesView: React.FC = () => {
    const [viewModel] = useState(() => new EditableCopyViewModel());
    const [view, setView] = useState(<Text />);

    return (
        <Grid desktopColumns={"1fr 5fr"}>
            <div>
                <LinkBox>
                    <Link onClick={() => setView(<Text />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 1))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Buttons />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 2))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<TextInputs />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 3))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<SelectInput />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 4))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<DatePicker />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 5))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<GridLayouts />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 6))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Animation />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 8))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Loaders />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 9))}</Link>
                </LinkBox>
            </div>
            <Box>{view}</Box>
        </Grid>
    );
};

export const Loaders: React.FC = () => {
    return (
        <>
            <Box paddingTop={2}>
                Animated Dots
                <DotWrapper>
                    <Dot delay="0s" />
                    <Dot delay=".1s" />
                    <Dot delay=".2s" />
                </DotWrapper>
            </Box>
        </>
    );
};
