import { makeStyles } from "@material-ui/core";
import { pxToRem } from "Globals/Styles/AppTheme";

const gutterSpacing = 30;

export const FaqsStyles = makeStyles((theme) => ({
    root: {},
    searchContainer: {
        alignItems: "center",
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            margin: `${pxToRem(40)} 0`,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
        },
    },
    listContainer: {
        margin: `${pxToRem(15)} 0`,
    },
    introductionSearch: {},
    introduction: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
    },
    search: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
    },
    formRow: {
        display: "flex",
        margin: `0 ${pxToRem(-15)}`,
        "& > .MuiFormControl-root": {
            flex: 1,
            margin: `0 ${pxToRem(15)}`,
        },
    },
}));

export const FaqStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            flex: 1,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            "& > div": {
                borderLeft: `${pxToRem(gutterSpacing / 2)} solid ${theme.palette.info.main}`,
                margin: `${pxToRem(gutterSpacing / 2)} ${pxToRem(gutterSpacing)}`,
                minHeight: pxToRem(100),
                padding: `${pxToRem(10)} 0 ${pxToRem(10)} ${pxToRem(gutterSpacing)}`,
            },
        },
    },
    innerRow: {
        display: "flex",
        padding: `${pxToRem(10)} 0`,
    },
    qa: {
        color: theme.palette.info.main,
        fontSize: pxToRem(28),
        fontWeight: "bold",
        marginRight: pxToRem(gutterSpacing / 2),
    },
}));
