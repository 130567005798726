import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";

// Globals
import { StoresInstance } from "Globals/Stores";

export class HomeViewModel extends ViewModelBase<any> {
    // #region Constructors and Disposers

    constructor() {
        super({});
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get canDisplayLogout() {
        return StoresInstance.Domain.AccountStore.IsLoggedIn;
    }

    // #endregion Properties

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
