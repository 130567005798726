import { FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { FeedCategory } from "../Feeds/FeedCategoryModel";
import { FeedsModel, UserFeedsResponseModelDTO } from "../Feeds/FeedsModel";
import { FeedSubCategory } from "../Feeds/FeedSubCategoryModel";

// ViewModels
import { FeedSubCategoryViewModel } from "./../Feeds/FeedSubCategoryViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class MyFeedsViewModel extends ViewModelBase<FeedsModel> {
    // #region Private Members

    @computed
    private get feedSubCategories(): FeedSubCategoryViewModel[] {
        const viewModels: FeedSubCategoryViewModel[] = [];
        const feedCategories = this.model.feedCategories.slice().sort((fc1: FeedCategory, fc2: FeedCategory) => fc1.ordinal - fc2.ordinal);

        for (const feedCategory of feedCategories) {
            const feedSubCategories = feedCategory.feedSubCategories.slice().sort((fsc1: FeedSubCategory, fsc2: FeedSubCategory) => fsc1.ordinal - fsc2.ordinal);

            for (const feedSubCategory of feedSubCategories) {
                viewModels.push(
                    new FeedSubCategoryViewModel(
                        feedSubCategory,
                        computed(() => ""),
                        true,
                    ),
                );
            }
        }

        viewModels.find((vm) => vm.numberOfFilteredFeeds > 0)?.setDisplayFeeds();

        return viewModels;
    }

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super(new FeedsModel());

        this.getFeedsByUser();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Actions

    @computed
    public get filteredFeedSubCategories(): FeedSubCategoryViewModel[] {
        return this.feedSubCategories.filter((fsc) => fsc.numberOfFilteredFeeds > 0);
    }

    @action
    public getFeedsByUser = async (): Promise<void> => {
        const DEFAULT_SERVERVALIDATIONMESSAGE = "There was an error trying to get the feeds.";

        try {
            this.server.resetValidationMessage();
            this.server.setIsBusy(true);

            const apiResult = await this.Get<UserFeedsResponseModelDTO>(AppUrls.Server.Public.Feed.GetAllByUser);

            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
            } else {
                if (!isNullOrUndefined(apiResult.errors) && apiResult.errors.length > 0) {
                    this.server.setValidationMessage(apiResult.errors[0].message);
                } else {
                    this.server.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
                }
            }
        } catch (exception) {
            this.server.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
        } finally {
            this.server.resetIsBusy();
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
