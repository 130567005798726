import { Box } from "@material-ui/core";
import React from "react";

// Styles
import { PublicLayoutStyles } from "../../Globals/Styles/Public/PublicLayoutStyles";

// Views
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { PublicFooter } from "./SubViews/Footer/PublicFooter";
import { PublicHeader } from "./SubViews/Header/PublicHeader";

/**
 * Layout for all pages. The header is fixed to the top of the page. The footer
 * and workspace are scrollable.
 */
export const PublicLayout: React.FC<{}> = (props) => {
    const classes = PublicLayoutStyles();

    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    } else {
        return (
            <Box className={classes.root}>
                <PublicHeader />
                <Box className={classes.scrollableContainer}>
                    {props.children}
                    <PublicFooter />
                </Box>
            </Box>
        );
    }
};
