import { createMuiTheme, PaletteColorOptions, Theme, ThemeOptions } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { colors } from "@material-ui/core";
import { Palette, PaletteColor, TypeText } from "@material-ui/core/styles/createPalette";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
const getTheme = (): Theme => {
    return createMuiTheme(appTheme as ThemeOptions);
};
//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
export const customBreakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1200,
        xl: 1920,
    },
});

const appTheme = createMuiTheme({
    breakpoints: customBreakpoints,
    ///**************
    ///Set up your spacing here according to Daves designs
    ///Dave tends to do multiples of 10. so p={1} will be 10px padding. p(5) will be 50px padding
    ///The code below will shrink the padding on small devices
    ///**************
    spacing: (value: number) => {
        let spacing = 10; //Default spacing on desktop
        const screenWidth = document.body.clientWidth;
        if (screenWidth <= appTheme.breakpoints.values["md"]) spacing /= 2;
        if (screenWidth > appTheme.breakpoints.values["md"] && screenWidth <= appTheme.breakpoints.values["lg"]) spacing /= 1.5;
        if (screenWidth > appTheme.breakpoints.values["lg"]) spacing /= 1;
        return value * spacing;
    },
    ///**************
    ///Set up your text sizes here to match Dave's designs
    ///You can also set up the values text should be on mobile devices if differenct
    ///**************
    typography: {
        fontFamily: "neo-sans, sans-serif;",
        h1: {
            fontSize: pxToRem(42),
            fontWeight: "bold",
            lineHeight: pxToRem(51),
            "& span.nowrap": {
                whiteSpace: "nowrap",
            },
            [customBreakpoints.down("sm")]: {
                //fontSize: pxToRem(56),
            },
        },
        h2: {
            fontSize: pxToRem(32),
            fontWeight: "bold",
            lineHeight: pxToRem(39),
            "& span.nowrap": {
                whiteSpace: "nowrap",
            },
            [customBreakpoints.down("sm")]: {
                //fontSize: pxToRem(38),
            },
        },
        h3: {
            fontSize: pxToRem(24),
            fontWeight: "bold",
            lineHeight: pxToRem(29),
            "& span.nowrap": {
                whiteSpace: "nowrap",
            },
            [customBreakpoints.down("sm")]: {
                //fontSize: pxToRem(32),
            },
        },
        h4: {
            fontSize: pxToRem(18),
            fontWeight: "bold",
            lineHeight: pxToRem(22),
            "& span.nowrap": {
                whiteSpace: "nowrap",
            },
            [customBreakpoints.down("sm")]: {
                //fontSize: pxToRem(24),
            },
        },
        h5: {
            fontSize: pxToRem(20),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(18),
            },
        },
        h6: {
            fontSize: pxToRem(18),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(16),
            },
        },
        subtitle1: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        subtitle2: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        body1: {
            fontSize: pxToRem(18),
            lineHeight: pxToRem(22),
            [customBreakpoints.down("sm")]: {
                //fontSize: pxToRem(14),
            },
        },
        body2: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        button: {
            fontSize: pxToRem(16),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        caption: {
            fontSize: pxToRem(14),
            [customBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
    },
    ///***************
    ///Set up your material ui overrides here or in the index.scss file. Scss will probably be faster and easier overall
    ///***************
    overrides: {
        MuiFormHelperText: {
            root: {},
        },
        MuiFormLabel: {
            root: {},
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: pxToRem(22),
            },
        },
        MuiAutocomplete: {
            listbox: {
                padding: 0,
            },
            noOptions: {
                padding: `${pxToRem(6)} ${pxToRem(16)}`,
            },
            paper: {
                background: "white",
                border: "1px solid #CED4DA",
                borderRadius: 0,
                fontSize: pxToRem(12),
                margin: "-1px 0 0 0",
            },
            popper: {},
        },
    },
} as ThemeOptions);

///***************
///Add your Custom Colours below for typescript checking
///Probably only need to add to interface Palette as I have done here with accent
///***************
declare module "@material-ui/core/styles/createPalette" {
    interface IconPaletteColorOptions {
        pdf?: string;
        csv?: string;
    }
    interface IconPaletteColor {
        pdf: string;
        csv: string;
    }
    interface PaletteOptions {
        accent?: PaletteColorOptions;
        icon?: IconPaletteColorOptions;
    }
    ///***************
    ///Add your Custom Colours below for typescript
    ///***************
    interface Palette {
        default: PaletteColor;
        offWhite: PaletteColor;
        adminGrey: PaletteColor;
        mapRed: PaletteColor;
        purpleAccent: PaletteColor;
        customText: TypeText;
        icon: IconPaletteColor;
    }
}

const customPalette = {
    /// ****************
    /// Customise the colours below to match your the designs
    /// ****************
    common: {
        black: "#000",
        white: "#FFF",
    },
    background: {
        paper: "#F3F3F3",
        default: "#FFF",
    },
    /// Allocated for Adept
    primary: {
        light: "#DD378F",
        main: "#D50674",
        dark: "#AA055D",
        contrastText: "#FFF",
    },
    /// Allocated for Adept
    secondary: {
        light: "#95BE58",
        main: "#7BAE2F",
        dark: "#628B26",
        contrastText: "#FFF",
    },
    /// Allocated for Adept
    info: {
        light: "#33B0E3",
        main: "#009DDD",
        dark: "#006D9A",
        contrastText: "#FFF",
    },
    /// Allocated for Adept
    success: {
        light: "#FFD353",
        main: "#FFC929",
        dark: "#B28C1C",
        contrastText: "#333",
    },

    error: {
        light: "#E57373",
        main: "#F44336",
        dark: "#D32F2F",
        contrastText: "#FFF",
    },
    warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
    },
    text: {
        primary: "#333", /// <-- Allocated for Adept
        secondary: "rgba(0,0,0, 0.54)",
        disabled: "rgba(0,0,0, 0.38)",
        hint: "rgba(0,0,0, 0.38)",
    },

    /// Allocated for Adept
    divider: "#ced4da",

    /// ***************
    /// Add your Custom Colours below and in the declare module above this code block
    /// ***************
    /// Allocated for Adept
    default: {
        light: "#5B5B5B",
        main: "#333",
        dark: "#232323",
        contrastText: "#FFF",
    },
    /// Allocated for Adept
    offWhite: {
        light: "#FFF",
        main: "#FCFCFC",
        dark: "#B0B0B0",
        contrastText: "#333",
    },
    /// Allocated for Adept
    adminGrey: {
        light: "#898987",
        main: "#6C6C6A",
        dark: "#4B4B4A",
        contrastText: "#FFF",
    },
    /// Allocated for Adept
    mapRed: {
        light: "#E34847",
        main: "#DD1B19",
        dark: "#9A1211",
        contrastText: "#FFF",
    },

    purpleAccent: {
        light: colors.purple[300],
        main: colors.purple[500],
        dark: colors.purple[700],
        contrastText: "#ffb74d",
    },
    customText: {
        primary: "rgba(255,255,255, 0.87)",
        secondary: "rgba(255,255,255, 0.54)",
        disabled: "rgba(255,255,255, 0.38)",
        hint: "rgba(255,255,255, 0.38)",
    },
    icon: {
        csv: "rgba(255,255,255, 0.87)",
        pdf: "rgba(255,255,255, 0.87)",
    },
} as Palette;

export const theme = getTheme();
theme.breakpoints = customBreakpoints;
theme.palette = { ...theme.palette, ...customPalette };
export const palette = theme.palette;
window.theme = theme;
console.log("Theme done");
