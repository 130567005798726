import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminFaqModel, AdminUpsertFaqResponseModelDTO } from "./AdminFaqModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminFaqViewModel extends ViewModelBase<AdminFaqModel> {
    // #region Constructors and Disposers

    constructor(id?: string) {
        super(new AdminFaqModel());

        this.setDecorators(AdminFaqModel);

        if (!isEmptyOrWhitespace(id)) {
            this.getFaq(id!);
        }
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get isExistingFaq(): boolean {
        return !isEmptyOrWhitespace(this.model.id);
    }

    // #endregion Properties

    // #region Answer

    @action
    public setAnswer = (value: string): void => {
        this.model.answer = value;
    };

    @computed
    public get answer(): string {
        return this.model.answer;
    }

    @computed
    public get confirmAnswerValidationMessage(): string {
        const result = this.validateDecorators("answer");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Answer

    // #region Question

    @action
    public setQuestion = (value: string): void => {
        this.model.question = value;
    };

    @computed
    public get question(): string {
        return this.model.question;
    }

    @computed
    public get confirmQuestionValidationMessage(): string {
        const result = this.validateDecorators("question");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Question

    // #region IsActive

    @action
    public setIsActive = (): void => {
        this.model.isActive = !this.model.isActive;
    };

    @computed
    public get isActive(): boolean {
        return this.model.isActive;
    }

    // #endregion IsActive

    // #region Actions

    public getFaq = (id: string): Promise<void> => {
        return this.server.query<AdminUpsertFaqResponseModelDTO>(
            () =>
                this.Post(AppUrls.Server.Admin.Faq.Get, {
                    id: id,
                }),
            (result) => this.model.fromDto(result),
        );
    };

    public updateFaq = (): Promise<void> => {
        return this.server.command<AdminUpsertFaqResponseModelDTO>(
            () => this.Post(AppUrls.Server.Admin.Faq.Update, this.model.toDto()),
            (result) => this.model.fromDto(result),
            this.isModelValid,
            "There was an error trying to update the faq.",
        );
    };

    @action
    public navigateBackToFaqs = () => {
        this.history.push(AppUrls.Client.Admin.Faq.Faqs);
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<AdminFaqModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
