import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, IComputedValue } from "mobx";

// Models
import { FeedCategory } from "./FeedCategoryModel";

// ViewModels
import { FeedSubCategoryViewModel } from "./FeedSubCategoryViewModel";
import { FeedViewModel } from "./FeedViewModel";

export class FeedCategoryViewModel extends ViewModelBase<FeedCategory> {
    // #region Private Members

    private activeKeyword: IComputedValue<string>;

    private setActiveFeedCategoryCallback: (value: string) => void;

    @observable
    private displaySubCategories = false;

    @computed
    private get feedSubCategories(): FeedSubCategoryViewModel[] {
        const viewModels: FeedSubCategoryViewModel[] = [];
        const feedSubCategories = this.model.feedSubCategories.slice().sort((fsc1, fsc2) => fsc1.ordinal - fsc2.ordinal);

        for (const feedSubCategory of feedSubCategories) {
            viewModels.push(new FeedSubCategoryViewModel(feedSubCategory, this.activeKeyword));
        }

        viewModels.find((vm) => vm.numberOfFilteredFeeds > 0)?.setDisplayFeeds();

        return viewModels;
    }

    @computed
    private get haveFilteredItems(): boolean {
        return this.filteredFeedSubCategories.length > 0;
    }

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(feedCategory: FeedCategory, setActiveFeedCategoryCallback: (value: string) => void, activeKeyword: IComputedValue<string>) {
        super(feedCategory);

        this.setActiveFeedCategoryCallback = setActiveFeedCategoryCallback;
        this.activeKeyword = activeKeyword;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get displayName(): string {
        return this.model.displayName;
    }

    @computed
    public get filteredFeedSubCategories(): FeedSubCategoryViewModel[] {
        return this.feedSubCategories.filter((fsc) => fsc.numberOfFilteredFeeds > 0);
    }

    @computed
    public get feedPins(): any {
        return "";
    }

    @computed
    public get id(): string {
        return this.model.id;
    }

    @computed
    public get numberOfFilteredFeeds() {
        let numberOfFeeds = 0;

        for (const feedSubCategory of this.filteredFeedSubCategories) {
            numberOfFeeds += feedSubCategory.numberOfFilteredFeeds;
        }

        return numberOfFeeds;
    }

    // #endregion Properties

    // #region Computeds

    @computed
    public get canDisplaySubCategories(): boolean {
        return this.haveFilteredItems && this.displaySubCategories;
    }

    @computed
    public get canSelectItem(): boolean {
        return this.haveFilteredItems;
    }

    // #endregion Computeds

    // #region Actions

    @action
    public setDisplaySubCategories = () => {
        if (this.haveFilteredItems) {
            this.displaySubCategories = !this.displaySubCategories;
            this.setActiveFeedCategoryCallback(this.displaySubCategories ? this.model.id : "");
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
