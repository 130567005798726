import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";
import { MyApplicationsResponseModelDTO } from "./MyApplicationsModel";

export class ApplicationModel extends ModelBase<ApplicationModel, ApplicationResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_APIKEY = "";
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_CREATEDDATETIMEUTC = "";
    public static readonly DEFAULT_MODIFIEDDATETIMEUTC = undefined;

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = ApplicationModel.DEFAULT_ID;

    @observable
    public apiKey: string = ApplicationModel.DEFAULT_APIKEY;

    @observable
    public name: string = ApplicationModel.DEFAULT_NAME;

    @observable
    public description: string = ApplicationModel.DEFAULT_DESCRIPTION;

    @observable
    public createdDateTimeUTC: string = ApplicationModel.DEFAULT_CREATEDDATETIMEUTC;

    @observable
    public modifiedDateTimeUTC?: string = ApplicationModel.DEFAULT_MODIFIEDDATETIMEUTC;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = ApplicationModel.DEFAULT_ID;
        this.apiKey = ApplicationModel.DEFAULT_APIKEY;
        this.name = ApplicationModel.DEFAULT_NAME;
        this.description = ApplicationModel.DEFAULT_DESCRIPTION;
        this.createdDateTimeUTC = ApplicationModel.DEFAULT_CREATEDDATETIMEUTC;
        this.modifiedDateTimeUTC = ApplicationModel.DEFAULT_MODIFIEDDATETIMEUTC;
    }

    @action
    public fromDto(dto: ApplicationResponseModelDTO): void {
        this.id = dto.id;
        this.apiKey = dto.apiKey;
        this.name = dto.name;
        this.description = dto.description;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
    }

    @action
    public fromAddedDto(dto: MyApplicationsResponseModelDTO): void {
        this.id = dto.id;
        this.apiKey = dto.apiKey;
        this.name = dto.name;
        this.description = dto.description;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
    }

    public toDto(): ApplicationResponseModelDTO {
        throw new Error("Method not implemented.");
    }

    // #endregion Actions
}

export interface ApplicationResponseModelDTO {
    id: string;
    apiKey: string;
    name: string;
    description: string;
    createdDateTimeUTC: string;
    modifiedDateTimeUTC?: string;
}
