import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";
import moment from "moment";
import "moment-timezone";

// Models
import { ApplicationModel } from "./ApplicationModel";

export class ApplicationViewModel extends ViewModelBase<ApplicationModel> {
    // #region Private Members

    public deleteCallback: (id: string) => Promise<void>;

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(model: ApplicationModel, deleteCallback: (id: string) => Promise<void>) {
        super(model);

        this.deleteCallback = deleteCallback;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get apiKey() {
        return this.model.apiKey;
    }

    @computed
    public get name() {
        return this.model.name;
    }

    @computed
    public get description() {
        return this.model.description;
    }

    @computed
    public get lastUpdated() {
        return isEmptyOrWhitespace(this.model.modifiedDateTimeUTC) ? this.model.createdDateTimeUTC : this.model.modifiedDateTimeUTC;
    }

    @computed
    public get lastUpdatedFormatted() {
        const utcDate = isEmptyOrWhitespace(this.model.modifiedDateTimeUTC) ? this.model.createdDateTimeUTC : this.model.modifiedDateTimeUTC;

        const ukDate = moment.utc(utcDate).tz("Europe/London");

        return ukDate.format("DD MMM YYYY hh:mma");
    }

    // #endregion Properties

    // #region Actions

    public delete = (): Promise<void> => this.deleteCallback(this.model.id);

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
