import { Box, Divider, Link } from "@material-ui/core";
import React, { useState } from "react";

import { AdminFooterStyles } from "../../../../Globals/Styles/Admin/AdminFooterStyles";
import { AdminFooterViewModel } from "./AdminFooterViewModel";

export const AdminFooter: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new AdminFooterViewModel());
    const classes = AdminFooterStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.logos}>
                <Link>
                    <Box className="thamesvalleyberkshirescc" />
                </Link>
                <Link href="http://www.thamesvalleyberkshire.co.uk" target="_blank">
                    <Box className="thamesvalleyberkshire" />
                </Link>
                <Link href="https://www.wokingham.gov.uk" target="_blank">
                    <Box className="wokingham" />
                </Link>
                <Link href="https://www.bracknell-forest.gov.uk" target="_blank">
                    <Box className="bracknell" />
                </Link>
                <Link href="https://www.westberks.gov.uk" target="_blank">
                    <Box className="westberkshire" />
                </Link>
                <Link href="https://www.reading.gov.uk" target="_blank">
                    <Box className="reading" />
                </Link>
                <Link href="https://www.rbwm.gov.uk" target="_blank">
                    <Box className="windsormaidenhead" />
                </Link>
                <Link href="https://www.slough.gov.uk" target="_blank">
                    <Box className="slough" />
                </Link>
                <Link href="https://www.adeptnet.org.uk/livelabs" target="_blank">
                    <Box className="livelabs" />
                </Link>
                <Link href="https://shoothill.com" target="_blank">
                    <Box className="shoothill" />
                </Link>
            </Box>
            <Box className={classes.navigationOptions}>
                <Box className={classes.navigationOption} onClick={viewModel.navigateTermsAndConditions}>
                    <Box>Terms & Conditions</Box>
                </Box>
                <Divider className={classes.navigationOptionDivider} orientation="vertical" />
                <Box className={classes.navigationOption} onClick={viewModel.navigatePrivacyPolicy}>
                    <Box>Privacy Policy</Box>
                </Box>
            </Box>
            <Box className={classes.copyright}>{viewModel.Copyright}</Box>
        </Box>
    );
};
