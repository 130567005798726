import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";

// Models
import { FaqModel } from "./FaqModel";

export class FaqViewModel extends ViewModelBase<FaqModel> {
    // #region Constructors and Disposers

    constructor(model: FaqModel) {
        super(model);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @computed
    public get answer() {
        return this.model.answer;
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get question() {
        return this.model.question;
    }

    // #endregion Properties

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<FaqModel>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
