import { FormControl, InputLabel, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React from "react";

interface Props {
    autoComplete?: boolean;
    canExecute?: boolean;
    className?: string;
    displayName: string;
    execute: (value: string) => void;
    gutterBottom?: boolean;
    numberOfRows?: number;
    placeholder?: string;
    validationMessage?: string;
    value: string;
}

export const EditTextArea: React.FC<Props> = (props) => {
    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["editText"]: true,
            ["editTextGutterBottom"]: hasGutterBottom(),
        });
    };

    const getId = (): string => {
        return "editControlId";
    };

    const getAutoComplete = (): "on" | "off" => {
        return isNullOrUndefined(props.autoComplete) ? "off" : props.autoComplete ? "on" : "off";
    };

    const getType = (): string => {
        return "text";
    };

    const getNumberOfRows = (): number => {
        return isNullOrUndefined(props.numberOfRows) ? 2 : props.numberOfRows!;
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "" : props.placeholder!;
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage) ? "" : props.validationMessage!;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    const hasGutterBottom = (): boolean => {
        return isNullOrUndefined(props.gutterBottom) ? false : props.gutterBottom!;
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.canExecute) ? false : !props.canExecute;
    };

    const onChange = (event: any): void => {
        props.execute(event.target.value);
    };

    return useObserver(() => (
        <FormControl className={getClasses()}>
            <InputLabel disabled={isDisabled()} htmlFor={getId()} shrink>
                {props.displayName}
            </InputLabel>
            <TextField
                InputProps={{
                    disableUnderline: true,
                }}
                autoComplete={getAutoComplete()}
                disabled={isDisabled()}
                error={hasError()}
                helperText={getValidationMessage()}
                id={getId()}
                multiline={true}
                onChange={onChange}
                placeholder={getPlaceholder()}
                rows={getNumberOfRows()}
                type={getType()}
                value={props.value}
            />
        </FormControl>
    ));
};
