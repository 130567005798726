import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

export const AdminHeaderStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `0px ${pxToRem(3)} ${pxToRem(6)} ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "center",
        minHeight: pxToRem(60),
        zIndex: 1000,
    },
    content: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        margin: `0 ${pxToRem(30)}`,
    },
    logo: {
        backgroundImage: "url(resources/Logo.svg)",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        flex: 1,
        maxWidth: pxToRem(110),
        "&:hover": {
            cursor: "pointer",
        },
    },
    navigationOptions: {
        display: "flex",
    },
    navigationOption: {
        display: "flex",
        flexDirection: "column",
        "& > div": {
            alignItems: "center",
            borderLeft: "1px solid transparent",
            borderRight: "1px solid transparent",
            display: "flex",
            fontSize: pxToRem(14),
            fontWeight: "bold",
            flex: 1,
            padding: `0 ${pxToRem(30)}`,
        },
        "&:hover": {
            cursor: "pointer",
        },
    },
    navigationOptionActive: {
        backgroundColor: theme.palette.offWhite.main,
        marginBottom: pxToRem(-10),
        paddingBottom: pxToRem(10),
        "& > div": {
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    },
}));

export const AdminHomeHeaderStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    logo: {
        backgroundImage: "url(resources/Logo.svg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        minWidth: pxToRem(310),
        minHeight: pxToRem(100),
        margin: "55px 0 12.5px 0",
    },
}));
