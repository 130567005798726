import { makeStyles } from "@material-ui/core";

/**
 * Styles for use with the Admin layout components.
 */
export const AdminLayoutStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    scrollableContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowY: "auto",
    },
}));
