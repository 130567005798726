import * as Utils from "@shoothill/core";
import { BaseStore, CoreStoreInstance } from "@shoothill/core";
import { action, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Globals/Models";
import { AccountStatus } from "@shoothill/core";

export class AccountStore extends BaseStore {
    @observable public IsLoggedIn: boolean = false;
    @observable public UserName: string = "";
    @observable public DisplayName: string = "";
    @observable public UserRoles: string[] = [];

    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
        AccountStoresInstance = this;
    }

    public init(stores: Stores, initialState: InitialState) {
        this.getLoginState(initialState.accountStatus);
        //this.tokenViewModel = new TokenViewModel();
    }

    public getJwt = (): string => {
        return this.jwt as string;
    };

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }
        return false;
    };

    public get IsAdministrator(): boolean {
        return this.isInRole("admin");
    }

    public get IsUser(): boolean {
        return this.isInRole("user");
    }

    @action
    public setIsLoggedIn = (state: boolean) => {
        this.IsLoggedIn = state;
        CoreStoreInstance.SetLoggedIn(false);
    };

    @action
    public setDisplayName = (value: string) => {
        this.DisplayName = value;
        CoreStoreInstance.SetDisplayName(this.DisplayName);
    };

    @action
    public Logout = (redirect: boolean = false): void => {
        localStorage.removeItem(".auth");
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.setIsLoggedIn(false);
        this.UserName = "";
        this.DisplayName = "";
        CoreStoreInstance.SetDisplayName("");
        this.UserRoles = [];
        //(window as any).jwt = null;
        if (redirect) {
            window.location.href = "/";
        }
    };

    @action
    public getLoginState = (apiResult: AccountStatus) => {
        const jwt = apiResult?.jwt ?? "";

        if (!jwt || jwt.length === 0) {
            this.jwt = localStorage.getItem(".auth") as string;
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            localStorage.setItem(".auth", this.jwt);
            if (data === "") {
                return;
            }
            //localStorage.setItem(".refreshToken", apiResult?.refreshToken ?? "");
            //localStorage.setItem(".refreshTokenExpiryDate", apiResult?.refreshTokenExpiryDate ?? new Date());
            this.setIsLoggedIn(true);
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
            CoreStoreInstance.SetDisplayName(this.DisplayName);
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
        }
    };
}
export let AccountStoresInstance = {} as AccountStore;
