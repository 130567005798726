import { ModelBase } from "@shoothill/core";
import { IsEmail, IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

export class ForgotPasswordModel extends ModelBase<ForgotPasswordModel, ForgotPasswordModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_EMAILADDRESS = "";

    // #endregion Defaults

    // #region Observables

    @observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public emailAddress: string = ForgotPasswordModel.DEFAULT_EMAILADDRESS;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.emailAddress = ForgotPasswordModel.DEFAULT_EMAILADDRESS;
    }

    public fromDto(model: ForgotPasswordModelDTO): void {
        throw new Error("Method not implemented.");
    }

    public toDto(): ForgotPasswordModelDTO {
        return {
            emailAddress: this.emailAddress,
        };
    }
}

export interface ForgotPasswordModelDTO {
    emailAddress: string;
}
