import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

export const PublicHeaderStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `0px ${pxToRem(3)} ${pxToRem(6)} ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "center",
        minHeight: pxToRem(115),
        zIndex: 1000,
    },
    content: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        margin: `0 ${pxToRem(30)}`,
        maxWidth: pxToRem(960),
    },
    logo: {
        backgroundImage: "url(resources/NewLogo.png)",
        height: "150px",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        flex: 1,
        maxWidth: pxToRem(205),
        "&:hover": {
            cursor: "pointer",
        },
    },
    navigationOptions: {
        display: "flex",
    },
    navigationOption: {
        borderRadius: `0 0 ${pxToRem(5)} ${pxToRem(5)}`,
        display: "flex",
        flexDirection: "column",
        height: pxToRem(40),
        minWidth: pxToRem(85),
        minHeight: pxToRem(40),
        "& > div": {
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            fontSize: pxToRem(14),
            fontWeight: "bold",
            justifyContent: "center",
            marginBottom: pxToRem(6),
        },
        "&:hover": {
            cursor: "pointer",
        },
    },
    navigationOptionActive: {
        height: pxToRem(50),
        minHeight: pxToRem(50),
    },
    naviagationOptionFaqs: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.info.dark,
        },
    },
    navigationOptionHome: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    navigationOptionMap: {
        backgroundColor: theme.palette.mapRed.main,
        color: theme.palette.mapRed.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.mapRed.dark,
        },
    },
    navigationOptionMyProfile: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}));
