import { makeStyles, Theme } from "@material-ui/core";
import { pxToRem } from "Globals/Styles/AppTheme";

const gutterSpacing = 30;

const getCanSelectApplications = (props: any) => {
    return props.viewModel.canSelectItem;
};

export const MyApplicationsStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            margin: `${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)} 0 ${pxToRem(gutterSpacing)}`,
            maxWidth: pxToRem(960),
        },
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
    formv2: {
        flex: 1,
        maxWidth: pxToRem(960),
        padding: `${pxToRem(30)} ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
    },
    formRow: {
        padding: `0 ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
    },
    formButtonRowWithTop: {
        display: "flex",
        justifyContent: "center",
        padding: `${pxToRem(15)}`,
        "& > button": {
            flex: `0 1 ${pxToRem(400)}`,
        },
    },
    alert: {
        fontSize: pxToRem(14),
        marginTop: pxToRem(15),
    },
    info: {
        fontSize: pxToRem(14),
    },
    infoWithTop: {
        fontSize: pxToRem(14),
        marginTop: pxToRem(15),
    },
    emptyWarning: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            justifyContent: "center",
            margin: `${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)} 0 ${pxToRem(gutterSpacing)}`,
            maxWidth: pxToRem(960),
        },
    },
}));

export const ApplicationsStyles = makeStyles((theme) => ({
    root: {
        margin: `${pxToRem(gutterSpacing)} 0`,
    },
    innerRowv2: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            margin: `0 ${pxToRem(gutterSpacing)}`,
            maxWidth: pxToRem(960),
            minHeight: pxToRem(99),
            padding: pxToRem(gutterSpacing),
            "&:hover": {
                backgroundColor: (props) => (getCanSelectApplications(props) ? theme.palette.secondary.dark : theme.palette.secondary.main),
                cursor: (props) => (getCanSelectApplications(props) ? "pointer" : "auto"),
            },
        },
    },
    iconText: {
        backgroundImage: "url(resources/energy.svg)",
        backgroundPosition: "left center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        minHeight: pxToRem(40),
        minWidth: pxToRem(40),
        paddingLeft: pxToRem(75),
    },
    countArrow: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        maxWidth: pxToRem(160),
        "& > svg": {
            fontSize: pxToRem(39),
        },
    },
    items: {
        display: "flex",
        flexDirection: "column",
        margin: `${pxToRem(gutterSpacing / 2)} 0`,
    },
}));

export const ApplicationStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        "& > div": {
            flex: 1,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
            "& > div": {
                backgroundColor: "#F3F3F3",
                borderLeft: (props) => `${pxToRem(gutterSpacing / 2)} solid ${theme.palette.secondary.light}`,
                margin: `${pxToRem(gutterSpacing / 2)} ${pxToRem(gutterSpacing)}`,
                minHeight: pxToRem(100),
                padding: `${pxToRem(10)} ${pxToRem(gutterSpacing)}`,
            },
        },
    },
    topRow: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            marginRight: pxToRem(gutterSpacing),
        },
    },
    innerRow: {
        padding: `${pxToRem(10)} 0`,
    },
}));
