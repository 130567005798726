import { makeStyles } from "@material-ui/core";
import { palette, pxToRem } from "../AppTheme";

const gutterSpacing = 30;

/**
 * Styles for use with the all workspace components.
 */
export const PublicWorkspaceStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
}));

/**
 * Styles for use with account form-based workspace components.
 */
export const PublicAccountFormWorkspaceStyles = makeStyles((theme) => ({
    root: {},
    titleContainer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        display: "flex",
        justifyContent: "center",
        minHeight: pxToRem(170),
    },
    titleContainerRow: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        maxWidth: pxToRem(960 + 2 * gutterSpacing),
        paddingBottom: pxToRem(30),
        "& > button": {
            margin: `0 ${pxToRem(gutterSpacing)}`,
        },
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
    form: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: pxToRem(5),
        flex: 1,
        margin: `${pxToRem(-gutterSpacing)} ${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)} ${pxToRem(gutterSpacing)}`,
        maxWidth: pxToRem(960),
        padding: `${pxToRem(30)} ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
    },
    formv2: {
        flex: 1,
        maxWidth: pxToRem(960),
        padding: `${pxToRem(30)} ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
    },
    formRow: {
        padding: `0 ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
    },
    formButtonRow: {
        display: "flex",
        justifyContent: "center",
        padding: `0 ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)}`,
        "& > button": {
            flex: `0 1 ${pxToRem(400)}`,
        },
    },
    formButtonRowWithTop: {
        display: "flex",
        justifyContent: "center",
        padding: `${pxToRem(15)}`,
        "& > button": {
            flex: `0 1 ${pxToRem(400)}`,
        },
    },
    alert: {
        fontSize: pxToRem(14),
        marginTop: pxToRem(15),
    },
    info: {
        fontSize: pxToRem(14),
    },
    infoWithTop: {
        fontSize: pxToRem(14),
        marginTop: pxToRem(15),
    },
}));
