import React from "react";
import { DefaultLayout } from "Globals/Views/Layouts/DefaultLayout";
import { Route } from "react-router-dom";
import { RouteComponentProps, RouteProps } from "react-router";

interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    layout?: any;
}

export const RouteWithLayout: React.FC<Props> = (props: Props) => {
    if (!props) return <>Props undefined</>;

    const { component: Component, layout: NewLayout, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(props) => (
                <NewLayout>
                    <Component {...props} />
                </NewLayout>
            )}
        />
    );
};

RouteWithLayout.defaultProps = {
    layout: DefaultLayout,
};
