import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { AdminHomeStyles } from "Globals/Styles/Admin/Home/AdminHomeStyles";
import { AdminFormWorkspaceStyles, AdminWorkspaceStyles } from "Globals/Styles/Admin/AdminWorkspaceStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";

// Views
import { EditText } from "Views/Primitives/EditText";

export const AdminResetPasswordView: React.FC<{}> = (props) => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());

    const buttonStyles = ButtonStyles();
    const editTextStyles = EditTextStyles();
    const classes = AdminHomeStyles();
    const workspaceClasses = AdminWorkspaceStyles();
    const formWorkspaceClasses = AdminFormWorkspaceStyles();

    useEffect(() => {
        document.title = "Reset password";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const getFormContainerClasses = () => {
        return clsx({
            [classes.formContainer]: true,
            [formWorkspaceClasses.formContainer]: true,
        });
    };

    const getFormClasses = () => {
        return clsx({
            [classes.form]: true,
            [formWorkspaceClasses.form]: true,
        });
    };

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.resetPassword();
    };

    const renderTitle = (): JSX.Element => {
        return (
            <Box className={formWorkspaceClasses.titleContainer}>
                <Box className={formWorkspaceClasses.titleContainerRow}>
                    <Typography component="h1" variant="h2">
                        Reset password
                    </Typography>
                </Box>
            </Box>
        );
    };

    const renderResetPasswordSuccess = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} key="success-1" rowGap={15}>
                    <Alert className={formWorkspaceClasses.alert} severity="info" variant="filled">
                        Your password has been reset.
                    </Alert>
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRowWithTop} columnGap={30} key="success-2" rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} onClick={viewModel.navigateToHome} variant="contained">
                        Back
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };

    const renderResetPasswordForm = (): JSX.Element => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} key="form-1" rowGap={15}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicLight}
                        displayName="New password*"
                        execute={viewModel.setPassword}
                        isPassword={true}
                        placeholder="Your new password"
                        validationMessage={viewModel.passwordValidationMessage}
                        value={viewModel.password}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} key="form-2" rowGap={15}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicLight}
                        displayName="Confirm new password*"
                        execute={viewModel.setConfirmPassword}
                        isPassword={true}
                        placeholder="Your new password"
                        validationMessage={viewModel.confirmPasswordValidationMessage}
                        value={viewModel.confirmPassword}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRowWithTop} columnGap={30} key="form-3" rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Reset password
                    </Button>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} onClick={viewModel.navigateToHome} variant="contained">
                        Back
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formButtonRowWithTop} columnGap={30} key="form-4" rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            {renderTitle()}
            <Box className={getFormContainerClasses()}>
                <form className={getFormClasses()} onSubmit={onSubmit}>
                    {viewModel.server.IsRequestSuccessful ? renderResetPasswordSuccess() : renderResetPasswordForm()}
                </form>
            </Box>
        </Box>
    ));
};
