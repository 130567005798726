import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { MyApplicationsModel, MyApplicationsResponseModelDTO } from "./MyApplicationsModel";

// ViewModels
import { ApplicationsViewModel } from "./ApplicationsViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class MyApplicationsViewModel extends ViewModelBase<MyApplicationsModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new MyApplicationsModel());

        this.setDecorators(MyApplicationsModel);

        this.applicationsViewModel = new ApplicationsViewModel();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    public applicationsViewModel: ApplicationsViewModel;

    @observable
    public canDisplayAddApplication = false;

    // #endregion Properties

    // #region Name

    @action
    public setName = (value: string): void => {
        this.model.name = value;
    };

    @computed
    public get name(): string {
        return this.model.name;
    }

    @computed
    public get nameValidationMessage(): string {
        const result = this.validateDecorators("name");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #region Name

    // #region Description

    @action
    public setDescription = (value: string): void => {
        this.model.description = value;
    };

    @computed
    public get description(): string {
        return this.model.description;
    }

    @computed
    public get descriptionValidationMessage(): string {
        const result = this.validateDecorators("description");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #region Description

    // #region Actions

    public updateApplication = (): Promise<void> => {
        return this.server.command<MyApplicationsResponseModelDTO>(
            () => this.Post(AppUrls.Server.Public.Application.Update, this.model.toDto()),
            (result) => {
                this.model.fromDto(result);
                this.applicationsViewModel.addOrUpateCollection(result);
            },
            this.isModelValid,
        );
    };

    @action
    public setCanDisplayAddApplication = (): void => {
        this.canDisplayAddApplication = !this.canDisplayAddApplication;

        if (!this.canDisplayAddApplication) {
            this.model.reset();
            this.server.reset();
        }
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<MyApplicationsModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
