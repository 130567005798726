import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { PublicWorkspaceStyles } from "../../../Globals/Styles/Public/PublicWorkspaceStyles";
import { MyProfileStyles } from "../../../Globals/Styles/Public/MyProfile/MyProfileStyles";

// ViewModels
import { MyProfileViewModel } from "./MyProfileViewModel";

// Views
import { Login } from "../SubViews/Login/Login";
import { Logout } from "../SubViews/Logout/Logout";
import { MyAccountView } from "../SubViews/MyAccount/MyAccountView";
import { MyApplicationsView } from "../SubViews/MyApplications/MyApplicationsView";
import { MyFeedsView } from "../SubViews/MyFeeds/MyFeedsView";

export const MyProfileView: React.FC = () => {
    const [viewModel] = useState(() => new MyProfileViewModel());
    useEffect(() => {
        return () => viewModel.dispose();
    }, []);

    const workspaceClasses = PublicWorkspaceStyles();
    const classes = MyProfileStyles();

    useEffect(() => {
        document.title = "My profile";
    }, []);

    // #region Code Behind

    const getWorkspaceClasses = () => {
        return clsx({
            [workspaceClasses.root]: true,
            [classes.root]: true,
        });
    };

    const getNavigationButtonClasses = (isOptionActive: boolean) => {
        return clsx({
            [classes.tab]: true,
            [classes.activeTab]: isOptionActive,
        });
    };

    const renderLoginContainer = (): JSX.Element => {
        return (
            <Box className={classes.introductionLogin}>
                <Box className={classes.introduction}>
                    <Typography component="h1" gutterBottom variant="h2">
                        My Profile
                    </Typography>
                    <Typography variant="body1">
                        Register an account with the Open Data Hub to access and subscribe to a wide variety of transport datasets. You’ll be provided with all the information you
                        need to make use the datasets shown on the homepage, including; samples, documentation, API keys, and feed status.
                    </Typography>
                </Box>
                <Login className={classes.login} />
            </Box>
        );
    };

    const renderLogoutContainer = (): JSX.Element => {
        return (
            <Box className={classes.introductionLogout}>
                <Box className={classes.introduction}>
                    <Logout />
                    <Typography gutterBottom variant="body1">
                        Below is your profile.
                    </Typography>
                </Box>
            </Box>
        );
    };

    // #endregion Code Behind

    return useObserver(() => (
        <Box className={getWorkspaceClasses()}>
            <Box className={classes.introductionContainer}>{viewModel.canDisplayLogout ? renderLogoutContainer() : renderLoginContainer()}</Box>
            {viewModel.canDisplayProfile && (
                <Box>
                    <Box className={classes.tabsContainer}>
                        <Box className={classes.tabs}>
                            {viewModel.myProfileOptions.map((o) => (
                                <Box className={getNavigationButtonClasses(viewModel.isOptionActive(o.id))} key={o.id} onClick={() => viewModel.setMyProfileOption(o)}>
                                    {o.displayName}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    {viewModel.isOptionActive("id_myaccount") && <MyAccountView />}
                    {viewModel.isOptionActive("id_myfeeds") && <MyFeedsView />}
                    {viewModel.isOptionActive("id_myapplications") && <MyApplicationsView />}
                </Box>
            )}
        </Box>
    ));
};
