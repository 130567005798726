import { FieldType, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminUserModel } from "./AdminUserModel";

export class AdminUserViewModel extends ViewModelBase<AdminUserModel> {
    // #region Private Members

    private deleteUserCallback: (id: string) => Promise<void>;

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(model: AdminUserModel, deleteUserCallback: (id: string) => Promise<void>) {
        super(model);

        this.deleteUserCallback = deleteUserCallback;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get companyName() {
        return this.model.companyName;
    }

    @computed
    public get emailAddress() {
        return this.model.emailAddress;
    }

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get name() {
        return `${this.model.firstName} ${this.model.lastName}`;
    }

    // #endregion Properties

    // #region Actions

    public deleteUser = (): Promise<void> => this.deleteUserCallback(this.model.id);

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
