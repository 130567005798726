import { makeStyles } from "@material-ui/core";
import { pxToRem } from "Globals/Styles/AppTheme";

const gutterSpacing = 30;

export const MyProfileStyles = makeStyles((theme) => ({
    root: {},
    introductionContainer: {
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        display: "flex",
        justifyContent: "center",
        "& > div": {
            display: "flex",
            flex: 1,
            margin: `${pxToRem(60)} 0`,
            maxWidth: pxToRem(960 + 2 * gutterSpacing),
        },
    },
    introductionLogin: {},
    introductionLogout: {},
    introduction: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
    },
    login: {
        flex: 1,
        margin: `0 ${pxToRem(gutterSpacing)}`,
        "& > form": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
    },
    tabsContainer: {
        display: "flex",
        justifyContent: "center",
    },
    tabs: {
        display: "flex",
        flex: 1,
        marginTop: pxToRem(-50),
        maxWidth: pxToRem(960 + 2 * gutterSpacing),
        padding: `0 ${pxToRem(gutterSpacing)}`,
    },
    tab: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        borderRadius: `${pxToRem(5)} ${pxToRem(5)} 0 0`,
        boxShadow: "inset 0px -0.1875rem 0.375rem #ced4da",
        display: "flex",
        fontSize: "18px",
        fontWeight: "bold",
        height: pxToRem(50),
        justifyContent: "center",
        marginRight: pxToRem(2),
        minWidth: pxToRem(195),
        "&:hover": {
            cursor: "pointer",
        },
    },
    activeTab: {
        backgroundColor: "white",
        boxShadow: "none",
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));
