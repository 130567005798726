import { ModelBase } from "@shoothill/core";
import { action, observable } from "mobx";

export class FaqModel extends ModelBase<FaqModel> {
    // #region Defaults

    public static readonly DEFAULT_ID = "";
    public static readonly DEFAULT_ANSWER = "";
    public static readonly DEFAULT_QUESTION = "";

    // #endregion Defaults

    // #region Observables

    @observable
    public id: string = FaqModel.DEFAULT_ID;

    @observable
    public answer: string = FaqModel.DEFAULT_ANSWER;

    @observable
    public question: string = FaqModel.DEFAULT_QUESTION;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = FaqModel.DEFAULT_ID;
        this.answer = FaqModel.DEFAULT_ANSWER;
        this.question = FaqModel.DEFAULT_QUESTION;
    }

    @action
    public fromDto(dto: GetFaqResponseModelDTO): void {
        this.id = dto.id;
        this.answer = dto.answer;
        this.question = dto.question;
    }

    public toDto() {
        throw "Not implemented";
    }

    // #endregion Actions
}

export interface GetFaqResponseModelDTO {
    id: string;
    answer: string;
    question: string;
}
