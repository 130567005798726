import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

export const PublicFooterStyles = makeStyles((theme) => ({
    root: {
        alignItems: "center",
        backgroundColor: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        minHeight: pxToRem(200),
        justifyContent: "center",
    },
    copyright: {
        margin: pxToRem(7.5),
    },
    logos: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: `${pxToRem(20)} ${pxToRem(7.5)}`,
        "& > a": {
            margin: `0 ${pxToRem(15)}`,
            "& > div": {
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                minHeight: pxToRem(50),
                minWidth: pxToRem(100),
            },
            "& > div.thamesvalleyberkshirescc": {
                backgroundImage: "url(resources/thamesvalleyberkshirescc.svg)",
            },
            "& > div.thamesvalleyberkshire": {
                backgroundImage: "url(resources/thamesvalleyberkshire.png)",
            },
            "& > div.wokingham": {
                backgroundImage: "url(resources/wokingham.png)",
            },
            "& > div.bracknell": {
                backgroundImage: "url(resources/bracknell.svg)",
            },
            "& > div.westberkshire": {
                backgroundImage: "url(resources/westberkshire.svg)",
            },
            "& > div.reading": {
                backgroundImage: "url(resources/reading.png)",
            },
            "& > div.windsormaidenhead": {
                backgroundImage: "url(resources/windsormaidenhead.png)",
            },
            "& > div.slough": {
                backgroundImage: "url(resources/slough.png)",
            },
            "& > div.livelabs": {
                backgroundImage: "url(resources/livelabs.jpg)",
            },
            "& > div.dft": {
                backgroundImage: "url(resources/DfT_3298_AW.png)",
            },
            "& > div.shoothill": {
                backgroundImage: "url(resources/shoothill.svg)",
            },
        },
    },
    shoothill: {
        width: "100%",
        boxShadow: "inset 0px 3px 6px #00000029",
        "& div": {
            backgroundImage: "url(resources/shoothill.svg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: pxToRem(33),
            margin: pxToRem(10),
            overflow: "hidden",
        },
    },
    navigationOptions: {
        display: "flex",
        margin: pxToRem(7.5),
    },
    navigationOption: {
        "& > div": {
            fontSize: pxToRem(18),
            fontWeight: "bold",
            lineHeight: 1,
            padding: `0 ${pxToRem(15)}`,
        },
        "&:hover": {
            cursor: "pointer",
        },
    },
    navigationOptionDivider: {
        backgroundColor: theme.palette.text.primary,
        width: pxToRem(2),
    },
}));
