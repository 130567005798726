import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components/macro";
import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { MapMarker } from "./MapMarker";

export const Map = styled(Box)`
    position: relative;
    .leaflet-container {
        height: 74vh;
        min-height: 600px;
    }
    .marker-cluster-custom {
        background-color: rgba(19, 78, 118, 0.7);
        border: 3px solid #ededed;
        border-radius: 50%;
        color: white;
        height: 40px;
        line-height: 37px;
        text-align: center;
        width: 40px;
    }
    .feedsContainer {
        width: 400px;
        background-color: #556c6c6a;
        border-radius: 5px;
        position: absolute;
        top: 47px;
        right: 15px;
        z-index: 1000;
    }
`;

export const createClusterCustomIcon = (cluster: any) => {
    return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: "marker-cluster-custom",
        iconSize: L.point(40, 40, true),
    });
};

export const customMarker = (available: boolean) =>
    L.divIcon({
        className: "leaflet-data-marker",
        html: renderToStaticMarkup(<MapMarker color={available ? "#134E76" : "#2C8F9F"} />),
        iconAnchor: [23, 51],
        iconSize: [46, 51],
        popupAnchor: [0, -28],
    });

export const customMarkerSelected = L.divIcon({
    className: "leaflet-data-marker",
    html: renderToStaticMarkup(<MapMarker color={"green"} />),
    iconAnchor: [23, 51],
    iconSize: [46, 51],
    popupAnchor: [0, -28],
});
