import { Box } from "@material-ui/core";
import React from "react";

import { AdminLayoutStyles } from "../../Globals/Styles/Admin/AdminLayoutStyles";

import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { AdminFooter } from "./SubViews/Footer/AdminFooter";
import { AdminHeader } from "./SubViews/Header/AdminHeader";
import { AdminHomeHeader } from "./SubViews/Header/AdminHomeHeader";

/**
 * Layout for the login page. The header, footer and workspace are scrollable.
 */
export const AdminLayout: React.FC<{}> = (props) => {
    const classes = AdminLayoutStyles();

    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    } else {
        return (
            <Box className={classes.root}>
                <Box className={classes.scrollableContainer}>
                    <AdminHomeHeader />
                    {props.children}
                    <AdminFooter />
                </Box>
            </Box>
        );
    }
};

/**
 * Layout for form pages. The header is fixed to the top of the page. There is
 * no footer and workspaces are scrollable.
 */
export const AdminFormLayout: React.FC<{}> = (props) => {
    const classes = AdminLayoutStyles();

    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    } else {
        return (
            <Box className={classes.root}>
                <AdminHeader />
                <Box className={classes.scrollableContainer}>{props.children}</Box>
            </Box>
        );
    }
};

/**
 * Layout for table pages. The header is fixed to the top of the page. There is
 * no footer and workspaces are not scrollable as that is the responsibility of
 * the table.
 */
export const AdminTableLayout: React.FC<{}> = (props) => {
    const classes = AdminLayoutStyles();

    if (!props) {
        return <>Props undefined</>;
    }

    if (window.IE11) {
        return <UnsupportedBrowserView />;
    } else {
        return (
            <Box className={classes.root}>
                <AdminHeader />
                <Box className={classes.container}>{props.children}</Box>
            </Box>
        );
    }
};
