import { ApiResult, isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { action, computed, observable } from "mobx";

export class ServerViewModel {
    // #region Defaults

    public static readonly DEFAULT_ISBUSY = false;
    public static readonly DEFAULT_ISREQUESTSUCCESSFUL = false;
    public static readonly DEFAULT_ISSUBMITTED = false;
    public static readonly DEFAULT_VALIDATIONMESSAGE = "";

    // #endregion Defaults

    // #region Reset

    public reset = (): void => {
        this.resetIsBusy();
        this.resetIsSubmitted();
        this.resetRequestSuccessful();
        this.resetValidationMessage();
    };

    // #endrgion Reset

    // #region Busy

    @observable
    private isBusy = ServerViewModel.DEFAULT_ISBUSY;

    @computed
    public get IsBusy(): boolean {
        return this.isBusy;
    }

    @action
    public resetIsBusy = (): void => {
        this.isBusy = ServerViewModel.DEFAULT_ISBUSY;
    };

    @action
    public setIsBusy = (value: boolean): void => {
        this.isBusy = value;
    };

    // #endregion Busy

    // #region Error

    @observable
    private validationMessage = ServerViewModel.DEFAULT_VALIDATIONMESSAGE;

    @computed
    public get ValidationMessage(): string {
        return this.validationMessage;
    }

    @action
    public resetValidationMessage = (): void => {
        this.validationMessage = ServerViewModel.DEFAULT_VALIDATIONMESSAGE;
    };

    @computed
    public get HaveValidationMessage(): boolean {
        return !isEmptyOrWhitespace(this.validationMessage);
    }

    @action
    public setValidationMessage = (value: string): void => {
        this.validationMessage = value;
    };

    // #endregion Error

    // #region Submitted

    @observable
    private isSubmitted = ServerViewModel.DEFAULT_ISSUBMITTED;

    @computed
    public get IsSubmitted(): boolean {
        return this.isSubmitted;
    }

    @action
    public resetIsSubmitted = (): void => {
        this.isSubmitted = ServerViewModel.DEFAULT_ISSUBMITTED;
    };

    @action
    public setIsSubmitted = (value: boolean): void => {
        this.isSubmitted = value;
    };

    // #endregion Submitted

    // #region Request Succeeded

    @observable
    private isRequestSuccessful = ServerViewModel.DEFAULT_ISREQUESTSUCCESSFUL;

    @computed
    public get IsRequestSuccessful(): boolean {
        return this.isRequestSuccessful;
    }

    @action
    public resetRequestSuccessful = (): void => {
        this.isRequestSuccessful = ServerViewModel.DEFAULT_ISREQUESTSUCCESSFUL;
    };

    @action
    public setRequestSuccessful = (value: boolean): void => {
        this.isRequestSuccessful = value;
    };

    // #endregion Request Succeeded

    public query = async <TPayload>(queryAction: () => Promise<ApiResult<TPayload>>, result: (data: TPayload) => void, errorMessage: string = ""): Promise<void> => {
        const DEFAULT_SERVERVALIDATIONMESSAGE = isEmptyOrWhitespace(errorMessage) ? "There was an error trying to process the request." : errorMessage;

        try {
            this.reset();
            this.setIsBusy(true);

            const apiResult = await queryAction();

            if (apiResult.wasSuccessful) {
                result(apiResult.payload);
            } else {
                if (!isNullOrUndefined(apiResult.errors) && apiResult.errors.length > 0) {
                    this.setValidationMessage(apiResult.errors[0].message);
                } else {
                    this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
                }
            }
        } catch (exception) {
            this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
        } finally {
            this.resetIsBusy();
        }
    };

    public command = async <TPayload>(
        commandAction: () => Promise<ApiResult<TPayload>>,
        result: (data: TPayload) => void,
        isModelValid: () => boolean,
        errorMessage: string = "",
    ): Promise<void> => {
        const DEFAULT_SERVERVALIDATIONMESSAGE = isEmptyOrWhitespace(errorMessage) ? "There was an error trying to process the request." : errorMessage;

        try {
            this.reset();
            this.setIsSubmitted(true);

            if (isModelValid()) {
                this.setIsBusy(true);

                const apiResult = await commandAction();

                if (apiResult.wasSuccessful) {
                    result(apiResult.payload);
                    this.setRequestSuccessful(true);
                } else {
                    if (!isNullOrUndefined(apiResult.errors) && apiResult.errors.length > 0) {
                        this.setValidationMessage(apiResult.errors[0].message);
                    } else {
                        this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
                    }
                }
            }
        } catch (exception) {
            this.setValidationMessage(DEFAULT_SERVERVALIDATIONMESSAGE);
        } finally {
            this.resetIsBusy();
        }
    };
}
