import { Stores } from "./Globals/Stores";

export class GlobalAppUrls {
    public Client = {
        /* Public facing routes */
        Home: "/",
        Faqs: "/faqs",
        MyProfile: "/myprofile",
        Map: "/map",
        PrivacyPolicy: "/privacypolicy",
        TermsAndConditions: "/termsandconditions",
        Swagger: "/doc",

        /* Admin facing routes */
        Admin: {
            Account: {
                Home: "/admin",
                ForgotPassword: "/admin/forgotpassword",
                ResetPassword: "/admin/resetpassword",
            },
            Faq: {
                Faq: "/admin/faq",
                Faqs: "/admin/faqs",
            },
            Feed: {
                Feed: "/admin/feed",
                Feeds: "/admin/feeds",
            },
            User: {
                User: "/admin/user",
                Users: "/admin/users",
            },
            LogViewer: "/admin/logviewer",
        },

        /* Sample stuff to borrow from - to be deleted towards the end of the project */
        HomeSamples: "/homesamples",
        Samples: "/samples",
        Error: "/error",
        NotFound: "/notfound",
        Test: "/test",
        Account: {
            ForgotPassword: "/forgotpassword",
            ForgotPasswordSuccess: "/forgotpassword-success",
            ResetPassword: "/resetpassword",
            Register: "/register",
            SessionExpired: "/sessionexpired",
        },
    };

    public Server = {
        Account: {
            Get: "/api/account/get",
            ForgotPassword: "/api/account/forgotpassword",
            Login: "/api/account/userlogin",
            Logout: "/api/account/logout",
            Register: "/api/account/register",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",
            Update: "/api/account/update",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
            Account: {
                Login: "/api/account/adminlogin",
            },
            Faq: {
                Delete: "/api/admin/faq/delete",
                Get: "/api/admin/faq/get",
                GetAll: "/api/admin/faq/getall",
                Publish: "/api/admin/faq/publish",
                Update: "/api/admin/faq/upsert",
            },
            Feed: {
                Delete: "/api/admin/feed/delete",
                Get: "/api/admin/feed/get",
                GetAll: "/api/admin/feed/getall",
                Publish: "/api/admin/feed/publish",
                Update: "/api/admin/feed/upsert",
            },
            User: {
                Delete: "/api/admin/user/delete",
                Get: "/api/admin/user/get",
                GetAll: "/api/admin/user/getall",
                Update: "/api/admin/user/update",
            },
        },
        Public: {
            Application: {
                Delete: "/api/public/application/delete",
                GetAllByUser: "/api/public/application/getallbyuser",
                Update: "/api/public/application/upsert",
            },
            Faq: {
                GetAll: "/api/public/faq/getall",
            },
            Feed: {
                GetAll: "/api/public/feed/getall",
                GetAllByUser: "/api/public/feed/getallbyuser",
                Subscribe: "/api/public/feed/subscribe",
                Pins: "/api/public/map/getfeed",
            },
        },
    };
}
export const AppUrls = new GlobalAppUrls();
