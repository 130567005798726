import { FieldType, isEmptyOrWhitespace, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";

// Globals
import { SearchViewModel } from "Globals/ViewModels/SearchViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { StoresInstance } from "Globals/Stores";

// Models
import { FeedsModel, FeedsResponseModelDTO, UserFeedsResponseModelDTO } from "./FeedsModel";

// ViewModels
import { FeedCategoryViewModel } from "./FeedCategoryViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class FeedsViewModel extends ViewModelBase<FeedsModel> {
    // #region Private Members

    private reactionDispose?: IReactionDisposer;
    public isMapView: boolean = false;

    @computed
    private get feedCategories(): FeedCategoryViewModel[] {
        const viewModels: FeedCategoryViewModel[] = [];
        const feedCategories = this.model.feedCategories.slice().sort((fsc1, fsc2) => fsc1.ordinal - fsc2.ordinal);
        const activeKeyword = computed(() => this.search.activeKeyword);

        for (const feedCategory of feedCategories) {
            viewModels.push(new FeedCategoryViewModel(feedCategory, this.setActiveFeedCategory, activeKeyword));
        }

        return viewModels;
    }

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super(new FeedsModel());

        // Monitor changes to the login state. If the user is already on the
        // page and log in or navigating to the page and already logged in,
        // get the users profile.
        this.reactionDispose = reaction(
            () => StoresInstance.Domain.AccountStore.IsLoggedIn,
            (isLoggedIn) => {
                if (isLoggedIn) {
                    this.getFeedsByUser();
                } else {
                    this.getFeeds();
                }
            },
        );

        if (StoresInstance.Domain.AccountStore.IsLoggedIn) {
            this.getFeedsByUser();
        } else {
            this.getFeeds();
        }
    }

    public dispose = (): void => {
        if (!isNullOrUndefined(this.reactionDispose)) {
            this.reactionDispose?.();
        }
    };

    // #endregion Constructors and Disposers

    // #region Properties

    public search: SearchViewModel = new SearchViewModel();

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public activeFeedCategory: string = "";

    @computed
    public get filteredFeedCategories(): FeedCategoryViewModel[] {
        let feedCategories = isEmptyOrWhitespace(this.activeFeedCategory) ? this.feedCategories : this.feedCategories.filter((fc) => fc.model.id === this.activeFeedCategory);

        feedCategories = feedCategories.filter((fc) => fc.numberOfFilteredFeeds > 0);

        return feedCategories;
    }

    @computed
    public get numberOfFilteredFeeds() {
        let numberOfFeeds = 0;

        for (const feedCategory of this.filteredFeedCategories) {
            numberOfFeeds += feedCategory.numberOfFilteredFeeds;
        }

        return numberOfFeeds;
    }

    // #endregion Properties

    // #region Actions

    public getFeeds = (): Promise<void> => {
        return this.server.query<FeedsResponseModelDTO>(
            () => this.Get(AppUrls.Server.Public.Feed.GetAll),
            (result) => {
                this.model.fromDto(result);
                this.setActiveFeedCategory("");
            },
            "There was an error trying to get the feeds.",
        );
    };

    public getFeedsByUser = (): Promise<void> => {
        return this.server.query<UserFeedsResponseModelDTO>(
            () => this.Get(AppUrls.Server.Public.Feed.GetAllByUser),
            (result) => {
                this.model.fromDto(result);
                this.setActiveFeedCategory("");
            },
            "There was an error trying to get the feeds.",
        );
    };

    @action
    public setActiveFeedCategory = (value: string): void => {
        this.activeFeedCategory = value;
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
