import { Box, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Grid } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

// Styles
import { PublicAccountFormWorkspaceStyles, PublicWorkspaceStyles } from "Globals/Styles/Public/PublicWorkspaceStyles";
import { MyProfileStyles } from "Globals/Styles/Public/MyProfile/MyProfileStyles";
import { ButtonStyles } from "Globals/Styles/Primitives/ButtonStyles";
import { CheckBoxStyles } from "Globals/Styles/Primitives/CheckBoxStyles";
import { EditTextStyles } from "Globals/Styles/Primitives/EditTextStyles";

// ViewModels
import { MyAccountViewModel } from "./MyAccountViewModel";

// Views
import { CheckBox } from "../../../Primitives/CheckBoxes";
import { EditText } from "../../../Primitives/EditText";

export const MyAccountView: React.FC = () => {
    const [viewModel] = useState(() => new MyAccountViewModel());

    const buttonStyles = ButtonStyles();
    const checkBoxStyles = CheckBoxStyles();
    const editTextStyles = EditTextStyles();
    const classes = MyProfileStyles();
    const formWorkspaceClasses = PublicAccountFormWorkspaceStyles();

    const onSubmit = (event: any) => {
        event.preventDefault();

        viewModel.updateAccount();
    };

    const renderProfile = () => {
        return (
            <React.Fragment>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Email address*"
                        execute={viewModel.setEmailAddress}
                        placeholder="Your email address"
                        validationMessage={viewModel.emailAddressValidationMessage}
                        value={viewModel.emailAddress}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Company name"
                        execute={viewModel.setCompanyName}
                        placeholder="The company you work for"
                        value={viewModel.companyName}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="First name*"
                        execute={viewModel.setFirstName}
                        placeholder="Your first name"
                        validationMessage={viewModel.confirmFirstNameValidationMessage}
                        value={viewModel.firstName}
                    />
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Last name*"
                        execute={viewModel.setLastName}
                        placeholder="Your last name"
                        validationMessage={viewModel.confirmLastNameValidationMessage}
                        value={viewModel.lastName}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <EditText
                        canExecute={!viewModel.server.IsBusy}
                        className={editTextStyles.publicDark}
                        displayName="Intended use*"
                        execute={viewModel.setIntendedUse}
                        placeholder="The use for the feeds"
                        validationMessage={viewModel.confirmIntendedUseValidationMessage}
                        value={viewModel.intendedUse}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formRow} columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                    <CheckBox
                        canExecute={!viewModel.server.IsBusy}
                        className={checkBoxStyles.publicDark}
                        displayName="I agree to the Terms & Conditions*"
                        execute={viewModel.setAgreeTermsAndConditions}
                        validationMessage={viewModel.confirmAgreeTermsAndConditionsValidationMessage}
                        value={viewModel.agreeTermsAndConditions}
                    />
                </Grid>
                <Grid className={formWorkspaceClasses.formButtonRowWithTop} columnGap={30} rowGap={15}>
                    <Button className={buttonStyles.containedSuccess} disabled={viewModel.server.IsBusy} type="submit" variant="contained">
                        Update
                    </Button>
                </Grid>
                {viewModel.server.HaveValidationMessage && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.alert} severity="error" variant="filled">
                            {viewModel.server.ValidationMessage}
                        </Alert>
                    </Grid>
                )}
                {viewModel.server.IsRequestSuccessful && (
                    <Grid className={formWorkspaceClasses.formRow} columnGap={30} rowGap={15}>
                        <Alert className={formWorkspaceClasses.infoWithTop} severity="info" variant="filled">
                            Your profile has been updated.
                        </Alert>
                    </Grid>
                )}
            </React.Fragment>
        );
    };

    return useObserver(() => (
        <Box className={classes.formContainer}>
            <form className={formWorkspaceClasses.formv2} onSubmit={onSubmit}>
                {renderProfile()}
            </form>
        </Box>
    ));
};
