import { DomainStores } from "./DomainStores";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import * as Models from "../Models";
import React, { createContext } from "react";
import { CoreStore } from "@shoothill/core";
import { theme } from "Globals/Styles/AppTheme";
import { GlobalHistory } from "index";

export class Stores {
    public CoreStore: CoreStore = new CoreStore();
    public Domain: DomainStores = new DomainStores();

    public init(initialState: Models.InitialState) {
        this.CoreStore.SetHistory(GlobalHistory);
        this.CoreStore.SetTheme(theme);
        this.CoreStore.Init(initialState.accountStatus.signalRAccessToken, initialState.appSettings.useFormLockConcurrency);
        this.Domain.init(this, initialState);
    }
}
export const StoresInstance = new Stores();
export const StoresContext: React.Context<Stores> = createContext(StoresInstance);
(window as any).stores = StoresInstance;
