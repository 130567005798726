import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { ForgotPasswordModel } from "./ForgotPasswordModel";

// Urls
import { AppUrls } from "AppUrls";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new ForgotPasswordModel());

        this.setDecorators(ForgotPasswordModel);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    // #endregion Properties

    // #region Email Address

    @action
    public setEmailAddress = (value: string): void => {
        this.model.emailAddress = value;
    };

    @computed
    public get emailAddress(): string {
        return this.model.emailAddress;
    }

    @computed
    public get emailAddressValidationMessage(): string {
        const result = this.validateDecorators("emailAddress");

        return !this.server.IsSubmitted ? "" : result.isValid ? "" : result.errorMessage;
    }

    // #endregion Email Address

    // #region Actions

    public forgotPassword = (): Promise<void> => {
        return this.server.command(
            () => this.Post(AppUrls.Server.Account.ForgotPassword, this.model.toDto()),
            () => {},
            this.isModelValid,
        );
    };

    @action
    public navigateToMyProfile = (): void => {
        this.history.push(AppUrls.Client.MyProfile);
    };

    // #endregion Actions

    // #region Boilerplate

    public isFieldValid(fieldName: keyof FieldType<ForgotPasswordModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
