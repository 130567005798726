//https://carlosmanotas.github.io/animate-styled/?selectedKind=Bounce%20animation&selectedStory=bounce&full=0&addons=1&stories=1&panelRight=1&addonPanel=storybooks%2Fstorybook-addon-knobs
import AnimateStyled from "animate-styled";
import { FancyBox } from "@shoothill/core";
import { Box, Grid, InputLabel } from "@material-ui/core";
import React from "react";

export const Animation: React.FC = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <Box>
                        <InputLabel>Simple Hover</InputLabel>
                        <FancyBox>Hello</FancyBox>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        <InputLabel>Fade In</InputLabel>
                        <AnimateStyled name="fadeIn" delay="1000ms">
                            <Box bgcolor="#aaa">Hello</Box>
                        </AnimateStyled>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        <InputLabel>Bounce In</InputLabel>
                        <AnimateStyled name="bounceIn" delay="1000ms">
                            <Box bgcolor="#aaa">Hello</Box>
                        </AnimateStyled>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        <InputLabel>Light Speed In</InputLabel>
                        <AnimateStyled name="lightSpeedIn" delay="1000ms">
                            <Box bgcolor="#aaa">Hello</Box>
                        </AnimateStyled>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <InputLabel>Zoom In</InputLabel>
                    <AnimateStyled name="zoomIn" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Grid>
                <Grid item xs={1}>
                    <InputLabel>Zoom Out</InputLabel>
                    <AnimateStyled name="zoomOut" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Grid>
                <Grid item xs={1}>
                    <InputLabel>Flip</InputLabel>
                    <AnimateStyled name="flip" delay="1000ms">
                        <Box bgcolor="#aaa">Hello</Box>
                    </AnimateStyled>
                </Grid>
            </Grid>
        </>
    );
};
