import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../AppTheme";

export const AdminFaqStyles = makeStyles((theme) => ({
    root: {},
    formContainer: {
        flex: 1,
        padding: pxToRem(30),
    },
}));
