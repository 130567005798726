import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../AppTheme";

const helperFontSize = 14;

export const CheckBoxStyles = makeStyles((theme) => ({
    publicDark: {
        "& .MuiCheckbox-root": {
            "&:not(.Mui-disabled)": {
                color: theme.palette.default.main,
            },
        },
        "& .MuiFormControlLabel-label": {
            fontSize: pxToRem(12),
            "&:not(.Mui-disabled)": {
                color: theme.palette.default.main,
            },
        },
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: pxToRem(helperFontSize),
        },
    },
    publicLight: {
        "& .MuiCheckbox-root": {
            "&:not(.Mui-disabled)": {
                color: theme.palette.default.contrastText,
            },
        },
        "& .MuiFormControlLabel-label": {
            fontSize: pxToRem(12),
            "&:not(.Mui-disabled)": {
                color: theme.palette.default.contrastText,
            },
        },
    },
}));
