import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminUserModel, AdminGetUserResponseModelDTO } from "./AdminUserModel";

// ViewModels
import { AdminUserViewModel } from "./AdminUserViewModel";

// Urls
import { AppUrls } from "./../../../../AppUrls";

export class AdminUsersViewModel extends ViewModelBase<any> {
    // #region Private Members

    @observable
    private models = observable<AdminUserModel>([]);

    @action
    private fromDtos = (dtos: AdminGetUserResponseModelDTO[]): void => {
        for (const dto of dtos) {
            const model = new AdminUserModel();

            model.fromDto(dto);

            this.models.push(model);
        }
    };

    @action
    private deleteFromCollection = (id: string): void => {
        const user = this.models.find((m) => m.id === id);

        this.models.remove(user!);
    };

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super({});

        this.getUsers();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get users(): AdminUserViewModel[] {
        return this.models.slice().map((model) => new AdminUserViewModel(model, this.deleteUser));
    }

    // #endregion Properties

    // #region Actions

    public getUsers = (): Promise<void> => {
        return this.server.query<AdminGetUserResponseModelDTO[]>(
            () => this.Get(AppUrls.Server.Admin.User.GetAll),
            (result) => this.fromDtos(result),
        );
    };

    public deleteUser = (id: string): Promise<void> => {
        return this.server.command(
            () =>
                this.Post(AppUrls.Server.Admin.User.Delete, {
                    id: id,
                    isDeleted: true,
                }),
            (result) => this.deleteFromCollection(id),
            () => true,
        );
    };

    @action
    public navigateToUser = (id: string) => {
        this.history.push(`${AppUrls.Client.Admin.User.User}/${id}`);
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
