import { isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable } from "mobx";

export class SearchViewModel {
    // #region Defaults

    public static readonly DEFAULT_SERACHKEYWORD = "";
    public static readonly DEFAULT_ACTIVESERACHKEYWORD = "";

    // #endregion Defaults

    // #region Observables

    @observable
    public keyword: string = SearchViewModel.DEFAULT_SERACHKEYWORD;

    @observable
    public activeKeyword: string = SearchViewModel.DEFAULT_ACTIVESERACHKEYWORD;

    // #endregion Observables

    // #region Computeds

    @computed
    public get haveKeyword(): boolean {
        return !isEmptyOrWhitespace(this.keyword);
    }

    @computed
    public get haveActiveKeyword(): boolean {
        return !isEmptyOrWhitespace(this.activeKeyword);
    }

    // #endregion Computeds

    // #region Actions

    @action
    public apply = (): void => {
        this.activeKeyword = this.keyword;
    };

    @action
    public reset = (): void => {
        this.activeKeyword = SearchViewModel.DEFAULT_ACTIVESERACHKEYWORD;
        this.keyword = SearchViewModel.DEFAULT_SERACHKEYWORD;
    };

    @action
    public setKeyword = (value: string): void => {
        this.keyword = value;

        if (!this.haveKeyword) {
            this.reset();
        }
    };

    // #endregion Actions
}
