import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { useObserver } from "mobx-react-lite";
import React from "react";

interface Props {
    canExecute?: boolean;
    className?: string;
    displayName: string;
    execute: () => void;
    validationMessage?: string;
    value: boolean;
}

export const CheckBox: React.FC<Props> = (props) => {
    const getClasses = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            ["checkBox"]: true,
        });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage) ? "" : props.validationMessage!;
    };

    const hasError = (): boolean => {
        return !isEmptyOrWhitespace(props.validationMessage);
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.canExecute) ? false : !props.canExecute;
    };

    return useObserver(() => (
        <FormControl className={getClasses()} error={hasError()}>
            <FormControlLabel checked={props.value} control={<Checkbox onChange={props.execute} />} disabled={isDisabled()} label={props.displayName} />
            {hasError() && <FormHelperText>{getValidationMessage()}</FormHelperText>}
        </FormControl>
    ));
};
