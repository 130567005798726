import { ModelBase } from "@shoothill/core";
import { IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

export class MyApplicationsModel extends ModelBase<MyApplicationsModel, MyApplicationsResponseModelDTO> {
    // #region Defaults

    public static readonly DEFAULT_ID = undefined;
    public static readonly DEFAULT_NAME = "";
    public static readonly DEFAULT_DESCRIPTION = "";
    public static readonly DEFAULT_CREATEDDATETIMEUTC = "";
    public static readonly DEFAULT_MODIFIEDDATETIMEUTC = undefined;

    // #endregion Defaults

    // #region Observables

    @observable
    public id?: string = MyApplicationsModel.DEFAULT_ID;

    @observable
    @IsNotEmpty({ message: "You must provide a name" })
    public name: string = MyApplicationsModel.DEFAULT_NAME;

    @observable
    @IsNotEmpty({ message: "You must provide a description" })
    public description: string = MyApplicationsModel.DEFAULT_DESCRIPTION;

    @observable
    public createdDateTimeUTC: string = MyApplicationsModel.DEFAULT_CREATEDDATETIMEUTC;

    @observable
    public modifiedDateTimeUTC?: string = MyApplicationsModel.DEFAULT_MODIFIEDDATETIMEUTC;

    // #endregion Observables

    // #region Actions

    @action
    public reset(): void {
        this.id = MyApplicationsModel.DEFAULT_ID;
        this.name = MyApplicationsModel.DEFAULT_NAME;
        this.description = MyApplicationsModel.DEFAULT_DESCRIPTION;
        this.createdDateTimeUTC = MyApplicationsModel.DEFAULT_CREATEDDATETIMEUTC;
        this.modifiedDateTimeUTC = MyApplicationsModel.DEFAULT_MODIFIEDDATETIMEUTC;
    }

    @action
    public fromDto(dto: MyApplicationsResponseModelDTO): void {
        this.id = dto.id;
        this.name = dto.name;
        this.description = dto.description;
        this.createdDateTimeUTC = dto.createdDateTimeUTC;
        this.modifiedDateTimeUTC = dto.modifiedDateTimeUTC;
    }

    public toDto(): MyApplicationsRequestModelDTO {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
        };
    }

    // #endregion Actions
}

export interface MyApplicationsRequestModelDTO {
    id?: string;
    name: string;
    description: string;
}

export interface MyApplicationsResponseModelDTO {
    id: string;
    apiKey: string;
    name: string;
    description: string;
    createdDateTimeUTC: string;
    modifiedDateTimeUTC?: string;
}
