import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { SearchViewModel } from "Globals/ViewModels/SearchViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { FaqModel, GetFaqResponseModelDTO } from "./FaqModel";

// ViewModels
import { FaqViewModel } from "./FaqViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class FaqsViewModel extends ViewModelBase<any> {
    // #region Private Members

    @observable
    private models = observable<FaqModel>([]);

    private get filteredModels(): FaqModel[] {
        return this.models.filter(
            (m) =>
                m.question.toLocaleLowerCase().includes(this.search.activeKeyword.toLocaleLowerCase()) ||
                m.answer.toLocaleLowerCase().includes(this.search.activeKeyword.toLocaleLowerCase()),
        );
    }

    @action
    private fromDtos = (dtos: GetFaqResponseModelDTO[]): void => {
        for (const dto of dtos) {
            const model = new FaqModel();

            model.fromDto(dto);

            this.models.push(model);
        }
    };

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super({});

        this.getFaqs();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public search: SearchViewModel = new SearchViewModel();

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get faqs(): FaqViewModel[] {
        const models = this.search.haveActiveKeyword ? this.filteredModels : this.models;

        return models.slice().map((model) => new FaqViewModel(model));
    }

    // #endregion Properties

    // #region Actions

    public getFaqs = (): Promise<void> => {
        return this.server.query<GetFaqResponseModelDTO[]>(() => this.Get(AppUrls.Server.Public.Faq.GetAll), this.fromDtos);
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
