import { observable, extendObservable, runInAction, action, autorun } from "mobx";
import { Marker } from "react-leaflet";
import { useRef, createRef } from "react";
import { throttle, delay } from "lodash-es";
import axios from "axios";
import { Box } from "@material-ui/core";
import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { MapModel } from "./MapModel";
import * as styles from "./Map.styles";
import { FeedsViewModel } from "../SubViews/Feeds/FeedsViewModel";

export interface Location {
    Latitude: number;
    Longitude: number;
}
//Pass in <any> as the generic type because we are showing the use of nested classes
export class MapViewModel extends ViewModelBase<MapModel> {
    public clusterdMarkers = observable<any>([]);
    @observable public available = true;
    @observable public listView = false;
    @observable public totalCount = 0;
    @observable public loadingData = false;
    @observable public showDetail = false;
    @observable public homeMarker = (<Box></Box>);
    @observable public selectedItem: MapModel = new MapModel();
    @observable public suggestions = [] as any;
    @observable public showAutoSuggestions = false;
    @observable public displayLoadMoreButton = true;
    public selectedMarker = null;
    public clusterRef = null;
    public circleRef: any = createRef();
    public page = 1;
    public setTimeoutId: any;
    public mapRef: any;

    public feedsViewModel = new FeedsViewModel();

    constructor() {
        super(new MapModel());
        this.setDecorators(MapModel);
        this.feedsViewModel.isMapView = true;

        const doAsync = async () => {
            await this.feedsViewModel.getFeeds();
            let a = this.feedsViewModel.getModel;
        };
        const _ = doAsync();
    }

    @action
    public getSuggestions = async (value: any) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        //return inputLength === 0 ? [] : this.languages.filter((lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue);
        //let retval = inputLength === 0 ? [] : this.languages.filter((lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue);
        if (inputLength >= 3) {
            let response = await axios.get(
                `https://photon.komoot.io/api/?q=${value}&limit=10&lat=53.3879&lon=-3.0582&osm_tag=place:town&osm_tag=place:city&osm_tag=place:village&lang=en&location_bias_scale=10&bbox=-7.57216793459,49.959999905,1.68153079591,58.6350001085`,
            );

            let ret: any[] = [];
            response.data.features.forEach((item: any) => {
                //if (item.properties.type === "city")
                {
                    ret.push({
                        name: item.properties.name,
                        county: item.properties.county,
                        lat: item.geometry.coordinates[1],
                        lon: item.geometry.coordinates[0],
                    });
                }
            });
            this.setSuggestions(ret);
        } else {
            this.setSuggestions([]);
        }
    };
    @action
    public setSuggestions = (data: any) => {
        this.suggestions = data;
    };

    markerClicked = async (e: any, item: MapModel) => {
        if (this.selectedMarker != null) {
            try {
                (this.selectedMarker as any).target!.setIcon(styles.customMarker);
            } catch {}
        }
        this.selectedMarker = e;
        e.target.setIcon(styles.customMarkerSelected);
        /*runInAction(() => {
            if (item.facilitiesAvailableCSV && item.facilitiesAvailableCSV !== "") {
                item.facilitiesAvailable = item.facilitiesAvailableCSV.split(",");
            } else {
                item.facilitiesAvailableCSV = "";
                item.facilitiesAvailable = [];
            }
        });*/
        //let listing = await this.getListingDetails(item.id);
        //this.setSelectedItem(listing);
        this.setShowDetail(true);
    };

    @action
    public setShowDetail = (state: boolean) => {
        this.showDetail = state;
    };

    public get getWithin() {
        let value = parseInt(this.getValue<string>("within"));
        if (value >= 500) {
            return 0;
        }
        return this.milesToMeters(value);
    }
    milesToMeters = (miles: number) => {
        if (isNaN(miles)) {
            console.log("miles is not a number");
            return 0;
        }
        return miles * 1609.34;
    };

    @action
    private bindMarker = (ref: any, item: MapModel) => {
        if (ref) {
            //console.log("marker element", ref);
            item.ref = ref;
        }
    };

    public loadMarkers = (data: MapModel[]) => {
        let tmp: any = [];
        data.forEach((item, index) => {
            if (!item.hidden) {
                let marker = (
                    <Marker
                        eventHandlers={{
                            click: (e) => {
                                this.markerClicked(e, item);
                            },
                        }}
                        //ref={(e) => this.bindMarker(e, item)}
                        //data-self={item}
                        opacity={item.hidden ? 0 : 1}
                        data-id={item.id}
                        key={"mapmarker-" + index}
                        position={[item.lat, item.lon]}
                        icon={styles.customMarker(this.available)}
                    />
                );

                tmp.push(marker);
            }
        });
        runInAction(() => {
            this.clusterdMarkers.replace(tmp);
            this.totalCount = this.clusterdMarkers.length;
        });
    };

    public getAllMarkers = async (): Promise<ApiResult<MapModel[]>> => {
        this.setLoadingData(true);
        this.clearLocations();
        //this.setValue("offset", -1);
        //this.setValue("available", this.available);

        let response = await this.Post<MapModel[]>("/api/listings/getalllocations/", this.getModel);
        //let response = await this.Post<MyListingsModel[]>(`/api/listings/getlistings/`, this.getModel);
        if (response.wasSuccessful) {
            await runInAction(() => {
                //this.locations.replace(response.payload);
                this.totalCount = response.payload.length;
            });
            //this.loadMarkers(this.locations);
        }
        console.log("loaded data");
        this.setLoadingData(false);
        return response;
    };

    @action
    public setLoadingData = (state: boolean) => {
        this.loadingData = state;
    };

    public getListings = async (): Promise<ApiResult<MapModel[]>> => {
        this.setLoadingData(true);
        //this.setValue("offset", 50);
        //this.setValue("available", this.available);

        let response = await this.Post<MapModel[]>(`/api/listings/getlistings/`, this.getModel);
        if (response.wasSuccessful) {
            runInAction(() => {
                response.payload.forEach((item: MapModel, index: number) => {});
                this.displayLoadMoreButton = response.payload.length === 50;
            });
        }
        this.setLoadingData(false);

        return response;
    };

    @action
    public setAvailable = (state: boolean) => {
        this.available = state;
    };

    @action
    public setListView = (state: boolean) => {
        this.listView = state;
        if (state === true) {
            let _ = this.getListings();
        } else {
            let _ = this.getAllMarkers();
        }
    };

    @action
    public clearLocations = () => {
        //this.locations.clear();
    };

    @action
    public clearClusteredMarkers = () => {
        this.clusterdMarkers.clear();
    };

    public isFieldValid(fieldName: keyof FieldType<MapModel>, value: any): boolean {
        return true;
    }
    @action
    private resetListView() {
        if (this.listView) {
            //this.listings.clear();
            this.getListings();
        }
    }
    afterUpdate(fieldName: keyof FieldType<MapModel>, value: any): void {
        return;
    }
    beforeUpdate(fieldName: keyof FieldType<MapModel>, value: any): any {
        return value;
    }
}
