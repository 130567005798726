import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../AppTheme";

export const AdminUserStyles = makeStyles((theme) => ({
    root: {},
    formContainer: {
        flex: 1,
        padding: pxToRem(30),
    },
    resetButton: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "50%",
        },
    },
}));
