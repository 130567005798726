import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { computed } from "mobx";
import moment from "moment";
import "moment-timezone";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminFaqModel, AdminPublishFaqResponseModelDto } from "./AdminFaqModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminFaqViewModel extends ViewModelBase<AdminFaqModel> {
    // #region Private Members

    private deleteFaqCallback: (id: string) => Promise<void>;

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor(model: AdminFaqModel, deleteFaqCallback: (id: string) => Promise<void>) {
        super(model);

        this.deleteFaqCallback = deleteFaqCallback;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get question() {
        return this.model.question;
    }

    @computed
    public get lastUpdatedBy() {
        return isEmptyOrWhitespace(this.model.modifiedByLastName)
            ? `${this.model.createdByFirstName} ${this.model.createdByLastName}`
            : `${this.model.modifiedByFirstName} ${this.model.modifiedByLastName}`;
    }

    @computed
    public get lastUpdated() {
        return isEmptyOrWhitespace(this.model.modifiedDateTimeUTC) ? this.model.createdDateTimeUTC : this.model.modifiedDateTimeUTC;
    }

    @computed
    public get lastUpdatedFormatted() {
        const utcDate = isEmptyOrWhitespace(this.model.modifiedDateTimeUTC) ? this.model.createdDateTimeUTC : this.model.modifiedDateTimeUTC;

        const ukDate = moment.utc(utcDate).tz("Europe/London");

        return ukDate.format("DD MMM YYYY HH:mm");
    }

    @computed
    public get isPublished() {
        return this.model.isActive;
    }

    // #endregion Properties

    // #region Actions

    public publishFaq = (): Promise<void> => {
        return this.server.command<AdminPublishFaqResponseModelDto>(
            () =>
                this.Post(AppUrls.Server.Admin.Faq.Publish, {
                    id: this.model.id,
                    isActive: !this.model.isActive,
                }),
            (result) => this.model.fromPublishDto(result),
            () => true,
        );
    };

    public deleteFaq = (): Promise<void> => this.deleteFaqCallback(this.model.id);

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
