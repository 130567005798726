import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

// Globals
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";

// Models
import { AdminFaqModel, AdminGetFaqResponseModelDTO } from "./AdminFaqModel";

// ViewModels
import { AdminFaqViewModel } from "./AdminFaqViewModel";

// Urls
import { AppUrls } from "AppUrls";

export class AdminFaqsViewModel extends ViewModelBase<any> {
    // #region Private Members

    @observable
    private models = observable<AdminFaqModel>([]);

    @action
    private fromDtos = (dtos: AdminGetFaqResponseModelDTO[]): void => {
        for (const dto of dtos) {
            const model = new AdminFaqModel();

            model.fromDto(dto);

            this.models.push(model);
        }
    };

    @action
    private deleteFromCollection = (id: string): void => {
        const faq = this.models.find((m) => m.id === id);

        this.models.remove(faq!);
    };

    // #endregion Private Members

    // #region Constructors and Disposers

    constructor() {
        super({});

        this.getFaqs();
    }

    // #endregion Constructors and Disposers

    // #region Properties

    public server: ServerViewModel = new ServerViewModel();

    @computed
    public get faqs(): AdminFaqViewModel[] {
        return this.models.slice().map((model) => new AdminFaqViewModel(model, this.deleteFaq));
    }

    // #endregion Properties

    // #region Actions

    public getFaqs = (): Promise<void> => {
        return this.server.query<AdminGetFaqResponseModelDTO[]>(
            () => this.Get(AppUrls.Server.Admin.Faq.GetAll),
            (result) => this.fromDtos(result),
        );
    };

    public deleteFaq = (id: string): Promise<void> => {
        return this.server.command(
            () =>
                this.Post(AppUrls.Server.Admin.Faq.Delete, {
                    id: id,
                    isDeleted: true,
                }),
            (result) => this.deleteFromCollection(id),
            () => true,
        );
    };

    @action
    public navigateAddNewFaq = () => {
        this.history.push(AppUrls.Client.Admin.Faq.Faq);
    };

    @action
    public navigateToFaq = (id: string) => {
        this.history.push(`${AppUrls.Client.Admin.Faq.Faq}/${id}`);
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
